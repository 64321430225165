import MuiInputLabel from '@mui/material/InputLabel'

interface Props {
  children: string | JSX.Element
  id?: string
  required?: boolean
}

export function InputLabel({ children, id, required = false }: Props) {
  return (
    <MuiInputLabel id={id} required={required}>
      {children}
    </MuiInputLabel>
  )
}
