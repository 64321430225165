import { ReactElement } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Theme } from '@mui/material'
import MuiIconButton from '@mui/material/IconButton'
import { TIconButton } from '../types'

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    // TODO: for now just only 1 option, then extend when necessary
    // @ts-ignore
    // TODO: fix type here, for some reason it's not working
    color: theme.palette.danger.main,
  },
}))

export const IconButton = ({
  className,
  children,
  onClick,
  isDanger,
  size,
  href,
  target,
  ...props
}: TIconButton): ReactElement<TIconButton> => {
  const { classes, cx } = useStyles()

  return (
    // @ts-ignore This works, but TypeScript doesn't agree
    <MuiIconButton
      className={cx({ [className as string]: !!className })}
      classes={{ root: isDanger ? classes.root : '' }}
      size={size ? size : 'medium'}
      onClick={onClick}
      // @ts-ignore This works, but TypeScript doesn't agree
      href={href}
      target={target}
      {...props}
    >
      {children}
    </MuiIconButton>
  )
}
