import React from 'react'
import { Link } from 'ui'
import { Button } from 'ui'
import GitHubIcon from './GitHubIcon'

export default function GithubButton({ action, label = 'Github', redirectTo }) {
  return (
    <Link
      style={{ textDecoration: 'none' }}
      href={`${process.env.PROTOCOL}://${
        process.env.API_URL
      }/users/auth/github?action=${action}&redirectTo=${encodeURIComponent(
        `${window.location.origin}${redirectTo}`,
      )}`}
      passHref
    >
      <Button fullWidth large startIcon={<GitHubIcon />} forceOutlined>
        {label}
      </Button>
    </Link>
  )
}
