import { ChangeEvent, MouseEvent, useCallback, useContext } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Check, Remove } from '@mui/icons-material'
import { Theme } from '@mui/material'
import MuiCheckbox from '@mui/material/Checkbox'
import Colors from '../Colors'
import { ReadOnlyContext } from './ReadOnlyContext'
import {
  BaseControl,
  CommonControlDefaultProps,
  CommonControlProps,
} from './components/BaseControl'

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    // reverting global change, it broke other parts of the app
    // padding: theme.spacing(0.3, 0.3),
  },
  icon: {
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.borderColor.main}`,
    borderRadius: theme.shape.borderRadius,
    boxSizing: 'border-box',
    height: '1rem',
    width: '1rem',
    margin: 2,
    'input:disabled ~ &': {
      backgroundColor: theme.palette.background.paper,
      borderColor: theme.palette.borderColor.main,
    },
  },
  checkedIcon: {
    border: 'none',
    color: theme.palette.common.white,
    position: 'relative',
  },
  indeterminateIcon: {
    backgroundColor: theme.palette.primary.main,
  },
  svg: {
    height: '0.9rem',
    left: '0.05rem',
    position: 'absolute',
    top: '0.05rem',
    width: '0.9rem',
  },
  button: {
    // reverting global change, it broke other parts of the app
    // padding: 'inherit',
  },
}))

export interface CheckboxProps extends CommonControlProps {
  /** If `true`, the component is checked. */
  checked?: boolean
  /** If `true`, the component uses danger styling. */
  danger?: boolean
  /** If `true`, the component appears indeterminate. */
  indeterminate?: boolean
  /** Name attribute of the `input` element. */
  name?: string
  /** Callback fired when the state is changed. */
  onChange?: (event: ChangeEvent, newValue: any) => void
  /** The value of the component. The DOM API casts this to a string. The browser uses "on" as the default value. */
  value?: any
  /** Color style to apply */
  color?: string
  /** Whether to stop click event propagation */
  stopPropagation?: boolean
  /** Additional css class name */
  className?: string
  /** QA test hook */
  ['data-qa']?: string
}

/**
 * A boolean input.
 */
export function Checkbox({
  checked,
  danger = false,
  disabled,
  error,
  fullWidth,
  gutterBottom,
  helperText,
  indeterminate,
  label,
  name,
  onBlur,
  onFocus,
  onChange,
  required,
  value,
  color = Colors.EdgioBlue,
  stopPropagation = false,
  className,
  ...props
}: CheckboxProps) {
  const { classes, cx } = useStyles()
  const clickHandler = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (stopPropagation) {
        event.stopPropagation()
      }
    },
    [stopPropagation],
  )

  const forceDisabled = useContext(ReadOnlyContext)

  return (
    <BaseControl
      control={
        <MuiCheckbox
          onClick={clickHandler}
          checked={checked}
          checkedIcon={
            <span
              className={cx(classes.icon, classes.checkedIcon)}
              style={{ backgroundColor: color }}
            >
              <Check className={classes.svg} />
            </span>
          }
          disabled={forceDisabled || disabled}
          icon={<span className={classes.icon} />}
          indeterminate={indeterminate}
          indeterminateIcon={
            <span className={cx(classes.icon, classes.checkedIcon, classes.indeterminateIcon)}>
              <Remove className={classes.svg} />
            </span>
          }
          name={name}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          size="small"
          value={value}
          className={classes.button}
          data-qa={props['data-qa']}
        />
      }
      danger={danger}
      error={error}
      fullWidth={fullWidth}
      gutterBottom={gutterBottom}
      helperText={helperText}
      label={label}
      required={required}
      className={cx(classes.root, className || '')}
    />
  )
}

Checkbox.defaultProps = CommonControlDefaultProps
