import { ReactNode, memo } from 'react'
import Ellipsis from '../Ellipsis'

type Props = {
  /**
   * An array of values
   */
  values: Array<any> | any

  /**
   * Called for each item in the array, to render it as a react node
   */
  renderItem: (value: any) => ReactNode

  /**
   * The separator to use between items
   */
  separator: ReactNode
}

/**
 * Renders an array as a comma-separated list, where the items are rendered by renderItem
 */
const Join = ({ values, renderItem, separator }: Props) => {
  if (!Array.isArray(values)) {
    values = [values]
  }

  return (
    <>
      {values.map((value: any, index: number) => {
        return (
          <>
            {index > 0 ? separator : ''}
            <Ellipsis key={index}>{renderItem(value)}</Ellipsis>
          </>
        )
      })}
    </>
  )
}

Join.defaultProps = {
  separator: ', ',
}

const memoized = memo(Join)

export { memoized as Join }
