import React, { useEffect, useState } from 'react'
// import { DatePicker as MuiDatePicker } from '@mui/lab'
// import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
// import { InputAdornment } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
// import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { TextField } from './TextField'
import { CommonInputDefaultProps, CommonInputProps } from './components/BaseInput'

// import { ClearButton } from './components/ClearButton'

export interface Props extends Omit<CommonInputProps, 'endAdornment' | 'startAdornment'> {
  /** Format string. */
  format?: string
  /** Max selectable date. */
  maxDate?: Date
  /** Min selectable date. */
  minDate?: Date
  /** Callback fired when `datepicker` value changes. */
  onChange?: (_value: React.SetStateAction<Date | null>) => void
  onAfterChange?: any
  /** The value of the `input` element, required for a controlled component. */
  value?: Date | null
  datePickerProps: Partial<React.ComponentProps<typeof MuiDatePicker>>
}

/**
 * A date input.
 */
// Although KeyboardDatePicker allows custom components, the type definitions do not always reflect that.
export function DatePicker({
  autoComplete,
  autoFocus,
  disabled,
  error,
  format = 'MM/dd/yyyy',
  fullWidth,
  gutterBottom,
  helperText,
  label,
  large,
  maxDate,
  minDate,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  required,
  value,
  datePickerProps,
  onAfterChange,
}: Props) {
  const dateFns = new DateFnsUtils()
  const [selectedDate, setSelectedDate] = useState(value || null)

  useEffect(() => {
    setSelectedDate(value || null)
  }, [value])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDatePicker
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        autoOk
        disabled={disabled}
        disableFuture
        disableToolbar
        error={error}
        clearable
        format={format}
        // @ts-ignore Custom prop
        gutterBottom={gutterBottom}
        helperText={helperText}
        // InputAdornmentProps={{ position: 'start' }}
        // InputProps={{
        //   endAdornment: selectedDate && (
        //     <InputAdornment position="end">
        //       <ClearButton
        //         onClick={() => {
        //           setSelectedDate(null)
        //           onChange && onChange(null)
        //         }}
        //       />
        //     </InputAdornment>
        //   ),
        // }}
        KeyboardButtonProps={{ size: 'small' }}
        label={label}
        // @ts-ignore Custom prop
        large={large}
        name={name}
        maxDate={maxDate}
        minDate={minDate}
        onBlur={onBlur}
        // @ts-ignore
        onChange={(date: React.SetStateAction<Date | null>) => {
          setSelectedDate(date)
          if (onChange && dateFns.isValid(date)) {
            onChange(date)

            if (onAfterChange) {
              onAfterChange(date)
            }
          }
        }}
        onFocus={onFocus}
        required={required}
        placeholder={placeholder || format}
        // @ts-ignore
        slots={{ textField: TextField }}
        slotProps={{
          textField: {
            // InputProps: {
            //   endAdornment: selectedDate && (
            //     <InputAdornment position="end">
            //       <ClearButton
            //         onClick={() => {
            //           setSelectedDate(null)
            //           onChange && onChange(null)
            //         }}
            //       />
            //     </InputAdornment>
            //   ),
            // },
            fullWidth,
            helperText,
            // @ts-ignore
            gutterBottom,
            ...datePickerProps?.slotProps?.textField,
          },
        }}
        value={selectedDate}
        variant="inline"
        {...datePickerProps}
      />
    </LocalizationProvider>
  )
}

DatePicker.defaultProps = CommonInputDefaultProps
