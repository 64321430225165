import { useCallback, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Paper, Theme } from '@mui/material'
import { Typography } from 'ui'

type Props = {
  label: string
}

const useStyles = makeStyles()((theme: Theme) => ({
  link: {
    cursor: 'pointer',
  },
  info: {
    padding: theme.spacing(1, 2),
    background: theme.palette.background.default,
  },
}))

export default function DisclaimerCaption({ label }: Props) {
  const { classes } = useStyles()
  const [expanded, setExpanded] = useState<boolean>(false)
  const toggleExpand = useCallback(() => setExpanded((v) => !v), [])

  return (
    <>
      <Typography variant="caption" color="textSecondary">
        By clicking "{label}" you consent to sharing this text with OpenAI for processing.{' '}
        <a className={classes.link} onClick={toggleExpand}>
          Show {expanded ? 'less' : 'more'}...
        </a>
      </Typography>

      {expanded && (
        <Paper variant="outlined" className={classes.info}>
          <Typography variant="caption">
            Edgio uses a third party,{' '}
            <a target="_blank" href="https://openai.com" rel="noreferrer">
              OpenAI.com
            </a>
            , to process the text you enter in this prompt. Only the text you enter here will be
            sent to OpenAI for processing. By clicking "{label}" you consent to sharing this text
            with OpenAI.
          </Typography>
        </Paper>
      )}
    </>
  )
}
