import React, { createContext, useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import isEmpty from 'lodash/isEmpty'
import qs from 'querystring'

export const getCurrentParams = () => {
  const search = window.location.search.replace('?', '')
  return qs.parse(search)
}

const QueryNavigationContext = createContext()

export default QueryNavigationContext

export const QueryNavigationProvider = ({ children }) => {
  const router = useRouter()
  const [currentParams, setCurrentParams] = useState(getCurrentParams())

  useEffect(() => {
    function handle() {
      setCurrentParams(getCurrentParams())
    }

    router.events.on('routeChangeComplete', handle)

    return () => {
      router.events.off('routeChangeComplete', handle)
    }
  }, [router.events])

  /**
   * Deletes null/undefined values, then returns a query string
   */
  const createQueryString = (paramObject) => {
    Object.keys(paramObject).forEach((key) => {
      if (paramObject[key] == null || paramObject[key].length === 0 || !key) {
        delete paramObject[key]
      }
    })
    return isEmpty(paramObject) ? '' : `?${qs.stringify(paramObject)}`
  }

  /**
   * Navigate based on params in object.
   */
  const navigate = useCallback((updatedQueryParams = {}, options = {}) => {
    const { override = true, action = 'replaceState' } = options

    let navigateParams = updatedQueryParams
    if (!override) {
      navigateParams = Object.assign({}, getCurrentParams(), updatedQueryParams)
    }

    const queryString = createQueryString(navigateParams)
    const url = `${location.pathname}${queryString}${location.hash}`

    // Do not navigate while keeping the same query
    if (location.search !== queryString) {
      window.history[action](window.history.state, document.location, url)
      setCurrentParams(getCurrentParams())
    }
  }, [])

  /**
   * Remove a specific params from the query
   */
  const removeParam = useCallback((paramName) => {
    navigate({ [paramName]: undefined }, { override: false })
  }, [])

  return (
    <QueryNavigationContext.Provider
      value={{
        ...currentParams,
        navigate,
        removeParam,
      }}
    >
      {children}
    </QueryNavigationContext.Provider>
  )
}
