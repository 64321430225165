import { IconButton as MuiIconButton } from '@mui/material'
// @ts-ignore
import TrashIcon from 'ui/icons/trash-2.svg'
import { TIconButton } from '../types'

export default function DeleteButton(props: TIconButton) {
  return (
    <MuiIconButton
      // @ts-ignore
      color="inherit"
      {...props}
      classes={props.classes}
    >
      <TrashIcon />
    </MuiIconButton>
  )
}
