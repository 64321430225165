import Loading from '../Loading'
import NotFound from '../NotFound'
import { useCurrentProperty } from '../hooks/useCurrentProperty'
import { OrganizationPage } from './OrganizationPage'

export const PropertyPage = ({ children }) => {
  const { loading, currentProperty } = useCurrentProperty()

  if (loading) {
    return <Loading />
  }
  if (!currentProperty) {
    return <NotFound />
  }

  return <OrganizationPage>{children}</OrganizationPage>
}
