import { forwardRef } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Theme } from '@mui/material'
import { lighten } from '@mui/material/styles'
import { customColors } from '../../../theme'
import { Chip } from '../Chip'
import { TChipProps } from '../Chip/types'

type BadgeProps = {} & TChipProps

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    background: theme.palette.mode === 'dark' ? theme.palette.background.default : undefined,
    borderColor:
      theme.palette.mode === 'dark'
        ? lighten(theme.palette.background.default, 0.2)
        : customColors.EdgioGreen,
    color: theme.palette.mode === 'dark' ? theme.palette.text.primary : customColors.EdgioGreen,
  },
}))

export const Badge = forwardRef(({ className, ...props }: BadgeProps, ref) => {
  const { classes, cx } = useStyles()
  return <Chip className={cx(classes.root, className)} ref={ref} {...props} />
})

Badge.displayName = 'Badge'
