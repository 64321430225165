import { Notifier } from '@airbrake/browser'

// Report to the Edgio Developer Console - Browser project in Airbrake
// https://moovweb.airbrake.io/projects/326154
const airbrake = new Notifier({
  projectId: 490941,
  projectKey: '55527b1960c024e9888f5792a8522642',
  environment: process.env['ENVIRONMENT_NAME'],
})

// This prevents non-production errors from being sent to AIrbrake:
airbrake.addFilter((notice) => (process.env['ENVIRONMENT_NAME'] !== 'development' ? notice : null))

export default airbrake
