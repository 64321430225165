import SvgIcon from '@mui/material/SvgIcon'

export const BulbIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M20.0001 29.3334H12.0001V26.6667H20.0001V29.3334ZM20.0001 25.3334H12.0001L11.7027 22.6667C11.5428 21.7846 11.2783 20.9248 10.9147 20.1054C10.4427 19.4387 9.95075 18.8694 9.47475 18.3134C7.72557 16.6738 6.71295 14.3971 6.66675 12C6.66675 6.84536 10.8454 2.66669 16.0001 2.66669C21.1547 2.66669 25.3334 6.84536 25.3334 12C25.2792 14.3824 24.2743 16.6442 22.5427 18.2814L22.5201 18.308C22.0454 18.864 21.5547 19.44 21.0921 20.1C20.7289 20.9217 20.4635 21.7831 20.3014 22.6667L20.0001 25.3334ZM16.0001 5.33335C12.32 5.33776 9.33782 8.31995 9.33341 12C9.33341 14.0587 10.1921 15.0574 11.4907 16.5707C11.9841 17.1467 12.5441 17.7974 13.0907 18.5587C13.7542 19.8478 14.2074 21.2346 14.4334 22.6667H17.5681C17.7999 21.2387 18.2524 19.8555 18.9094 18.5667C19.4427 17.8054 20.0014 17.1507 20.4934 16.5747L20.5134 16.5507C21.8094 15.0307 22.6667 14.0267 22.6667 12C22.6623 8.31995 19.6802 5.33776 16.0001 5.33335Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}
