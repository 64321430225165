import React from 'react'
import { Box, Divider } from '@mui/material'
import { Button, Typography } from 'ui'
import { Formik } from 'formik'
import FormikTextField from '../FormikTextField'
import { useCurrentUser } from '../hooks/useCurrentUser'
import Password from '../icons/password.svg'
import AuthForm from './AuthForm'
import GithubButton from './GithubButton'
import GoogleButton from './GoogleButton'
import LoginLayout from './LoginLayout'
import { useChangePassword } from './authQueries'

export default function SignUpSetup() {
  const { currentUser } = useCurrentUser()
  const [changePassword] = useChangePassword()

  return (
    <LoginLayout
      main={
        <Formik
          initialValues={{
            password: '',
            confirmPassword: '',
          }}
          validate={(values) => {
            const errors = {}
            if (values.password && values.password !== values.confirmPassword) {
              errors.confirmPassword = 'does not match with password'
            }
            return errors
          }}
          onSubmit={async (values, { setSubmitting, setFieldError }) => {
            try {
              const { userErrors } = await changePassword(currentUser.id, values.password)

              if (userErrors && userErrors.length > 0) {
                userErrors.map((e) => setFieldError(e.path, e.message))
              }
            } finally {
              setSubmitting(false)
            }
          }}
        >
          {({ handleSubmit, isSubmitting, dirty, isValid }) => (
            <AuthForm onSubmit={handleSubmit} title="Sign Up Setup">
              <Typography>
                Please set password or link with existing google or github account.
              </Typography>
              <br />

              <FormikTextField
                fullWidth
                name="password"
                placeholder="Password"
                type="password"
                autoComplete="current-password"
                gutterBottom
                startAdornment={<Password />}
                large
              />
              <FormikTextField
                fullWidth
                name="confirmPassword"
                placeholder="Confirm your password"
                type="password"
                autoComplete="current-password"
                gutterBottom
                startAdornment={<Password />}
                large
              />
              <Box marginTop={1}>
                <Button
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={isSubmitting || !dirty || !isValid}
                  large
                >
                  Set password
                </Button>
              </Box>
              <Box marginY={3}>
                <Divider />
              </Box>
              <Box marginBottom={2}>
                <GithubButton redirectTo="/" action="link" label="Link Github" />
              </Box>
              <GoogleButton redirectTo="/" action="link" label="Link Google" />
            </AuthForm>
          )}
        </Formik>
      }
    />
  )
}
