import React, { useMemo } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useCurrentOrganization } from './hooks/useCurrentOrganization'

const PageTitle = ({ pageName }: { pageName: string }) => {
  const {
    query: { organizationSlug, propertySlug, environmentName },
  } = useRouter()

  const { currentOrganization } = useCurrentOrganization()

  const title = useMemo(() => {
    const propertyName = 'Edgio'
    if (!pageName) return propertyName

    const context = [
      currentOrganization?.name || organizationSlug,
      propertySlug,
      environmentName,
    ].filter((item) => item)

    return context.length
      ? `${pageName} · ${context.join(' / ')} · ${propertyName}`
      : `${pageName} · ${propertyName}`
  }, [currentOrganization, organizationSlug, propertySlug, environmentName, pageName])

  return (
    <Head>
      <title>{title}</title>
    </Head>
  )
}

export default PageTitle
