import { useRouter } from 'next/router'
import { BuildPage } from './BuildPage'
import Loading from '../Loading'
import { useAppStore } from '../stores/appStore/appStore'
import { EnvironmentPage } from './EnvironmentPage'
import { OrganizationPage } from './OrganizationPage'
import { PartnerPage } from './PartnerPage'
import { PropertyPage } from './PropertyPage'

export const Page = ({ children }) => {
  const {
    query: { partnerSlug, organizationSlug, propertySlug, environmentName, buildNumber },
  } = useRouter()

  const { isForceLoading } = useAppStore((s) => ({ isForceLoading: s.isForceLoading }))

  if (isForceLoading) {
    return <Loading />
  }

  if (partnerSlug) {
    return <PartnerPage>{children}</PartnerPage>
  }

  if (buildNumber) {
    return <BuildPage>{children}</BuildPage>
  }

  if (environmentName) {
    return <EnvironmentPage>{children}</EnvironmentPage>
  }

  if (propertySlug) {
    return <PropertyPage>{children}</PropertyPage>
  }

  if (organizationSlug) {
    return <OrganizationPage>{children}</OrganizationPage>
  }

  return children
}
