import { ChangeEvent, KeyboardEvent, Ref, useContext } from 'react'
import { ReadOnlyContext } from './ReadOnlyContext'
import { BaseInput, CommonInputDefaultProps, CommonInputProps } from './components/BaseInput'

export interface Props extends CommonInputProps {
  /**
   * @ignore
   * Used only by the `AutoComplete` component
   */
  inputProps?: object
  /**
   * @ignore
   * Used only by the `AutoComplete` component
   */
  InputProps?: object
  /**
   * @ignore
   * Used only by the `DatePicker` component
   */
  inputRef?: Ref<HTMLInputElement>
  /**
   * @ignore
   * Used only by the `Select` component
   */
  labelRequired?: boolean
  /** Callback fired when `input` value changes. */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  /**
   * Type of the `input` element. It should be
   * <a href="https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill" target="_blank">a valid HTML5 input type</a>.
   */
  type?: string
  min?: number
  /** The value of the `input` element, required for a controlled component. */
  value?: string
  flexWrap?: boolean
  onKeyDown?: (event: KeyboardEvent) => void
  className?: string
  /** QA test hook */
  ['data-qa']?: string
}

/**
 * A text input.
 */
export function TextField({
  style,
  autoComplete,
  autoFocus,
  disabled,
  endAdornment,
  error,
  fullWidth,
  gutterBottom,
  helperText,
  inputProps,
  InputProps,
  inputRef,
  label,
  labelRequired = false,
  large,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  required,
  startAdornment,
  type = 'text',
  min,
  value,
  flexWrap,
  onKeyDown,
  ...props
}: Props) {
  const forceDisabled = useContext(ReadOnlyContext)

  return (
    <BaseInput
      style={style}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      disabled={forceDisabled || disabled}
      endAdornment={endAdornment}
      error={error}
      fullWidth={fullWidth}
      gutterBottom={gutterBottom}
      helperText={helperText}
      inputProps={inputProps}
      InputProps={InputProps}
      inputRef={inputRef}
      label={label}
      labelRequired={labelRequired}
      large={large}
      name={name}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      startAdornment={startAdornment}
      type={type}
      value={value}
      flexWrap={flexWrap}
      onKeyDown={onKeyDown}
      min={min}
      qaData={props['data-qa']}
    />
  )
}

TextField.defaultProps = CommonInputDefaultProps
