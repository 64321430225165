import * as React from 'react'
import { SVGProps } from 'react'

interface SVGRProps {
  title?: string
  titleId?: string
}
const SvgBox = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="18px"
    height="18px"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M15.75 12V6A1.5 1.5 0 0 0 15 4.702l-5.25-3a1.5 1.5 0 0 0-1.5 0l-5.25 3A1.5 1.5 0 0 0 2.25 6v6A1.5 1.5 0 0 0 3 13.297l5.25 3a1.5 1.5 0 0 0 1.5 0l5.25-3A1.5 1.5 0 0 0 15.75 12Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.453 5.22 9 9.008l6.547-3.788M9 16.56V9"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgBox
