import React, { useMemo } from 'react'
import { TextField } from 'ui'
import { Props as TextFieldProps } from 'ui/forms/TextField'
import { FastField, Field, FieldConfig } from 'formik'
import getValue from 'lodash/get'

type ShowErrorCallbackParams = {
  dirty: boolean
  isTouched: boolean
}

type Props = {
  readOnly?: boolean
  fast?: boolean
  shouldShowError?: (params: ShowErrorCallbackParams) => boolean
} & TextFieldProps &
  Pick<FieldConfig, 'validate' | 'name'>

export default function FormikTextField({
  name,
  helperText,
  readOnly,
  validate,
  fast,
  shouldShowError,
  ...otherProps
}: Props) {
  const Component = useMemo(() => (fast ? FastField : Field), [fast])

  return (
    // <Field> uses <Formik> context to access parent form values and handlers
    <Component name={name} validate={validate}>
      {({ field: { name, value, onChange, onBlur }, form: { errors, dirty, touched } }) => {
        // Since forms can have multiple levels e.g { 'name': 'Bob', emails: [{ value: 'bob@bob.bob' }] }
        // So for the emails, field name will be 'emails[0].value' that means that we have to use
        // _.get instead of a errors[name] since { 'emails[0].value': 'VALUE' } is not existing in that object.
        const error = getValue(errors, name)
        const isTouched = getValue(touched, name)
        const showError = shouldShowError
          ? shouldShowError({ dirty, isTouched }) && !!error
          : dirty && isTouched && !!error

        return (
          <TextField
            error={showError}
            helperText={showError ? error : helperText}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            disabled={readOnly}
            {...otherProps}
          />
        )
      }}
    </Component>
  )
}
