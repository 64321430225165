import { gql, useMutation } from '@apollo/client'

export const createAiChatMutation = gql`
  mutation createAiChat(
    $organizationId: String!
    $messages: String!
    $model: String!
    $purpose: String!
  ) {
    createAiChat(
      organizationId: $organizationId
      messages: $messages
      model: $model
      purpose: $purpose
    ) {
      aiChat {
        id
        result
      }
    }
  }
`

export const updateAiChatMutation = gql`
  mutation updateAiChat(
    $id: String!
    $success: Boolean
    $error: String
    $feedback: String
    $correction: String
  ) {
    updateAiChat(
      id: $id
      success: $success
      error: $error
      feedback: $feedback
      correction: $correction
    ) {
      aiChat {
        id
      }
    }
  }
`

export const validateJsonMutation = gql`
  mutation validateJson($value: JSON!) {
    validateJson(value: $value) {
      result {
        valid
        message
      }
    }
  }
`

type Chat = {
  organizationId: string
  messages: string
  model: string
  temperature: number
  purpose: string
}

type ChatResult = {
  id: string
  success?: boolean
  error?: string
  feedback?: string
  correction?: any
}

type ValidateJson = {
  value: any
}

export function useAiChat() {
  const [createAiChat] = useMutation(createAiChatMutation)
  const [updateAiChat] = useMutation(updateAiChatMutation)
  const [validateJson] = useMutation(validateJsonMutation)

  return {
    createAiChat(variables: Chat) {
      return createAiChat({ variables })
    },
    updateAiChat(variables: ChatResult) {
      return updateAiChat({ variables })
    },
    validateJson(variables: ValidateJson) {
      return validateJson({ variables })
    },
  }
}
