import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Box } from '@mui/material'
import { Link } from 'ui'
import { Button } from 'ui'
import StaffButton from './admin/StaffButton'

const useStyles = makeStyles()((theme) => ({
  root: {
    textAlign: 'center',
    marginTop: '25%',
    fontSize: theme.typography.h2.fontSize,
    color: theme.palette.text.secondary,
    fontWeight: 100,
  },
}))

export default function NotFound() {
  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <b>404</b>&nbsp;&nbsp;|&nbsp;&nbsp;The page you are looking for could not be found.
      <Box display="flex" justifyContent="center" my={4}>
        <StaffButton label="Try again as Staff" />

        <Link href="/" passHref>
          <Button large variant="contained">
            Back to home
          </Button>
        </Link>
      </Box>
    </div>
  )
}
