import { useCallback, useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Theme } from '@mui/material'
import moment from 'moment'
import { DateRange } from 'moment-range'
import { TCalendarMode } from './Calendar'
import CalendarWeekdays from './CalendarWeekdays'
import { TCalendarCell } from './calendarGridUtils'
import { generateDateGrid, generateMonthGrid, generateYearGrid } from './calendarGridUtils'

export type TCalendarFrame = {
  granularity: string
  date: moment.Moment
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {},
  calendarCell: {
    height: '40px',

    '&.selectable': {
      cursor: 'pointer',

      '&:hover': {
        color: '#019F7F',
      },
    },
    '&.disabled': {
      color: '#ccc',
    },
    '&.faded': {
      color: '#ccc',
    },

    '&.inRange': {
      backgroundColor: theme.palette.mode === 'dark' ? '#576D69' : '#D9F3EE',
      borderRadius: 0,
    },

    '&.active,  &.active:hover': {
      backgroundColor: '#019F7F',
      color: '#eee',
    },

    '&.rangeStart': {
      borderRadius: '20px 0 0 20px',
      '&.rangeEnd': {
        borderRadius: '20px',
      },
    },

    '&.rangeEnd': {
      borderRadius: '0 20px 20px 0',
    },

    '&.date': {
      paddingTop: '9px',
      width: '40px',
    },

    '&.month, &.year': {
      height: '70px',
      width: '70px',
      paddingTop: '23px',
    },
  },
  calendarRow: {
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'center',
  },
}))

type Props = {
  frame: TCalendarFrame
  mode: TCalendarMode
  availableRange: DateRange
  range: DateRange
  frameChange: any
  rangeChange: any
}

export const CalendarGrid: React.FC<Props> = ({
  frame,
  mode,
  range,
  availableRange,
  frameChange,
  rangeChange,
}) => {
  const { classes, cx } = useStyles()

  const [grid, setGrid] = useState<TCalendarCell[][]>()

  const generateGrid = useCallback(() => {
    switch (frame.granularity) {
      case 'date':
        return generateDateGrid(frame.date, range, availableRange, mode)
      case 'month':
        return generateMonthGrid(frame.date, range, availableRange, mode)
      case 'year':
        return generateYearGrid(range, availableRange, mode)
    }
  }, [frame])

  useEffect(() => setGrid(generateGrid()), [generateGrid])

  const onCellClick = (cell: TCalendarCell) => {
    if (!cell.selectable) {
      return
    }

    switch (frame.granularity) {
      case 'year':
        return onYearClick(cell)
      case 'month':
        return onMonthClick(cell)
      case 'date':
        return onDateClick(cell)
    }
  }

  const onDateClick = (cell: TCalendarCell) => {
    if (cell.faded) {
      frameChange({
        granularity: 'date',
        date: moment(cell.moment).startOf('month'),
      })
    }

    switch (mode) {
      case 'date':
      case 'time':
        return rangeChange(new DateRange(moment(cell.moment), moment(cell.moment).endOf('day')))
      case 'rangeStart':
        return rangeChange(new DateRange(cell.moment, range.end))
      case 'rangeEnd':
        return rangeChange(
          new DateRange(
            range.start,
            moment.min(moment(cell.moment).endOf('day'), availableRange.end),
          ),
        )
    }
  }

  const onMonthClick = (cell: TCalendarCell) => {
    if (mode === 'month') {
      return rangeChange(
        new DateRange(cell.moment, moment.min(moment(cell.moment).endOf('month'), moment())),
      )
    }

    frameChange({
      granularity: 'date',
      date: moment(cell.moment),
    })
  }

  const onYearClick = (cell: TCalendarCell) => {
    frameChange({
      granularity: 'month',
      date: moment(cell.moment),
    })
  }

  return (
    <div className={classes.container}>
      {frame.granularity === 'date' && <CalendarWeekdays />}
      {grid?.map((row, i) => (
        <div key={i} className={cx(classes.calendarRow)}>
          {row.map((cell, i) => (
            <div
              key={i}
              className={cx(classes.calendarCell, {
                active: cell.active,
                disabled: !cell.selectable,
                selectable: cell.selectable && !cell.active,
                faded: cell.faded,
                inRange: cell.inRange,
                rangeStart: cell.rangeStart,
                rangeEnd: cell.rangeEnd,
                date: frame.granularity === 'date',
                month: frame.granularity === 'month',
                year: frame.granularity === 'year',
              })}
              onClick={() => onCellClick(cell)}
            >
              {cell.title}
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default CalendarGrid
