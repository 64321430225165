import { ReactElement, forwardRef } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Chip as MuiChip } from '@mui/material'
import { TChipProps } from './types'

const useStyles = makeStyles()(() => ({
  root: {
    // temporary hardcoded
    height: '20px',
    borderColor: '#01B18D',
    color: '#017C63',
    backgroundColor: '#D9F3EE',
  },
}))

export const Chip = forwardRef<any, TChipProps>(
  ({ label, className, onClick, ...props }: TChipProps, ref): ReactElement<TChipProps> => {
    const { classes } = useStyles()

    return (
      <MuiChip
        ref={ref}
        onClick={onClick}
        variant="outlined"
        size="small"
        color="primary"
        label={label}
        classes={{ root: classes.root }}
        className={className}
        {...props}
      />
    )
  },
)

Chip.displayName = 'Chip'
