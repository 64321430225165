import { ApolloLink, FetchResult, NextLink, Observable, Observer, Operation } from '@apollo/client'

type QueuedRequest = {
  operation: Operation
  observer: Observer<FetchResult>
  forward: NextLink
}

class UpdateEnvironmentVersionDraftMutationLink extends ApolloLink {
  queue: QueuedRequest[]
  sending: boolean
  latestBackendData: {
    lockVersion: number
    id: string
  }

  constructor() {
    super()
    this.queue = []
    this.sending = false
    this.latestBackendData = {
      lockVersion: null,
      id: null,
    }
  }

  request(operation: Operation, forward: NextLink) {
    const { operationName } = operation
    if (operationName !== 'updateEnvironmentVersionDraft') {
      return forward(operation)
    }

    return new Observable((observer: Observer<FetchResult>) => {
      this.queue.push({
        operation,
        observer,
        forward,
      })
      this.sendNextMutation()
    })
  }

  sendNextMutation() {
    if (this.sending) {
      return
    }
    this.sending = true

    if (this.queue.length > 0) {
      this.queue.forEach((request, idx) => {
        const { operation, observer, forward } = request

        // send only last in queue
        if (idx === this.queue.length - 1) {
          if (
            operation.variables.environmentVersion.id === this.latestBackendData.id &&
            this.latestBackendData.lockVersion
          ) {
            const lockVersion = Math.max(
              operation.variables.environmentVersion.lockVersion,
              this.latestBackendData.lockVersion,
            )
            if (lockVersion) {
              operation.variables.environmentVersion.lockVersion = lockVersion
            }
          }

          forward(operation).subscribe({
            next: (data) => {
              this.setLatestBackendData(data)
              observer.next(data)
            },
            error: (error) => {
              observer.error(error)
            },
            complete: () => {
              observer.complete()
              this.sending = false
              // send mutation from queue (will complete the loop if queue is empty)
              this.sendNextMutation()
            },
          })
        } else {
          // terminate not last in queue requests
          observer.complete()
        }
      })
      this.queue = []
    } else {
      this.sending = false
    }
  }

  setLatestBackendData(data: FetchResult) {
    if (data.data?.updateEnvironmentVersionDraft?.environmentVersion?.id) {
      this.latestBackendData = {
        lockVersion: data.data.updateEnvironmentVersionDraft.environmentVersion.lockVersion,
        id: data.data.updateEnvironmentVersionDraft.environmentVersion.id,
      }
    }
  }
}

export default UpdateEnvironmentVersionDraftMutationLink
