import { TypographyVariantsOptions } from '@mui/material'
import { Colors } from '../colors'

const darkTypography: TypographyVariantsOptions = {
  h1: {
    color: 'white',
  },
  subtitle1: {
    color: Colors.grey.t50,
  },
  body1: {
    color: 'white',
  },
  body2: {
    color: 'white',
  },
  caption: {
    color: Colors.grey.t50,
  },
  // @ts-ignore - for some reason code type override is not seen here
  code: {
    backgroundColor: '#0A0E12',
  },
}

export default darkTypography
