import React, { useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Help as DeleteIcon, FileCopyTwoTone } from '@mui/icons-material'
import { Avatar, Chip, IconButton, Tooltip, Typography } from '@mui/material'
import { gql } from '@apollo/client'
import PropTypes from 'prop-types'
import DeployUserLogo from './icons/deploy-token-avatar.svg'
import copyToClipboard from './util/copyToClipboard'
import hslFromString from './util/hslFromString'

export const ACTOR_AVATAR_FRAGMENT = gql`
  fragment ActorAvatar on Actor {
    deployToken {
      id
      name
    }
    partnerApiToken {
      id
      name
    }
    user {
      id
      avatarUrl
      email
      fullname
      staffRole
    }
    jwtClient {
      id
      name
    }
  }
`

const useStyles = makeStyles()((theme) => ({
  avatar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '& img': {
      position: 'absolute',
    },
  },
  tiny: {
    height: 20,
    width: 20,
    fontSize: theme.typography.body2.fontSize,
  },
  icon: {
    height: 32,
    width: 32,
  },
  thumbnail: {
    height: 40,
    width: 40,
  },
  full: {
    height: 100,
    width: 100,
  },
  staffChip: {
    position: 'absolute',
    fontSize: '12px',
    top: '-6px',
    left: '25px',
    height: '20px',
  },
  avatarBox: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.75),
  },
}))

function getUserInitials(user) {
  return user?.email
    ?.split('@')[0]
    .split('.')
    .map((words) => words[0].toUpperCase())
    .join('')
}

const TooltipTitle = ({ user, showStaffChip }) => {
  return (
    <>
      <Typography variant="body1">{user.fullname}</Typography>
      {user.staffRole && showStaffChip && <Typography variant="body2">Edgio Team</Typography>}
      <Typography variant="body2">
        {user.email}
        <IconButton size="small" onClick={() => copyToClipboard(user.email)}>
          <FileCopyTwoTone fontSize="small" />
        </IconButton>
      </Typography>
    </>
  )
}

export default function ActorAvatar({
  actor = {},
  variant,
  showTooltip = false,
  showStaffChip = false,
  showName = false,
  onClick = () => {},
  className = null,
  ...otherProps
}) {
  const { classes, cx } = useStyles()

  const backgroundColor = useMemo(() => {
    return hslFromString(actor.user?.fullname || actor.deployToken?.name || '')
  }, [actor.deployToken?.name, actor.user?.fullname])

  if (actor.user) {
    const user = actor.user
    const initials = getUserInitials(user)
    const avatar = (
      <div className={classes.avatarBox}>
        <Avatar
          src={user.avatarUrl || user.avatar_url}
          style={{ backgroundColor, cursor: onClick ? 'pointer' : 'auto' }}
          className={cx(className, classes.avatar, classes[variant])}
          onClick={onClick}
          {...otherProps}
        >
          {initials}
        </Avatar>
        {user.staffRole && showStaffChip && (
          <Chip className={classes.staffChip} size="small" color="primary" label="edgio" />
        )}
        {showName && <Typography variant="body2">{user.fullname || user.email}</Typography>}
      </div>
    )
    if (showTooltip) {
      return (
        <Tooltip interactive title={<TooltipTitle user={user} showStaffChip={showStaffChip} />}>
          {avatar}
        </Tooltip>
      )
    }
    return avatar
  }

  const tokenActor = actor.deployToken || actor.partnerApiToken || actor.jwtClient
  if (tokenActor) {
    // The actor is a deploy token
    return (
      <div title={tokenActor.name} className={classes.icon}>
        <DeployUserLogo className={classes.icon} style={{ color: backgroundColor }} />
      </div>
    )
  }

  if (!actor.user?.email) {
    return (
      <Tooltip title="Deleted User" placement="right-end">
        <Avatar className={classes.icon}>
          <DeleteIcon />
        </Avatar>
      </Tooltip>
    )
  }

  return (
    <div title={'deleted user or token'} className={classes.icon}>
      <Avatar className={classes.icon}>
        <DeleteIcon />
      </Avatar>
    </div>
  )
}

ActorAvatar.propTypes = {
  actor: PropTypes.object,
  variant: PropTypes.oneOf(['tiny', 'icon', 'thumbnail', 'full']),
  onClick: PropTypes.func,
}

ActorAvatar.defaultProps = {
  variant: 'thumbnail',
}
