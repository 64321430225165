import React, { useContext, useMemo } from 'react'
import { CssBaseline, GlobalStyles, createTheme } from '@mui/material'
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { baseGlobalOverrides } from 'ui/theme/base/globalOverrides'
import darkTheme from 'ui/theme/dark/darkTheme'
import { darkGlobalOverrides } from 'ui/theme/dark/globalOverrides'
import { lightGlobalOverrides } from 'ui/theme/light/globalOverrides'
import lightTheme from 'ui/theme/light/lightTheme'
import merge from 'ts-deepmerge'
import DarkModeContext from './DarkModeContext'

export default function ThemeProvider({ children, darkMode }) {
  const darkModeFromContext = useContext(DarkModeContext).darkMode

  if (darkMode === undefined) {
    darkMode = darkModeFromContext
  }

  const theme = darkMode ? createTheme(darkTheme) : createTheme(lightTheme)

  const globalStyleOverrides = useMemo(() => {
    return darkMode
      ? merge(baseGlobalOverrides, darkGlobalOverrides)
      : merge(baseGlobalOverrides, lightGlobalOverrides)
  }, [darkMode])

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <CssBaseline>
          <GlobalStyles styles={globalStyleOverrides} />
          {children}
        </CssBaseline>
      </MuiThemeProvider>
    </StyledEngineProvider>
  )
}
