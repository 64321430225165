import React, { useState } from 'react'
import WarningIcon from '@mui/icons-material/Warning'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material'
import { Button } from 'ui'
import LoadingDialog from './LoadingDialog'

type Props = {
  confirmLabel?: string
  denyLabel?: string
  title: string | React.ReactNode
  text?: string | React.ReactNode
  onConfirm: () => Promise<any> | void
  onDeny: () => void
  onClose: () => void
  open: boolean
  children?: React.ReactNode
  disableConfirm?: boolean
  loading?: boolean
  dialogContentStyles?: React.CSSProperties
  warning?: boolean
} & Partial<DialogProps>

export default function ConfirmationDialog({
  confirmLabel = 'Confirm',
  denyLabel = 'Cancel',
  title,
  text,
  onConfirm,
  onDeny,
  onClose,
  open,
  children,
  disableConfirm = false,
  dialogContentStyles = {},
  warning = false,
  ...other
}: Props) {
  const [isConfirming, setIsConfirming] = useState(false)

  const safeOnConfirm = async () => {
    if (isConfirming) return
    setIsConfirming(true)
    try {
      await onConfirm()
    } finally {
      setIsConfirming(false)
    }
  }

  return (
    <Dialog onClose={onClose} open={open} {...other}>
      {isConfirming && <LoadingDialog />}
      <DialogTitle>{title}</DialogTitle>
      <DialogContent style={dialogContentStyles}>
        {text && <DialogContentText component="div">{text}</DialogContentText>}
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={onDeny} large>
          {denyLabel}
        </Button>
        {confirmLabel && (
          <Button
            variant="contained"
            disabled={disableConfirm || isConfirming}
            onClick={safeOnConfirm}
            large
            endIcon={warning ? <WarningIcon /> : null}
          >
            {confirmLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
