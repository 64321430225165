import React, { createContext } from 'react'
import { SnackbarProvider } from 'notistack'

export const SnackbarContext = createContext()

export default function Snackbar({ children }) {
  const snackbarProps = {
    maxSnack: 4,
    dense: true,
    autoHideDuration: 3000,
  }
  return <SnackbarProvider {...snackbarProps}>{children}</SnackbarProvider>
}
