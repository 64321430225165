import { TypographyVariantsOptions } from '@mui/material'

const baseTypography: TypographyVariantsOptions = {
  fontFamily: '"Inter", sans-serif',
  h1: {
    fontSize: '2rem',
    lineHeight: 1.2,
    margin: '0.5rem 0 2rem',
  },
  h2: {
    fontSize: '1.75rem',
    margin: '15px 0 15px',
  },
  h3: {
    fontSize: '1.5rem',
  },
  h4: {
    fontSize: '1.25rem',
  },
  subtitle1: {
    fontSize: '1.15rem',
    lineHeight: 1.2,
  },
  body2: {
    fontSize: '0.875rem',
  },
  button: {
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '1rem',
    textTransform: 'none',
  },
  caption: {
    lineHeight: 1.33,
  },
  code: {
    fontFamily: "Menlo, Monaco, 'Courier New', monospace", // Here we use the font from VS Code because it's the most popular editor.  One day this comment will be horribly outdated.
    fontSize: '0.85rem',
  },
}
export default baseTypography
