import React from 'react'
import { DraggableProvided } from 'react-beautiful-dnd'

export type TAccordionSummary = {
  classes?: any
  title?: string | React.ReactElement
  children?: React.ReactNode
  draggableProvided?: DraggableProvided
  onDelete?: () => void
}
