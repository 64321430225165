import Loading from '../Loading'
import NotFound from '../NotFound'
import { useCurrentEnvironment } from '../hooks/useCurrentEnvironment'
import { OrganizationPage } from './OrganizationPage'
import { PropertyPage } from './PropertyPage'

export const EnvironmentPage = ({ children }) => {
  const { loading, currentEnvironment } = useCurrentEnvironment()

  if (loading) {
    return <Loading />
  }
  if (!currentEnvironment) {
    return <NotFound />
  }

  return (
    <OrganizationPage>
      <PropertyPage>{children}</PropertyPage>
    </OrganizationPage>
  )
}
