import { Ref, forwardRef } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Theme } from '@mui/material'
import MuiButton from '@mui/material/Button'
import { Colors } from './theme'

const useStyles = makeStyles<void, 'disabled'>()((theme: Theme, _params) => ({
  root: {
    height: 32,
    paddingLeft: 12,
    paddingRight: 12,
    textDecoration: 'none',
    borderRadius: 3,
    whiteSpace: 'nowrap',
    ...(theme.typography.body2 as { fontSize: string; color: string }),
    fontWeight: 500,
    color: 'white',
  },
  contained: {
    background: `linear-gradient(to right, ${Colors.green['100']}, ${Colors.blue['100']})`,
    '&:hover': {
      background: `linear-gradient(to right, ${Colors.blue['100']}, ${Colors.green['100']})`,
    },
    boxShadow: 'none',
  },
  containedSecondary: {
    background: 'linear-gradient(89.86deg, #812991 -1.31%, #00A2E2 138.3%)',
    '&:hover': {
      background: 'linear-gradient(89.86deg, #812991 -1.31%, #00A2E2 138.3%)',
    },
  },
  containedDanger: {
    background: `${theme.palette.danger.main} !important`,
    color: theme.palette.common.white,
    '&:hover, &:active': {
      background: `${theme.palette.danger.light} !important`,
    },
  },
  outlined: {
    backgroundColor: theme.palette.mode === 'light' ? 'transparent' : Colors.grey.s70,
    border: `1px solid ${theme.palette.mode === 'light' ? Colors.grey.t85 : Colors.grey[100]}`,
    color: theme.palette.mode === 'light' ? Colors.green[100] : Colors.green.t35,
    '&:hover': {
      color: theme.palette.mode === 'light' ? Colors.green[100] : Colors.green.t25,
      backgroundColor: theme.palette.mode === 'light' ? Colors.green.t92 : '#05332D',
      borderColor: theme.palette.mode === 'light' ? Colors.grey.t85 : Colors.grey[100],
    },
  },
  outlinedSecondary: {
    borderColor: theme.palette.mode === 'dark' ? Colors.grey[100] : Colors.grey.t85,
    color: theme.palette.mode === 'dark' ? Colors.grey.t75 : Colors.grey.t50,
    '&:hover': {
      color: theme.palette.mode === 'dark' ? Colors.grey.t75 : Colors.grey.t50,
      backgroundColor: `${Colors.green[100]}1A`, // 80% opacity
      borderColor: theme.palette.mode === 'dark' ? Colors.grey[100] : Colors.grey.t85,
    },
  },
  outlinedWhite: {
    color: 'white',
    backgroundColor: 'transparent',
    borderColor: 'white',
    '&:hover': {
      color: 'white',
      borderColor: 'white',
      backgroundColor: `#FFFFFF1A`, // 80% opacity
    },
  },
  outlinedDanger: {
    color: `${theme.palette.danger.main} !important`,
    borderColor: theme.palette.danger.main,
    '&:hover, &:active': {
      background: 'inherit',
      borderColor: theme.palette.danger.light,
      color: `${theme.palette.danger.light} !important`,
    },
  },
  outlinedWarning: {
    color: `${theme.palette.warning.main} !important`,
    border: `1px solid ${theme.palette.warning.main}`,
    '&:hover': {
      color: `${theme.palette.warning.main} !important`,
      border: `1px solid ${theme.palette.warning.main}`,
    },
  },
  outlinedPrimary: {},
  disabled: {
    opacity: 0.6,
  },
  sizeSmall: {
    height: 24,
  },
  text: {
    color: theme.palette.mode === 'light' ? Colors.green[100] : Colors.green.t35,
    '&:hover': {
      color: theme.palette.mode === 'light' ? Colors.green[100] : Colors.green.t25,
      backgroundColor: theme.palette.mode === 'light' ? Colors.green.t92 : '#05332D',
    },
  },
  textWhite: {
    color: 'white',
    '&:hover': {
      color: 'white',
      backgroundColor: `#FFFFFF1A`, // 80% opacity
    },
  },
}))

interface CaptchaProps {
  className: string
  'data-sitekey': string
  'data-action': string
  'data-callback': string
}

export interface Props {
  style?: any
  /** Used to pass on props necessary for captcha. */
  captchaProps?: CaptchaProps
  /** The content of the button. */
  children?: string | JSX.Element | string[]
  /** The color of the component. It supports those theme colors that make sense for this component. */
  color?: 'primary' | 'secondary' | 'dual' | 'danger' | 'warning' | 'white'
  /** If `true`, the button will be disabled. */
  disabled?: boolean
  /** Element placed after the children. */
  endIcon?: JSX.Element
  /** If `true`, button will always be outlined, independent of theme. */
  forceOutlined?: boolean
  /** If `true`, the button will take up the full width of its container. */
  fullWidth?: boolean
  /**
   * @ignore
   * Used only by the `Link` component
   */
  href?: string
  /** If `true`, button will be large */
  large?: boolean
  /** Action that is called on the button click. */
  onClick?: () => void
  /** Element placed before the children. */
  startIcon?: JSX.Element
  /** The type of the button. */
  type?: 'button' | 'submit'
  /** The variant to use. */
  variant?: 'outlined' | 'contained' | 'text'
  autoFocus?: boolean
  /** For links, set this to true to open the page in a new browser tab */
  newTab?: boolean
  size?: 'large' | 'medium' | 'small'
  /** QA test hook */
  ['data-qa']?: string
  className?: string
  component?: string
  target?: string
}

/**
 * A button implementation that supports all colors in the theme's palette.
 */
export const Button = forwardRef(
  (
    {
      captchaProps,
      children,
      color = 'primary',
      disabled = false,
      endIcon,
      forceOutlined = false,
      fullWidth = false,
      href,
      onClick,
      startIcon,
      type = 'button',
      variant = 'outlined',
      autoFocus = false,
      size = 'medium',
      style,
      className,
      target,
      ...props
    }: Props,
    ref: Ref<HTMLButtonElement>,
  ) => {
    const { classes } = useStyles()

    return (
      // @ts-ignore
      <MuiButton
        style={style}
        classes={classes}
        className={className}
        // @ts-ignore
        color={color}
        disabled={disabled}
        endIcon={endIcon}
        fullWidth={fullWidth}
        href={href}
        onClick={onClick}
        ref={ref}
        size={size}
        startIcon={startIcon}
        type={type}
        variant={forceOutlined ? 'outlined' : variant}
        autoFocus={autoFocus}
        data-qa={props['data-qa']}
        // @ts-ignore
        component={props.component}
        target={target}
        {...captchaProps}
      >
        {children}
      </MuiButton>
    )
  },
)

Button.displayName = 'Button'
