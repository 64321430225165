import { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { DropzoneOverlay } from './DropzoneOverlay'

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  dragging: {
    pointerEvents: 'none',
    visibility: 'hidden',
  },
}))

type Props = {
  children: React.ReactChild
  onDrop: (files: File[]) => void
  disabled?: boolean
}

export const Dropzone = ({ onDrop, children, disabled = false }: Props) => {
  const { classes, cx } = useStyles()
  const [isDragging, setIsDragging] = useState(false)

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>): void => {
    event.preventDefault()
    if (disabled) return
    setIsDragging(true)
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (disabled) return
    setIsDragging(true)
  }

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (disabled) return
    setIsDragging(false)
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (disabled) return
    setIsDragging(false)
    // @ts-ignore
    const files = [...event.dataTransfer.files]
    onDrop(files)
  }

  return (
    <div
      className={cx({
        [classes.root]: true,
      })}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {isDragging && <DropzoneOverlay />}

      <div className={cx({ [classes.dragging]: isDragging })}>{children}</div>
    </div>
  )
}
