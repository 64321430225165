import { makeStyles } from 'tss-react/mui'
import { Box } from '@mui/material'
import { Theme } from '@mui/material'
import { TDrawerFooter } from '../types'

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
  },
}))

export const DrawerFooter = ({ align, children }: TDrawerFooter) => {
  const { classes } = useStyles()

  return (
    <footer className={classes.root}>
      <Box
        display="flex"
        alignItems="center"
        flexGrow={1}
        justifyContent={`${align === 'left' ? 'flex-start' : 'flex-end'}`}
      >
        {children}
      </Box>
    </footer>
  )
}
