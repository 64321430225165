import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { AutocompleteProps, Autocomplete as MuiAutocomplete } from '@mui/material'
import { ChipTypeMap } from '@mui/material/Chip'

export function Autocomplete<
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>(
  props: AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>,
): JSX.Element {
  return (
    <MuiAutocomplete
      popupIcon={<ExpandMoreRoundedIcon />}
      slotProps={{
        popupIndicator: { size: 'small' },
        clearIndicator: { size: 'small' },
      }}
      {...props}
    />
  )
}
