import { useCurrentUser } from './useCurrentUser'

export const DATE_FORMAT_STRING = "EEEE, MMMM d, yyyy 'at' h:mm aaa"
export const EU_DATE_FORMAT_STRING = "EEEE, d MMMM, yyyy 'at' H:mm aaa"

export const useCurrentUserDateFormat = () => {
  const { currentUser } = useCurrentUser()

  return currentUser?.dateTimeFormat === 'eu' ? EU_DATE_FORMAT_STRING : DATE_FORMAT_STRING
}
