export const CONSOLE_DOMAIN = 'edgio.app'

/**
 * Current runtime environment config
 */
export const CURRENT_ENVIRONMENT_NAME = process.env['ENVIRONMENT_NAME']

export const ENVIRONMENT_PRODUCTION = 'production'
export const ENVIRONMENT_SANDBOX = 'sandbox'
export const ENVIRONMENT_DEVELOPMENT = 'development'
export const ENVIRONMENT_STAGE = 'stage'

export const IS_PROD_ENV = CURRENT_ENVIRONMENT_NAME === ENVIRONMENT_PRODUCTION
export const IS_STAGE_ENV = CURRENT_ENVIRONMENT_NAME === ENVIRONMENT_STAGE
export const IS_SANDBOX_ENV = CURRENT_ENVIRONMENT_NAME === ENVIRONMENT_SANDBOX
export const IS_DEVELOPMENT_ENV = CURRENT_ENVIRONMENT_NAME === ENVIRONMENT_DEVELOPMENT

export const IDS_PORTAL_URL = 'https://account.edgio.app'
export const RUM_SCRIPT_URL = 'https://rum.edgio.net/latest.js'

export const EDGE_INSIGHTS_URL =
  process.env['EDGE_INSIGHTS_URL'] || 'https://edge-insights.edgio.app'

export const PAGE_SIZE = 20
export const LARGE_PAGE_SIZE = 200

// source: https://emailregex.com/
export const EMAIL_REGEX =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
