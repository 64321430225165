import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import QueryNavigationContext from '../QueryNavigationContext'

interface DualQueryStateDefaults {
  [key: string]: any
}

interface DualQueryStateOptions {
  debounceDelay?: number
  syncToUrl?: boolean
}

function queryParamsToState(defaults: DualQueryStateDefaults = {}) {
  const queryParams = {}

  // note: we use location.search instead of queryNavigation here because queryNavigation is only
  // updated after the page has been rendered since it uses router.events.routeChangeComplete
  const search = new URLSearchParams(window.location.search)

  search.forEach((value, key) => {
    const existingValue = queryParams[key]

    if (existingValue) {
      queryParams[key] = Array.isArray(existingValue)
        ? [...existingValue, value]
        : [existingValue, value]
    } else {
      queryParams[key] = value
    }
  })

  return { ...defaults, ...queryParams }
}

/**
 * This hook is used to manage query state in two ways:
 * 1. Sync query state with URL
 * 2. Keep query state in memory
 *
 * @param {{ [key: string]: any }} defaultValues Default values for query state
 * @param {{ debounceDelay: number, syncToUrl: boolean }} options Options for hook
 */
export default function useDualQueryState(
  defaultValues: DualQueryStateDefaults = {},
  options: DualQueryStateOptions = {},
) {
  const { debounceDelay = 600, syncToUrl = true } = options
  const [defaults, setDefaults] = useState(defaultValues)
  const queryNavigation = useContext(QueryNavigationContext)
  const { navigate } = queryNavigation

  const [currentValues, setCurrentValues] = useState(
    syncToUrl ? queryParamsToState(defaultValues) : defaultValues,
  )

  const setDefaultValues = (obj: DualQueryStateDefaults) => {
    setDefaults(Object.assign(defaults, obj))
  }

  /**
   * Update query params with retaining other params
   */
  const updateQueryState = useCallback(
    (updatedValues = {}, options = {}) => {
      setCurrentValues((currentValues) => {
        const newValues = { ...currentValues, ...updatedValues }

        if (syncToUrl) {
          Object.keys(newValues).forEach((key) => {
            if (isEqual(newValues[key], defaults[key])) {
              newValues[key] = undefined
            }
          })

          navigate(newValues, { override: false, action: 'pushState', ...options })
        }

        return newValues
      })
    },
    [navigate],
  )

  const resetQueryState = useCallback(() => {
    updateQueryState(defaults, options)
  }, [defaults])

  /**
   * Debounced version of updateQueryState
   */
  const debouncedUpdateQueryState = useMemo(
    () => debounce((v) => updateQueryState(v), debounceDelay),
    [debounceDelay, updateQueryState],
  )

  let queryState = currentValues

  if (syncToUrl) {
    queryState = queryParamsToState(defaults)
  }

  useEffect(() => {
    if (syncToUrl) {
      setCurrentValues(queryParamsToState())
    }
  }, [syncToUrl, queryNavigation])

  return {
    defaults,
    queryState,
    updateQueryState,
    setDefaultValues,
    resetQueryState,
    debouncedUpdateQueryState,
  }
}
