import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Icon from './../icons/github.svg'

const useStyles = makeStyles()(() => ({
  root: {
    height: 16,
    width: 16,
    '& path': {
      fill: 'currentColor',
    },
  },
}))

export default function GitHubIcon(props) {
  const { classes } = useStyles()
  return <Icon className={classes.root} {...props} />
}
