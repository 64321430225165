import { useContext, useEffect, useState } from 'react'
import isEqual from 'lodash/isEqual'
import { ReadOnlyContext } from './ReadOnlyContext'
import {
  BaseSelect,
  CommonSelectDefaultProps,
  CommonSelectProps,
  OptionType,
} from './components/BaseSelect'

export interface Props<T> extends CommonSelectProps<T> {
  /** Used to determine if an option is selected, considering the current value. Uses strict equality by default. */
  getOptionSelected?: (option: T, value?: Partial<T> | string | number | boolean) => boolean
  /** Callback fired when the value changes. */
  onChange?: (selectedValue: T | null) => void
  /**
   * The value of the autocomplete.
   *
   * The value must have reference equality with the option in order to be selected. You can customize the equality
   * behavior with the `getOptionSelected` prop.
   */
  value?: Partial<T> | string | number | boolean
  classes?: any
  InputProps?: any
  dataQa?: any
}

/**
 * A select component.
 */
export function Select<T extends OptionType>({
  style,
  classes,
  clearable,
  createCustomOption,
  disabled,
  error,
  fullWidth,
  getOptionDisabled = (option) => option.disabled,
  getOptionLabel = (option) => option.name,
  getOptionSelected = (option, value) => option.value === value,
  groupBy,
  gutterBottom,
  helperText,
  label,
  large,
  name,
  onBlur,
  onChange,
  onFocus,
  options,
  placeholder,
  required,
  renderOption,
  searchable,
  value,
  InputProps,
  dataQa,
}: Props<T>) {
  const [selectedValue, setSelectedValue] = useState(
    (value !== undefined && options.find((option) => getOptionSelected(option, value))) || null,
  )

  useEffect(() => {
    setSelectedValue(
      (value !== undefined && options.find((option) => getOptionSelected(option, value))) || null,
    )
  }, [value, options])

  const forceDisabled = useContext(ReadOnlyContext)

  return (
    <BaseSelect
      customClasses={classes}
      style={style}
      clearable={clearable}
      createCustomOption={createCustomOption}
      disabled={forceDisabled || disabled}
      error={error}
      fullWidth={fullWidth}
      getOptionDisabled={getOptionDisabled}
      getOptionLabel={getOptionLabel}
      getOptionSelected={(option) => isEqual(option, selectedValue)}
      groupBy={groupBy}
      gutterBottom={gutterBottom}
      helperText={helperText}
      label={label}
      large={large}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      options={options}
      placeholder={placeholder}
      required={required}
      renderOption={renderOption}
      searchable={searchable}
      selectedValue={selectedValue}
      setSelectedValue={setSelectedValue}
      InputProps={InputProps}
      dataQa={dataQa}
    />
  )
}

Select.defaultProps = CommonSelectDefaultProps
