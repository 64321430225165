import { create } from 'zustand'

type OneUiMode = {
  hexId: string
  permissions: string[]
}

type AppState = {
  oneUiMode: OneUiMode
  isForceLoading: boolean
  isLoggingOut: boolean
  setOneUiMode: (oneUiMode: OneUiMode) => void
  setIsForceLoading: (isLoading: boolean) => void
  setIsLoggingOut: (isLoggingOut: boolean) => void
}

export const useAppStore = create<AppState>()((set) => ({
  oneUiMode: null,
  isForceLoading: false,
  isLoggingOut: false,
  setOneUiMode: (oneUiMode: OneUiMode) => set({ oneUiMode: oneUiMode }),
  setIsForceLoading: (isForceLoading: boolean) => set({ isForceLoading }),
  setIsLoggingOut: (isLoggingOut: boolean) => set({ isLoggingOut }),
}))
