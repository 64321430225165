import { useRef, useState } from 'react'

export default function useDebounceState<T>(
  initialValue: T,
  {
    delay = 500,
    onDebounce = null,
  }: { delay?: number; onDebounce?: ((value: T) => void) | null } = {},
): [T, (value: T) => void, T] {
  const [value, setValue] = useState<T>(initialValue)
  const [debouncedValue, setDebouncedValue] = useState<T>(initialValue)
  const debounceTimeout = useRef<NodeJS.Timeout>()

  const setValueWithDebounce = (value: T) => {
    setValue(value)
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current)
    }
    debounceTimeout.current = setTimeout(() => {
      setDebouncedValue(value)
      onDebounce && onDebounce(value)
    }, delay)
  }

  return [value, setValueWithDebounce, debouncedValue]
}
