import { ReactNode, useContext } from 'react'
import { FormControlLabel, Switch as MuiSwitch, SwitchProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Typography } from '../../../Typography'
import { ReadOnlyContext } from '../../../forms/ReadOnlyContext'
import { customColors } from '../../../theme'

export const CustomizedSwitch = styled(MuiSwitch)(() => ({
  padding: 8,
  '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
    backgroundColor: customColors.EdgioBlue,
    opacity: 1,
  },
  '& .Mui-checked.Mui-disabled + .MuiSwitch-track': {
    backgroundColor: customColors.SilverSand,
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    borderRadius: 22 / 2,
    backgroundColor: '#515A62',

    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 12,
      height: 12,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><path fill="white" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 14,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path fill="white" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>')`,
      right: 14,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
    backgroundColor: 'white',
  },
}))

const Label = styled(Typography)({
  padding: '8px 0',
})

type Props = {
  label?: ReactNode
} & SwitchProps

export const Switch = ({ label, className, ...props }: Props) => {
  const forceReadOnly = useContext(ReadOnlyContext)

  return (
    <FormControlLabel
      className={className}
      style={{ marginLeft: -8, alignItems: 'flex-start' }}
      control={<CustomizedSwitch {...props} disabled={forceReadOnly || props.disabled} />}
      label={<Label variant="body2">{label}</Label>}
    />
  )
}
