import { makeStyles } from 'tss-react/mui'
import { LogoImage } from './LogoImage'
import { LogoImageCollapsed } from './LogoImageCollapsed'

const useStyles = makeStyles()((theme) => ({
  logoWrapper: {
    display: 'flex',
    alignItems: 'end',
  },
  partnerName: {
    marginLeft: '8px',
    paddingBottom: '8px',
    maxWidth: '120px',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    overflow: 'hidden',
    color: theme.palette.text.secondary,
    fontSize: '11px',
    textTransform: 'uppercase',
    '&:hover': {
      textDecoration: 'none !important',
    },
  },
}))

export const Logo = ({ partnerName = null, isCollapsed = false }) => {
  const { classes } = useStyles()

  return (
    <div className={classes.logoWrapper}>
      {isCollapsed ? <LogoImageCollapsed /> : <LogoImage />}
      {partnerName && !isCollapsed && (
        <span title={partnerName} className={classes.partnerName}>
          {partnerName}
        </span>
      )}
    </div>
  )
}
