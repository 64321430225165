import React, { memo, useEffect, useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Box } from '@mui/material'
import { Link, Typography } from 'ui'
import { gql, useQuery as useGraphQLQuery } from '@apollo/client'
import Cookies from 'js-cookie'
import { sortBy } from 'lodash'
import { generateValues } from './DateValue'
import Spacer from './Spacer'
import { useCurrentUser } from './hooks/useCurrentUser'
import { useNotifications } from './util'

const DEFAULT_DETAILS = {
  type: '',
  from: '',
  to: '',
}

const useStyles = makeStyles()((theme) => ({
  text: {
    color: theme.palette.common.white,
  },
  link: {
    color: '#18ffff',
  },
}))

// 5 minutes
const REFRESH_INTERVAL_MS = 5 * 60 * 1000

const consoleStatusQuery = gql`
  query consoleStatus {
    statusPage
  }
`

const useGetStatus = () => {
  const { data } = useGraphQLQuery(consoleStatusQuery, {
    pollInterval: REFRESH_INTERVAL_MS,
  })

  const { maintenance = {}, incidents = [] } = data?.statusPage || {}

  const [maintenanceDetails, isMaintenance] = useMemo(() => {
    const { active = [], upcoming = [] } = maintenance
    const maintenanceAll = [...active, ...upcoming]

    if (maintenanceAll.length) {
      const earliestMaintenance = sortBy(maintenanceAll, 'datetime_planned_start')[0]
      return [
        {
          type: 'Maintenance',
          from: earliestMaintenance.datetime_planned_start,
          to: earliestMaintenance.datetime_planned_end,
        },
        true,
      ]
    }

    return [DEFAULT_DETAILS, false]
  }, [maintenance])

  const [incidentDetails, isIncident] = useMemo(() => {
    if (incidents.length) {
      const earliestOngoingIncident = sortBy(incidents, 'datetime_open')[0]
      return [
        {
          type: 'Incident',
          from: earliestOngoingIncident.datetime_open,
        },
        true,
      ]
    }

    return [DEFAULT_DETAILS, false]
  }, [incidents])

  return { isMaintenance, maintenanceDetails, isIncident, incidentDetails }
}

const NotificationWidget = ({ timezone, details }) => {
  const { classes } = useStyles()
  const utc = timezone === 'utc'

  const { type, from, to } = details
  const dateFrom = new Date(from)
  const dateTo = to ? new Date(to) : ''

  const { timestamp: formattedStartDate, timeZone } = generateValues(dateFrom, 'd MMM yyyy', utc)
  const formattedStartTime = generateValues(dateFrom, 'HH:mm', utc).timestamp
  const formattedEndDate = dateTo ? generateValues(dateTo, 'd MMM yyyy', utc).timestamp : ''
  const formattedEndTime = dateTo ? generateValues(dateTo, 'HH:mm', utc).timestamp : ''

  const isSameDay = !dateTo ? false : formattedStartDate === formattedEndDate

  let message = `from ${formattedStartDate} ${formattedStartTime} ${timeZone}`

  if (dateTo) {
    message = isSameDay
      ? `${formattedStartDate} from ${formattedStartTime} to ${formattedEndTime} ${timeZone}`
      : `from ${formattedStartDate} ${formattedStartTime} to ${formattedEndDate} ${formattedEndTime} ${timeZone}`
  }

  return (
    <Box>
      <Typography variant="body1" className={classes.text} fontWeight={700} gutterBottom>
        Edgio Console {type}
      </Typography>
      <Spacer vertical spacing={1} />
      <Typography variant="body2" className={classes.text} gutterBottom>
        {message}
      </Typography>
      <Spacer vertical spacing={2} />
      <Link href="https://status.edg.io/" className={classes.link} newTab>
        {type === 'Maintenance' ? 'View current status' : 'View latest updates'}
      </Link>
    </Box>
  )
}

export const StatusEventWidget = memo(() => {
  const { currentUser } = useCurrentUser()
  const notifications = useNotifications()
  const { isMaintenance, maintenanceDetails, isIncident, incidentDetails } = useGetStatus()

  useEffect(() => {
    const events = []
    if (isMaintenance) {
      events.push(maintenanceDetails)
    }
    if (isIncident) {
      events.push(incidentDetails)
    }

    if (events.length) {
      events.forEach((eventDetails) => {
        const cookieName = `status_notification_${eventDetails.type}`
        const cookieExpectedValue = `${eventDetails.from}-${eventDetails.to}`

        if (Cookies.get(cookieName) !== cookieExpectedValue) {
          notifications.info(
            <NotificationWidget timezone={currentUser?.timeZoneDisplay} details={eventDetails} />,
            {
              preventDuplicate: true,
              persist: true,
              key: eventDetails.type + eventDetails.from + eventDetails.to,
              onClose: () => Cookies.set(cookieName, cookieExpectedValue, { expires: 1 }),
            },
          )
        }
      })
    }
  }, [isMaintenance, maintenanceDetails, isIncident, incidentDetails])

  return null
})

StatusEventWidget.displayName = 'StatusEventWidget'
