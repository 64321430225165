import React from 'react'
import { Tooltip } from '@mui/material'
import { addMinutes, format, formatDistanceToNow } from 'date-fns'
import { useCurrentUser } from './hooks/useCurrentUser'
import { useCurrentUserDateFormat } from './hooks/useCurrentUserDateFormat'

export function generateValues(date, dateFormat, utcPref) {
  const shiftedDate = utcPref ? addMinutes(date, date.getTimezoneOffset()) : date
  const addSuffix = date.getTime() < Date.now()

  return {
    timestamp: format(shiftedDate, dateFormat),
    timeZone: utcPref ? 'UTC' : format(shiftedDate, 'zzzz'),
    distance: formatDistanceToNow(date, { addSuffix }),
  }
}

export default function DateValue({
  value,
  format: requiredFormat = null,
  variant = 'normal',
  inline = false,
  style = {},
  className = '',
  forceTimestamp = false,
  dateOnly = false,
  forceUtc = false,
}) {
  const { currentUser } = useCurrentUser()

  const dateFormat = useCurrentUserDateFormat()

  if (value == null) return null

  const utc = currentUser?.timeZoneDisplay === 'utc' || forceUtc

  const isTimestamp =
    forceTimestamp ||
    requiredFormat ||
    (currentUser?.dateTimeDisplay || '').indexOf('timestamp') === 0

  const format = dateOnly ? dateFormat.replace(/ 'at'.*/, '') : dateFormat

  let { timestamp, distance, timeZone } = generateValues(
    new Date(value),
    requiredFormat || format,
    utc,
  )

  if (variant === 'short') {
    distance = distance.replace('about ', '')
  }

  return (
    <Tooltip title={isTimestamp ? distance : `${timestamp} ${timeZone}`}>
      <div style={{ display: inline ? 'inline' : 'initial', ...style }} className={className}>
        <span>{isTimestamp ? timestamp : distance}</span>
        {isTimestamp && !dateOnly && <span>{' ' + timeZone}</span>}
      </div>
    </Tooltip>
  )
}
