import { memo } from 'react'
import isString from 'lodash'
import pluralize from './pluralize'

export const timeLabels: { [key: string]: string } = {
  s: 'second',
  m: 'minute',
  h: 'hour',
  d: 'day',
  w: 'week',
  y: 'year',
}

type Props = {
  value?: string
}

/**
 * Pretty formats time durations like "1m" as "1 minute"
 */
const DurationLabel = ({ value }: Props) => {
  if (!value || !isString(value)) return null

  const { duration, unit } = parseTime(value)
  const label = timeLabels[unit]

  return (
    <span>
      {duration} {pluralize(label, duration)}
    </span>
  )
}

/**
 * Parses a time string like "1m" into an object with duration and unit
 * @param time A string like "1m"
 * @returns
 */
export function parseTime(time: string) {
  const duration = parseInt(time.slice(0, time.length - 1))
  const unit = time[time.length - 1]
  return { duration, unit }
}

const memoized = memo(DurationLabel)

export { memoized as DurationLabel }
