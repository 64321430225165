import { useRouter } from 'next/router'
import { useGetPartnerQuery } from '../partner/partnerQueries'

export const useCurrentPartner = () => {
  const {
    query: { partnerSlug },
  } = useRouter()

  const {
    partner: currentPartner,
    loading,
    error,
    refetch,
  } = useGetPartnerQuery(partnerSlug, { skip: !partnerSlug })

  return { currentPartner, loading, error, refetch }
}
