import React from 'react'
import { Box } from '@mui/material'
import { Button } from 'ui'
import { useActivateStaff } from '../admin/adminQueries'
import { useCurrentUser } from '../hooks/useCurrentUser'

export default function StaffButton({ label = 'Activate staff', activateParams = {} }) {
  const { currentUser } = useCurrentUser()
  const activateStaff = useActivateStaff()

  if (!currentUser || !currentUser.staff) return null
  if (currentUser.activeStaffSession) return null

  return (
    <Box marginRight={1}>
      <Button
        large
        onClick={async () => {
          await activateStaff(activateParams)
          window.location.reload()
        }}
      >
        {label}
      </Button>
    </Box>
  )
}
