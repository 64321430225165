import { forwardRef } from 'react'
import { makeStyles } from 'tss-react/mui'
import { AccordionProps, Accordion as MuiAccordion } from '@mui/material'
import { Theme } from '@mui/material'
import { darken, lighten } from '@mui/material/styles'

type Props = {
  background?: 'paper' | 'default'
  customClasses?: { [name: string]: any }
} & AccordionProps

const useStyles = makeStyles()((theme: Theme) => {
  const dark = theme.palette.mode === 'dark'
  const { border, summary } = theme.palette.accordion

  return {
    root: {
      border: `1px solid ${border}`,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      boxShadow: 'none',
      transition: 'margin: 0.3s, box-shadow 0.3s, border-color 0.3s',

      '&:not(:first-child)': {
        marginTop: -1, // collapse adjacent borders
      },

      '&:before': {
        display: 'none',
      },

      '& > div:first-child': {
        transition: 'box-shadow 0.3s, background-color 0.3s',
        backgroundColor: dark ? darken(summary, 0.2) : 'white',
        borderBottom: `1px solid ${border}`,
        marginBottom: -1, // collapse adjacent borders
      },

      '& > div:first-child:hover': {
        backgroundColor: dark ? summary : lighten(summary, 0.3),
      },

      '&.Mui-expanded': {
        borderRadius: theme.shape.borderRadius,
        margin: theme.spacing(2, 0, 2, 0),

        '& > div:first-child': {
          backgroundColor: summary,
          boxShadow: 'none',
        },
      },
    },

    expanded: {},

    // expanded: () => {
    //   return {} // do not remove, for some reason this needs to be here or rules will not properly detach when expanded.
    // },

    paperBackground: {
      borderColor: lighten(border, 0.05),
      '&:hover': {
        boxShadow: 'none',
      },
      '& > div:first-child': {
        backgroundColor: dark ? summary : lighten(summary, 0.1),
        borderColor: lighten(border, 0.05),
      },
      '& > div:first-child:hover': {
        backgroundColor: dark ? lighten(summary, 0.02) : darken(summary, 0.015),
      },
      '&.Mui-expanded': {
        borderColor: lighten(border, 0.05),
      },
      '&.Mui-expanded > div:first-child': {
        backgroundColor: dark ? lighten(summary, 0.02) : summary,
      },
    },
  }
})

export const Accordion = forwardRef(
  (
    { background, customClasses = {}, className, ...props }: Props,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const { classes, cx } = useStyles(undefined, {
      props: {
        classes: customClasses,
      },
    })

    return (
      <MuiAccordion
        ref={ref}
        {...props}
        classes={classes}
        className={cx({
          [className as string]: true,
          [classes.paperBackground]: background === 'paper',
        })}
      />
    )
  },
)

Accordion.displayName = 'Accordion'

Accordion.defaultProps = {
  background: 'default',
}
