import { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react'
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from '@mui/material'
import { Button, TextField } from 'ui'
import { Typography } from 'ui'
import { useCurrentOrganization } from '../../../hooks/useCurrentOrganization'
import DisclaimerCaption from '../../ai/DisclaimerCaption'
import { useAiChat } from '../../ai/aiQueries'

type RegexHelpContextValue = {
  getHelp: () => Promise<string>
}

const RegexHelpContext = createContext<RegexHelpContextValue>(null)

export function useRegexHelp() {
  return useContext(RegexHelpContext)
}

export function RegexHelpProvider({ children }) {
  const [open, setOpen] = useState(false)
  const resolveRef = useRef<(v: string) => void>(null)

  const context = useMemo<RegexHelpContextValue>(() => {
    return {
      getHelp() {
        return new Promise(async (resolve) => {
          setOpen(true)
          resolveRef.current = resolve
        })
      },
    }
  }, [])

  const handleSuccess = (value) => {
    resolveRef.current(value)
  }

  return (
    <RegexHelpContext.Provider value={context}>
      <RegexHelpDialog open={open} onClose={() => setOpen(false)} onSuccess={handleSuccess} />
      {children}
    </RegexHelpContext.Provider>
  )
}

type Props = {
  open: boolean
  onClose: VoidFunction
  onSuccess: (regex: string) => void
}

function RegexHelpDialog({ open, onClose, onSuccess }: Props) {
  const [value, setValue] = useState('')
  const { currentOrganization } = useCurrentOrganization()
  const [error, setError] = useState<string | null>(null)
  const [isSubmitting, setSubmitting] = useState<boolean>(false)
  const { createAiChat, updateAiChat } = useAiChat()

  const handleApply = useCallback(
    async (e) => {
      e.stopPropagation()
      e.preventDefault()
      setSubmitting(true)

      try {
        const res = await createAiChat({
          organizationId: currentOrganization.id,
          messages: JSON.stringify([
            {
              role: 'user',
              content:
                'Write me a regular regular expression for a string that starts with "hello". Respond only with a regular expression. Do not provide an explanation.',
            },
            { role: 'assistant', content: '^hello' },
            {
              role: 'user',
              content: `Write me a regular regular expression for a string that ${value}`,
            },
          ]),
          model: 'gpt-3.5-turbo',
          temperature: 0.2,
          purpose: 'regex-help',
        })

        if (res.data?.createAiChat?.aiChat?.result) {
          try {
            const regex = new RegExp(res.data?.createAiChat?.aiChat?.result)
            setError(null)
            onSuccess(regex.source)
            onClose()
            setValue('')
            updateAiChat({ id: res.data?.createAiChat?.aiChat?.id, success: true })
          } catch (e) {
            updateAiChat({
              id: res.data?.createAiChat?.aiChat?.id,
              success: false,
              error: e.message,
            })
            throw e
          }
        }
      } catch (e) {
        setError(e.message)
      } finally {
        setSubmitting(false)
      }
    },
    [onSuccess, value, currentOrganization, createAiChat, updateAiChat, onClose],
  )

  const theme = useTheme()

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Regular Expression AI Assistant</DialogTitle>
      <form onSubmit={handleApply}>
        <DialogContent
          style={{ width: 600, display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}
        >
          <Typography>Write me a regular expression that:</Typography>
          <TextField
            autoFocus
            onChange={(e) => setValue(e.target.value)}
            value={value}
            fullWidth
            placeholder='Example: does not contain "checkout" or "account" in the path'
          />
          {error && <Typography color="error">Error: {error}</Typography>}
          <DisclaimerCaption label="Apply" />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} large>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            large
            disabled={isSubmitting}
            startIcon={isSubmitting && <CircularProgress size={16} color="inherit" />}
          >
            Apply
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
