import { useMemo, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { IconButton, Popover } from '@mui/material'
import { Button } from 'ui'
import { Colors } from 'ui'
import { ChevronDown } from 'icons'
import { map, omit } from 'lodash'
import { Moment } from 'moment'
import { DateRange } from 'moment-range'
import DPCalendar from './calendar/Calendar'
import {
  TDatePickerRange,
  TDatePickerRangeId,
  formatRangeDropdownTitle,
  generateRange,
  isCustomRange,
  rangeNames,
} from './dateRangePickerUtils'

type Props = {
  disabledRanges?: TDatePickerRangeId[]
  range: TDatePickerRange
  minDate?: Moment
  rangeChange: (range: TDatePickerRange) => void
  isUtc?: boolean
}

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
  },
  dropdownAnchor: {
    height: '34px',
    borderRadius: '3px',
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
    paddingLeft: '15px',
    paddingRight: '10px',
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      cursor: 'pointer',
      borderColor: Colors.blue[100],
    },
  },
  dropdownTitle: {
    marginRight: '8px',
    fontSize: 14,
  },
  ranges: {
    width: '200px',
    padding: '8px 0 8px 16px',
  },
  rangeItem: {
    padding: '8px 0',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  calendarContainer: {
    display: 'flex',
    marginLeft: '4px',
    marginBottom: '8px',
    paddingTop: '16px',
    '& > *': {
      marginRight: '8px',
    },
  },
  buttonsContainer: {
    margin: '8px',
    marginLeft: '16px',
    '& > *': {
      marginRight: '4px',
    },
  },
  rangeItemActive: {
    color: '#019F7F',
  },
}))

export const DateRangePicker: React.FC<Props> = ({
  disabledRanges = [],
  range,
  rangeChange,
  minDate,
  isUtc = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [selectedRange, setSelectedRange] = useState<TDatePickerRange>(range)
  const [savedRange, setSavedRange] = useState<TDatePickerRange>(range)
  const { classes, cx } = useStyles()

  const openDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = () => {
    setAnchorEl(null)
  }

  const selectRange = (rangeId: TDatePickerRangeId) => {
    if (!isCustomRange(rangeId)) {
      const newRange = {
        rangeId: rangeId,
        range: generateRange(rangeId, isUtc),
      }

      setSelectedRange(newRange)
      emitChange(newRange)
      handleDropdownClose()

      return
    }

    setSelectedRange({
      rangeId,
      range: rangeId === 'CUSTOM_RANGE' ? selectedRange.range : generateRange(rangeId, isUtc),
    })
  }

  const emitChange = (newRange: TDatePickerRange) => {
    setSavedRange(newRange)
    rangeChange(newRange)
  }

  const onCalendarChange = (range: DateRange) => {
    setSelectedRange({
      rangeId: selectedRange.rangeId,
      range,
    })
  }

  const onApply = () => {
    emitChange(selectedRange)
    handleDropdownClose()
  }

  const onCancel = () => {
    setSelectedRange(savedRange)
    handleDropdownClose()
  }

  const rangesOptions: any[] = useMemo<any>(() => {
    const filteredRanges = omit(rangeNames, disabledRanges)

    return map(filteredRanges, (name, id) => ({ name, id }))
  }, [disabledRanges])

  const dropdownTitle = useMemo(() => formatRangeDropdownTitle(savedRange), [savedRange])

  const open = Boolean(anchorEl)
  const id = open ? 'dp-popover' : undefined

  return (
    <div>
      <div
        className={classes.dropdownAnchor}
        data-qa="date-range-picker"
        aria-describedby={id}
        onClick={openDropdown as any}
      >
        <span className={classes.dropdownTitle}>{dropdownTitle}</span>
        <IconButton size="small">
          <ChevronDown />
        </IconButton>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleDropdownClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <div className={classes.container}>
          {isCustomRange(selectedRange.rangeId) && (
            <div>
              <div className={classes.calendarContainer}>
                {selectedRange.rangeId === 'CUSTOM_RANGE' && (
                  <>
                    <DPCalendar
                      range={selectedRange.range}
                      mode="rangeStart"
                      rangeChange={onCalendarChange}
                      minDate={minDate}
                    />
                    <DPCalendar
                      range={selectedRange.range}
                      mode="rangeEnd"
                      rangeChange={onCalendarChange}
                      minDate={minDate}
                    />
                  </>
                )}
                {selectedRange.rangeId === 'MONTH' && (
                  <DPCalendar
                    range={selectedRange.range}
                    mode="month"
                    rangeChange={onCalendarChange}
                    minDate={minDate}
                  />
                )}
                {selectedRange.rangeId === 'DAY' && (
                  <DPCalendar
                    range={selectedRange.range}
                    mode="date"
                    rangeChange={onCalendarChange}
                    minDate={minDate}
                  />
                )}
              </div>
              <div className={classes.buttonsContainer}>
                <Button variant="contained" onClick={onApply}>
                  Apply
                </Button>
                <Button onClick={onCancel}>Cancel</Button>
              </div>
            </div>
          )}
          <div className={classes.ranges}>
            {rangesOptions.map(({ id, name }) => (
              <div
                className={cx({
                  [classes.rangeItem]: true,
                  [classes.rangeItemActive]: selectedRange.rangeId === id,
                })}
                onClick={() => selectRange(id)}
                key={id}
                data-qa={`dp-range-${id}`}
              >
                {name}
              </div>
            ))}
          </div>
        </div>
      </Popover>
    </div>
  )
}

export default DateRangePicker
