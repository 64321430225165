import { useCallback, useContext, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import QueryNavigationContext from '../QueryNavigationContext'

/**
 * Helper for navigating based on query parameters. That keeps the state even on refresh, so all filters will be kept.
 *
 * @example
 * ```javascript
 * const {
 *  paramFoo = 'value',
 *  paramBar = 'value',
 *  paramFooBar = 'value',
 *  updateQuery,
 *  navigate
 * } = useQueryNavigation({ defaultValues })
 *
 * // Navigates to: ?paramFoo=value2&paramBar=value2&paramFooBar=value
 * const updateAndRetainQueryParams = () => updateQuery({
 *  paramFoo: 'value2',
 *  paramBar: 'value2'
 * })
 *
 * // Navigates to: ?paramFooBar=value2
 * const updateQueryParams = () => navigate({
 *  paramFooBar: 'value2'
 * })
 * ```
 *
 * The defaultValues option means that any params that are updated that match the
 * given default values will be removed from the URL.
 */
export default function useQueryNavigation(defaultValues = {}, options = {}) {
  const { debounceDelay = 600, syncToUrl = true } = options
  const [defaults, setDefaults] = useState(defaultValues)
  const queryNavigation = useContext(QueryNavigationContext)

  const setDefaultValues = (obj) => {
    setDefaults(Object.assign(defaults, obj))
  }

  /**
   * Update query params with retaining other params
   */
  const updateQuery = useCallback((updatedQueryParams = {}, options = {}) => {
    Object.keys(updatedQueryParams).forEach((key) => {
      if (isEqual(updatedQueryParams[key], defaults[key])) {
        updatedQueryParams[key] = undefined
      }
    })
    if (queryNavigation && syncToUrl) {
      queryNavigation.navigate(updatedQueryParams, { override: false, ...options })
    }
  }, [])

  const resetQuery = useCallback(() => {
    updateQuery(defaults, options)
  }, [defaults])

  /**
   * Debounced version of updateQuery
   */
  const debouncedUpdateQuery = useMemo(() => debounce((v) => updateQuery(v), debounceDelay), [])

  return {
    ...defaults,
    ...queryNavigation,
    updateQuery,
    setDefaultValues,
    resetQuery,
    debouncedUpdateQuery,
  }
}
