import React from 'react'
import { Box } from '@mui/material'
import { Button, Link, Typography } from 'ui'
import LoginLayout from './auth/LoginLayout'
import { useLogoutMutation } from './auth/authQueries'
import { useCurrentUser } from './hooks/useCurrentUser'
import { useUpdateUser } from './user/userQueries'

export default function TosPage() {
  const { currentUser } = useCurrentUser()
  const [updateUser] = useUpdateUser()
  const [logout] = useLogoutMutation()

  const handleAccept = () => {
    updateUser({ id: currentUser.id, tosAccepted: true })
  }

  return (
    <LoginLayout
      maxWidth={400}
      main={
        <>
          <Typography>
            {'To continue using Edgio, you must accept the '}
            <Link href="https://edg.io/legal/terms-of-service" newTab>
              Terms of Service
            </Link>
            {' and '}
            <Link href="https://edg.io/legal/privacy-policy" newTab>
              Privacy Policy
            </Link>
            .
          </Typography>

          <Box display="grid" gridTemplateColumns="1fr 0.5rem 1fr" marginTop={2}>
            <Button fullWidth variant="contained" onClick={handleAccept} large>
              Accept
            </Button>
            <div />
            <Button forceOutlined fullWidth onClick={logout} large>
              Decline
            </Button>
          </Box>
        </>
      }
    />
  )
}
