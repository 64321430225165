import { useEffect, useMemo, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { customColors } from 'ui'
import moment from 'moment'
import { DateRange } from 'moment-range'
import { TCalendarFrame } from './Calendar'
import { getFrameEnd } from './calendarUtils'

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    height: '40px',
    justifyContent: 'space-between',
  },
  arrowBtn: {
    width: '40px',
    textAlign: 'center',
  },
  title: {
    fontWeight: 'bold',
    width: '100%',
    textAlign: 'center',
  },
  selectable: {
    cursor: 'pointer',
    '&:hover': {
      color: customColors.EdgioBlue,
    },
  },
}))

type Props = {
  frame: TCalendarFrame
  availableRange: DateRange
  frameChange: any
}

export const CalendarHeader: React.FC<Props> = ({ frame, availableRange, frameChange }) => {
  const { classes, cx } = useStyles()
  const [title, setTitle] = useState<string>()

  const generateTitle = (frame: TCalendarFrame) => {
    switch (frame.granularity) {
      case 'date':
        return frame.date.format('MMMM YYYY')
      case 'month':
        return frame.date.format('YYYY')
      case 'year':
        return 'Year'
      default:
        return ''
    }
  }

  const prevBtnEnabled = useMemo(() => availableRange.start < frame.date, [availableRange, frame])
  const nextBtnEnabled = useMemo(
    () => availableRange.end > getFrameEnd(frame),
    [availableRange, frame],
  )

  const onZoomOut = () => {
    if (frame.granularity === 'date') {
      frameChange({
        granularity: 'month',
        date: moment(frame.date).startOf('year'),
      })
    } else if (frame.granularity === 'month') {
      frameChange({
        granularity: 'year',
        date: moment().startOf('year').subtract(7, 'years'),
      })
    }
  }

  const onPrevClick = () => {
    if (!prevBtnEnabled) {
      return
    }

    let newDate

    switch (frame.granularity) {
      case 'date':
        newDate = moment(frame.date).subtract(1, 'month')
        break
      case 'month':
        newDate = moment(frame.date).subtract(1, 'year')
        break
      default:
        return
    }

    frameChange({
      granularity: frame.granularity,
      date: newDate,
    })
  }

  const onNextClick = () => {
    if (!nextBtnEnabled) {
      return
    }

    let newDate

    switch (frame.granularity) {
      case 'date':
        newDate = moment(frame.date).add(1, 'month')
        break
      case 'month':
        newDate = moment(frame.date).add(1, 'year')
        break
      default:
        return
    }

    frameChange({
      granularity: frame.granularity,
      date: newDate,
    })
  }

  useEffect(() => {
    setTitle(generateTitle(frame))
  }, [frame])

  return (
    <div className={classes.container}>
      <div className={cx(classes.arrowBtn)}>
        {prevBtnEnabled && (
          <KeyboardArrowLeftIcon onClick={onPrevClick} className={classes.selectable} />
        )}
      </div>
      <div
        onClick={onZoomOut}
        className={cx(classes.title, { [classes.selectable]: frame.granularity !== 'year' })}
      >
        {title}
      </div>
      <div className={cx(classes.arrowBtn)}>
        {nextBtnEnabled && (
          <KeyboardArrowRightIcon onClick={onNextClick} className={classes.selectable} />
        )}
      </div>
    </div>
  )
}

export default CalendarHeader
