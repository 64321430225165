import moment from 'moment'
import { DateRange } from 'moment-range'

export class DatePickerException {
  message: string

  constructor(message: string = '') {
    this.message = message
  }
}

export type TDatePickerRangeId =
  | 'TODAY'
  | 'LAST_24_HOURS'
  | 'LAST_7_DAYS'
  | 'THIS_MONTH'
  | 'LAST_MONTH'
  | 'LAST_30_DAYS'
  | 'LAST_90_DAYS'
  | 'DAY'
  | 'MONTH'
  | 'CUSTOM_RANGE'

export type TDatePickerRange = {
  rangeId: TDatePickerRangeId
  range: DateRange
}

export const availableRanges: TDatePickerRangeId[] = [
  'TODAY',
  'LAST_24_HOURS',
  'LAST_7_DAYS',
  'THIS_MONTH',
  'LAST_MONTH',
  'LAST_30_DAYS',
  'LAST_90_DAYS',
  'DAY',
  'MONTH',
  'CUSTOM_RANGE',
]

export const rangeNames: { [key in TDatePickerRangeId]: string } = {
  TODAY: 'Today',
  LAST_24_HOURS: 'Last 24 Hours',
  LAST_7_DAYS: 'Last 7 Days',
  THIS_MONTH: 'This Month',
  LAST_MONTH: 'Last Month',
  LAST_30_DAYS: 'Last 30 Days',
  LAST_90_DAYS: 'Last 90 Days',
  DAY: 'Daily',
  MONTH: 'Monthly',
  CUSTOM_RANGE: 'Custom Date Range',
}

/**
 * Generates the momentjs range by rangeId
 * Does not generate range for 'CUSTOM_RANGE'
 */
export function generateRange(rangeId: TDatePickerRangeId, isUtc: boolean = false): DateRange {
  if (!isValidRangeId(rangeId)) {
    throw new Error(`Invalid rangeId: "${rangeId}".`)
  }

  switch (rangeId) {
    case 'TODAY':
    case 'DAY':
      return new DateRange(getMoment(isUtc).startOf('day'), getMoment(isUtc))
    case 'LAST_24_HOURS':
      return new DateRange(getMoment(isUtc).subtract(24, 'hours'), getMoment(isUtc))
    case 'LAST_7_DAYS':
      return new DateRange(getMoment(isUtc).subtract(6, 'days').startOf('day'), getMoment(isUtc))
    case 'THIS_MONTH':
    case 'MONTH':
      return new DateRange(getMoment(isUtc).startOf('month'), getMoment(isUtc))
    case 'LAST_MONTH':
      return new DateRange(
        getMoment(isUtc).subtract(1, 'month').startOf('month'),
        getMoment(isUtc).subtract(1, 'month').endOf('month'),
      )
    case 'LAST_30_DAYS':
      return new DateRange(getMoment(isUtc).subtract(29, 'days').startOf('day'), getMoment(isUtc))
    case 'LAST_90_DAYS':
      return new DateRange(getMoment(isUtc).subtract(89, 'days').startOf('day'), getMoment(isUtc))
    case 'CUSTOM_RANGE':
      throw new Error('Custom range is not supported')
    default:
      throw new Error(`Could not generate range for rangeId ${rangeId}`)
  }
}

/**
 * Local helper function returns either a local or UTC moment based on isUtc param
 */
function getMoment(isUtc: boolean) {
  return isUtc ? moment.utc() : moment()
}

/**
 * Checks if given date is today
 */
export function isToday(date: moment.Moment): boolean {
  return date.isSame(moment(), 'date')
}

/**
 * Checks if the given range is custom (used with calendars)
 */
export function isCustomRange(rangeId: TDatePickerRangeId): boolean {
  return ['MONTH', 'DAY', 'CUSTOM_RANGE'].includes(rangeId)
}

/**
 * Formats the title for dateRangePicker dropdown based on current range
 */
export function formatRangeDropdownTitle(dpRange: TDatePickerRange): string {
  const { rangeId, range } = dpRange

  if (rangeId === 'DAY') {
    return `${range.start.format('DD MMM YYYY')}`
  }

  if (rangeId === 'CUSTOM_RANGE') {
    return `${range.start.format('DD MMM YYYY')} - ${range.end.format('DD MMM YYYY')}`
  }

  if (rangeId === 'MONTH') {
    return `${range.start.format('MMM YYYY')}`
  }

  return rangeNames[rangeId] || ''
}

/**
 * Validates the range
 * Valid range should have valid momentjs start & date properties
 */
export function isValidRange(range: DateRange): boolean {
  if (!range || !range.start || !range.end) {
    return false
  }

  if (!moment(range.start).isValid() || !moment(range.end).isValid()) {
    return false
  }

  return true
}

/**
 * Validates the rangeId
 */
export function isValidRangeId(rangeId: TDatePickerRangeId): boolean {
  return availableRanges.includes(rangeId)
}
