import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { useRouter } from 'next/router'
import { Layers } from 'icons'
import pick from 'lodash/pick'
import { Link } from '../../../Link'
import { NavListItem } from '../../molecules'

const useStyles = makeStyles()((theme) => ({
  navListItem: {
    height: 36,
    padding: theme.spacing(0.5, 2),
    '& .MuiTypography-root': {
      fontSize: theme.typography.body2.fontSize,
      lineHeight: theme.typography.body2.lineHeight,
    },
  },
  link: {
    '&:hover': {
      textDecoration: 'none !important',
    },
  },
}))

export const NavLink = ({
  href,
  label,
  Icon,
  persistedParams = undefined,
  newTab = false,
  compact = false,
}) => {
  const { asPath, query } = useRouter()
  const { classes } = useStyles()

  const item = compact ? (
    <NavListItem
      button
      selected={asPath === href}
      icon={<Icon height={18} width={18} />}
      className={classes.navListItem}
      classes={{
        root: classes.navListItem,
      }}
    />
  ) : (
    <NavListItem
      button
      selected={asPath === href}
      icon={<Icon height={18} width={18} />}
      className={classes.navListItem}
      classes={{
        root: classes.navListItem,
      }}
    >
      {label}
    </NavListItem>
  )

  if (href) {
    return (
      <Link
        className={classes.link}
        underline="none"
        href={
          href?.pathname || href.match(/^https?:/)
            ? href
            : { pathname: href, query: pick(query, persistedParams) }
        }
        newTab={newTab}
      >
        {item}
      </Link>
    )
  } else {
    return item
  }
}

NavLink.defaultProps = {
  Icon: Layers,
}
