import React from 'react'
import { Link } from 'ui'
import { Button } from 'ui'
import GoogleIcon from './GoogleIcon'

export default function GoogleButton({ action, label = 'Google', redirectTo }) {
  return (
    <Link
      style={{ textDecoration: 'none' }}
      href={`${process.env.PROTOCOL}://${
        process.env.API_URL
      }/users/auth/google_oauth2?action=${action}&redirectTo=${encodeURIComponent(
        `${window.location.origin}${redirectTo}`,
      )}`}
      passHref
    >
      <Button fullWidth large startIcon={<GoogleIcon />} forceOutlined>
        {label}
      </Button>
    </Link>
  )
}
