import moment, { Moment } from 'moment'
import { DateRange } from 'moment-range'
import { TCalendarMode } from './Calendar'
import { getActiveRangeEdge } from './calendarUtils'

export type TCalendarCell = {
  moment: Moment
  active: boolean
  inRange: boolean
  rangeStart: boolean
  rangeEnd: boolean
  title: string
  faded?: boolean
  selectable: boolean
}

/**
 * Generates a table of cells(dates) for date view in datepicker
 */
export function generateDateGrid(
  month: Moment,
  range: DateRange,
  availableRange: DateRange,
  mode: TCalendarMode,
): TCalendarCell[][] {
  const monthRange = new DateRange(
    moment(month).startOf('month').startOf('week'),
    moment(month).endOf('month').endOf('week'),
  )
  const cells: TCalendarCell[] = []

  for (const date of Array.from(monthRange.by('days'))) {
    cells.push(newDateCell(moment(date), month, range, availableRange, mode))
  }

  return toTable(cells, 7)
}

/**
 * Creates new cell object for date
 */
function newDateCell(
  date: Moment,
  month: Moment,
  range: DateRange,
  availableRange: DateRange,
  mode: TCalendarMode,
): TCalendarCell {
  return {
    moment: date,
    active: range && date.isSame(getActiveRangeEdge(range, mode), 'day'),
    inRange: range && range.contains(date),
    rangeStart: range && date.isSame(range.start, 'day'),
    rangeEnd: range && date.isSame(range.end, 'day'),
    title: date.format('D'),
    faded: !date.isSame(month, 'month'),
    selectable:
      mode === 'time'
        ? availableRange.overlaps(new DateRange(date, moment(date).endOf('day')))
        : availableRange.contains(date),
  }
}

/**
 * Generates a table of cells(dates) for month view in datepicker
 */
export function generateMonthGrid(
  year: Moment,
  range: DateRange,
  availableRange: DateRange,
  mode: TCalendarMode,
): TCalendarCell[][] {
  const cells: TCalendarCell[] = []
  const yearRange = new DateRange(moment(year).startOf('year'), moment(year).endOf('year'))

  for (const month of Array.from(yearRange.by('month'))) {
    cells.push(newMonthCell(month, range, availableRange, mode))
  }

  return toTable(cells, 4)
}

/**
 * Creates new cell object for month
 */
function newMonthCell(
  month: Moment,
  range: DateRange,
  availableRange: DateRange,
  mode: TCalendarMode,
): TCalendarCell {
  const cell = {
    moment: month,
    active: range && month.isSame(getActiveRangeEdge(range, mode), 'month'),
    inRange: range && (range.contains(month) || range.contains(moment(month).endOf('month'))),
    rangeStart: range && month.isSame(range.start, 'month'),
    rangeEnd: range && month.isSame(range.end, 'month'),
    selectable: false,
    title: month.format('MMM'),
  }

  if (mode === 'time') {
    cell.selectable = !!availableRange.intersect(new DateRange(month, moment(month).endOf('month')))
  } else if (mode === 'month') {
    // whole month should be selectable if it's start is in availableRange
    cell.selectable = availableRange.contains(month)
  } else {
    // in other modes it should contain at least one selectable date
    cell.selectable =
      availableRange.contains(month) ||
      availableRange.contains(moment.min(moment(), moment(month).endOf('month').subtract(1, 'day')))
  }

  return cell
}

/**
 * Generates a table of cells(dates) for year view in datepicker
 */
export function generateYearGrid(
  range: DateRange,
  availableRange: DateRange,
  mode: TCalendarMode,
): TCalendarCell[][] {
  const yearsRange = new DateRange(
    moment(availableRange.start).startOf('year'),
    moment(availableRange.end).endOf('year'),
  )
  const cells: TCalendarCell[] = []

  for (const year of Array.from(yearsRange.by('years'))) {
    cells.push(newYearCell(year, range, availableRange, mode))
  }

  return toTable(cells, 4)
}

/**
 * Creates new cell object for year
 */
function newYearCell(
  year: Moment,
  range: DateRange,
  availableRange: DateRange,
  mode: TCalendarMode,
): TCalendarCell {
  const now = moment()

  const cell: TCalendarCell = {
    moment: year,
    active: range && year.isSame(getActiveRangeEdge(range, mode), 'year'),
    // is in range if year overlaps with range
    inRange: range && new DateRange(moment(year), moment(year).endOf('year')).overlaps(range),
    rangeStart: range && year.isSame(range.start, 'year'),
    rangeEnd: range && year.isSame(range.end, 'year'),
    selectable: false,
    title: year.format('YYYY'),
  }

  if (mode === 'month') {
    // year should be selectable if at least one month overlaps with availableRange
    const yearRange = new DateRange(
      moment(year).add(1, 'month'),
      moment.min(moment(now).startOf('month'), moment(year).add(11, 'months').add(1, 'day')),
    )
    cell.selectable = yearRange.overlaps(availableRange)
  } else if (mode === 'time') {
    const yearRange = new DateRange(moment(year), moment(year).endOf('year'))
    cell.selectable = yearRange.overlaps(availableRange)
  } else {
    // year should be selectable if at least first or last day is selectable
    const yearRange = new DateRange(
      moment(year),
      moment.min(moment(now), moment(year).endOf('year')),
    )
    cell.selectable = yearRange.overlaps(availableRange)
  }

  return cell
}

/**
 * Generate table-like array of arrays of given width
 * Used to generate grid of cells
 */
function toTable(cells: TCalendarCell[], rowLength: number = 1): TCalendarCell[][] {
  const result: TCalendarCell[][] = []

  for (let i = 0; i < cells.length; i += rowLength) {
    result.push(cells.slice(i, i + rowLength))
  }

  return result
}
