import { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import moment, { Moment } from 'moment'
import { DateRange } from 'moment-range'
import CalendarGrid from './CalendarGrid'
import CalendarHeader from './CalendarHeader'
import { buildFrame, getAvailableRange } from './calendarUtils'

export type TCalendarMode = 'date' | 'month' | 'rangeStart' | 'rangeEnd' | 'time'

export type TCalendarFrame = {
  granularity: string
  date: Moment
}

const useStyles = makeStyles()(() => ({
  container: {
    width: '288px',
    padding: '4px',
  },
}))

type Props = {
  range: DateRange
  mode: TCalendarMode
  minDate?: Moment
  maxDate?: Moment
  rangeChange: any
}

const defaultMinDate = moment('2021-01-01')
const defaultMaxDate = moment()

export const DPCalendar: React.FC<Props> = ({
  range,
  mode,
  minDate = defaultMinDate,
  maxDate = defaultMaxDate,
  rangeChange,
}) => {
  const { classes } = useStyles()

  const [frame, setFrame] = useState<TCalendarFrame>(buildFrame(range, mode))
  const [availableRange, setAvailableRange] = useState<DateRange>(
    getAvailableRange(range, mode, minDate, maxDate),
  )

  const onFrameChange = (frame: TCalendarFrame) => {
    setFrame(frame)
  }

  const onRangeChange = (range: DateRange) => {
    rangeChange(range)
  }

  useEffect(() => {
    setFrame(buildFrame(range, mode))
    setAvailableRange(getAvailableRange(range, mode, minDate, maxDate))
  }, [mode, range])

  return (
    <div className={classes.container}>
      <CalendarHeader frame={frame} availableRange={availableRange} frameChange={onFrameChange} />
      <CalendarGrid
        frame={frame}
        availableRange={availableRange}
        mode={mode}
        range={range}
        frameChange={onFrameChange}
        rangeChange={onRangeChange}
      />
    </div>
  )
}

export default DPCalendar
