import { makeStyles } from 'tss-react/mui'
import CloseIcon from '@mui/icons-material/Close'
import { Theme } from '@mui/material'
import Toolbar from '@mui/material/Toolbar'
import { Typography } from '../../../../Typography'
import { IconButton } from '../../../atoms'
import { TDrawerHeader } from '../types'

const useStyles = makeStyles()((theme: Theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 2.5),
    // if necessary for content to be below app bar - uncomment below line
    // ...theme.mixins.toolbar,
    minHeight: 'auto',
    borderBottom: `1px solid ${theme.palette.divider}`,
    background: theme.palette.background.default,
  },
}))

export const DrawerHeader = ({ title, onClose }: TDrawerHeader) => {
  const { classes } = useStyles()

  return (
    <Toolbar className={classes.toolbar}>
      <Typography color="textPrimary" variant="body1" fontWeight={600}>
        {title}
      </Typography>
      <IconButton size="small" onClick={onClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Toolbar>
  )
}
