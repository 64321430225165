import { ReactElement, useCallback } from 'react'
import { makeStyles } from 'tss-react/mui'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, AccordionSummary as MuiAccordionSummary } from '@mui/material'
import { Theme } from '@mui/material'
import { grey } from '@mui/material/colors'
import { DragHorizontal } from '../../../icons'
import { IconButton } from '../../atoms'
import DeleteButton from '../../atoms/Button/components/DeleteButton'
import { TAccordionSummary } from './types'

const ACCORDION_SUMMARY_MIN_HEIGHT = 36

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    fontWeight: 500,
    boxSizing: 'border-box',
    borderBottom: 'none',
    marginBottom: 0,
    minHeight: ACCORDION_SUMMARY_MIN_HEIGHT,
    padding: theme.spacing(0.75, 1),
    transition: 'background-color 0.3s',

    '&.Mui-expanded': {
      minHeight: ACCORDION_SUMMARY_MIN_HEIGHT,
      borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    },
  },
  expanded: {
    margin: 0,
  },
  expandIcon: {
    padding: 0,
    margin: theme.spacing(-0.25, 0),
  },
  accordionSummary: {
    display: 'flex',
    flex: 1,
    maxWidth: 'calc(100% - 25px)',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 0,
    marginLeft: theme.spacing(0.5),
    gap: theme.spacing(1),

    '&.Mui-expanded': {
      margin: 0,
      marginLeft: theme.spacing(0.5),
    },
  },
  accordionSummaryInfo: {
    display: 'inline-flex',
  },
  icon: {
    color: `${theme.palette.mode === 'dark' ? grey[500] : '#687077'}`,
    margin: 0,
  },
  dragHandle: {
    cursor: 'row-resize',
  },
}))

export const AccordionSummary = ({
  classes,
  title,
  children,
  draggableProvided,
  onDelete,
}: TAccordionSummary): ReactElement<TAccordionSummary> => {
  const {
    classes: { root, accordionSummary, expanded, icon, dragHandle },
  } = useStyles(undefined, {
    props: {
      classes: classes,
    },
  })

  const handleDelete = useCallback(
    (e) => {
      e.stopPropagation()
      onDelete && onDelete()
    },
    [onDelete],
  )

  return (
    <MuiAccordionSummary
      expandIcon={
        <IconButton size="small" data-qa="expand-button">
          <ExpandMoreIcon fontSize="small" className={icon} />
        </IconButton>
      }
      classes={{
        root: root,
        content: accordionSummary,
        expanded: expanded,
      }}
      {...(draggableProvided && draggableProvided.dragHandleProps)}
    >
      {draggableProvided && (
        <IconButton size="small" className={dragHandle} {...draggableProvided.dragHandleProps}>
          <DragHorizontal className={icon} />
        </IconButton>
      )}
      {title || children}
      {onDelete ? (
        <Box display="inline-flex">
          <DeleteButton onClick={handleDelete} data-qa="delete-button" />
        </Box>
      ) : (
        <div /> // spacer to ensure anything right aligned isn't too close to the expand icon
      )}
    </MuiAccordionSummary>
  )
}
