import { makeStyles } from 'tss-react/mui'
import { Theme } from '@mui/material'

const useStyles = makeStyles<{ isCollapsed: boolean }>()((theme: Theme, { isCollapsed }) => ({
  sidebarFooter: {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: isCollapsed ? '8px' : '20px',
    transition: 'padding 0.3s',
    cursor: 'pointer',
  },
}))

export const SidebarFooter = ({ children, isCollapsed }: any) => {
  const { classes } = useStyles({ isCollapsed })
  return <div className={classes.sidebarFooter}>{children}</div>
}
