import { MouseEvent } from 'react'
import { makeStyles } from 'tss-react/mui'
import ClearIcon from '@mui/icons-material/Clear'
import { Theme } from '@mui/material'
import IconButton from '@mui/material/IconButton'

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    color: theme.palette.text.secondary,
  },
}))

interface Props {
  onClick: (event: MouseEvent) => void
  size?: 'medium' | 'small'
}

export function ClearButton({ onClick, size = 'medium' }: Props) {
  const { classes } = useStyles()

  return (
    <IconButton aria-label="clear" classes={classes} onClick={onClick} size={size}>
      <ClearIcon fontSize="inherit" />
    </IconButton>
  )
}
