import { makeStyles } from 'tss-react/mui'
import { FileUpload as FileIcon } from '@mui/icons-material'
import { Theme } from '@mui/material'
import { Typography } from '../../../../Typography'

const useStyles = makeStyles()((theme: Theme) => ({
  draggableOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: theme.palette.background.default,
    zIndex: 100,
    pointerEvents: 'none',
    border: `1px dashed ${theme.palette.grey[700]}`,
    borderRadius: theme.shape.borderRadius,
  },
  draggableOverlayContent: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
    position: 'relative',
    opacity: 0.6,
    gap: theme.spacing(1),
  },
}))

type Props = {
  label?: string
}

export const DropzoneOverlay = ({ label = 'Drop File(s)' }: Props) => {
  const { classes } = useStyles()

  return (
    <div className={classes.draggableOverlay}>
      <div className={classes.draggableOverlayContent}>
        <FileIcon />
        <Typography>{label}</Typography>
      </div>
    </div>
  )
}
