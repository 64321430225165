import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNotifications } from '../../../util'
import restFetch, { restDownload } from '../../../util/rest'

export default function fetchAsmData(uri: string, record?: any, options: RequestInit = {}) {
  return restFetch(`/api/asm/v0.1${uri}`, record, options)
}

export function downloadAsmFile(url: string) {
  restDownload(`/api/asm/v0.1${url}`)
}

type DataUpdater<T> = (data: T) => T

export type FetchState<T> = {
  loading: boolean
  data: T
  error: boolean
  errorMessage?: string
}

export interface UseAsmData<T> extends FetchState<T> {
  setData: (data: T | DataUpdater<T>) => void
  reload: () => void
}

export function useAsmData<T>(
  uri: string,
  params: any,
  notify = true,
  refreshKey?: any,
): UseAsmData<T> {
  const setData = useCallback(async (data: T | DataUpdater<T>) => {
    setState((state) => {
      if (typeof data === 'function') {
        data = (data as DataUpdater<T>)(state.data)
      }

      return { ...state, data }
    })
  }, [])

  const [state, setState] = useState<FetchState<T>>({
    loading: true,
    data: null,
    error: false,
    errorMessage: null,
  })

  const notifications = useNotifications({})

  const reload = useCallback(async () => {
    try {
      setState((state) => ({ ...state, loading: true }))
      const url = new URL(uri, window.location.origin)

      Object.entries(params).forEach(([key, value]) => {
        if (value) {
          url.searchParams.append(key, value.toString())
        }
      })

      const res = await fetchAsmData(url.pathname + url.search)

      if (res.ok) {
        const data = await res.json()

        setState((state) => ({
          ...state,
          loading: false,
          data,
          error: false,
          errorMessage: null,
        }))
      } else {
        let errorMessage = 'An error occurred while fetching data. Please try again later.'

        try {
          errorMessage = (await res.json()).error
        } catch (e) {
          // Ignore any error parsing json here
        }

        setState((state) => ({
          ...state,
          loading: false,
          data: null,
          error: true,
          errorMessage,
        }))

        // We'll notify the user about the error by default
        if (notify) notifications.error(errorMessage)
      }
    } catch (e) {
      setState((state) => ({
        ...state,
        loading: false,
        data: null,
        error: true,
        errorMessage: e.message,
      }))
    }
  }, [uri, JSON.stringify(params)])

  useEffect(() => {
    reload()
  }, [reload, refreshKey])

  return useMemo(() => ({ ...state, reload, setData }), [state, reload, setData])
}
