import React, { createContext, useCallback, useState } from 'react'
import { useAppStore } from './stores/appStore/appStore'
import { useUpdateUser } from './user/userQueries'

const DarkModeContext = createContext()

function isOsDark() {
  return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
}

/**
 * Initialize dark mode by checking user setting, local storage setting, or OS setting.
 */
function getInitial(user) {
  if (user?.darkMode !== undefined) return user.darkMode

  try {
    const local = localStorage.getItem('darkMode')
    if (local != null) return local === 'true'
  } catch (e) {
    // localstorage access can fail for various reasons, like browser settings or plugins, just ignore
  }

  return isOsDark()
}

export default DarkModeContext

export const DarkModeContextProvider = ({ currentUser, children }) => {
  const [darkMode, setDarkMode] = useState(getInitial(currentUser))
  const [updateUser] = useUpdateUser()
  const { oneUiMode } = useAppStore((s) => ({
    oneUiMode: s.oneUiMode,
  }))

  const handleDarkModeChange = useCallback(
    (darkMode) => {
      // Only update if necessary
      if (currentUser && darkMode !== currentUser.darkMode && !oneUiMode) {
        updateUser({
          id: currentUser.id,
          darkMode,
        })
      }

      try {
        localStorage.setItem('darkMode', darkMode ? 'true' : 'false')
      } catch (e) {
        // might throw due to permission issues
      }

      setDarkMode(darkMode)
    },
    [currentUser, updateUser, setDarkMode],
  )

  const value = {
    darkMode,
    setDarkMode: handleDarkModeChange,
  }

  return <DarkModeContext.Provider value={value}>{children}</DarkModeContext.Provider>
}
