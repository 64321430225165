import { useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useApolloClient } from '@apollo/client'
import { idsLoginLink } from './auth/LoginLink'
import { useCurrentUser } from './hooks/useCurrentUser'
import { useAppStore } from './stores/appStore/appStore'
import { useNotifications } from './util'

export const ApolloClientSetup = () => {
  const client = useApolloClient()
  const notifications = useNotifications()
  const { currentUser } = useCurrentUser()
  const { oneUiMode } = useAppStore((s) => ({
    oneUiMode: s.oneUiMode,
  }))
  const router = useRouter()

  const onInternalError = useCallback((message) => {
    notifications.error(message, {
      preventDuplicate: true,
    })
  }, [])

  const onInternalWarning = useCallback((message, opts = { preventDuplicate: true }) => {
    notifications.warning(message, { ...opts })
  }, [])

  const onUnauthorized = useCallback(
    async (message) => {
      if (!!oneUiMode) {
        const idsLoginRedirect = idsLoginLink({ action: 'mcc-login', redirectTo: router.asPath })
        notifications.warning(message, {
          preventDuplicate: true,
        })
        window.location.replace(idsLoginRedirect)
      }
      // We only do the logout procedure if currentUser exists otherwise we may end up doing double redirect
      // Double redirect will redirect user back to login page after logging in
      else if (currentUser) {
        window.location.reload()
        notifications.warning(message, {
          preventDuplicate: true,
        })
      }
    },
    [oneUiMode, currentUser, router.asPath],
  )
  useEffect(() => {
    // @ts-ignore
    client.onInternalError(onInternalError)
  }, [client, onInternalError])

  useEffect(() => {
    // @ts-ignore
    client.onInternalWarning(onInternalError)
  }, [client, onInternalWarning])

  useEffect(() => {
    // @ts-ignore
    client.onUnauthorized(onUnauthorized)
  }, [client, onUnauthorized])

  return null
}
