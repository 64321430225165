import { cyan } from '@mui/material/colors'
import { customColors, darkCacheStatusColors } from '../customColors'

export const darkGlobalOverrides = {
  a: {
    color: cyan['A200'],
  },
  '#root': {
    backgroundColor: customColors.EerieBlack,
    colorScheme: 'dark',
  },
  '.statusGoodText': {
    color: `${darkCacheStatusColors.green} !important`,
  },
  '.statusFairText': { color: `${darkCacheStatusColors.yellowText} !important` },
  '.statusPoorText': { color: `${darkCacheStatusColors.red} !important` },
  '*::-webkit-scrollbar': {
    backgroundColor: '#0C1117',
    width: '0.6em',
  },
  '*::-webkit-scrollbar-thumb': {
    backgroundColor: '#1A232B',
    borderRadius: '10px',
  },
}
