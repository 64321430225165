import { makeStyles } from 'tss-react/mui'
import { Theme, useMediaQuery } from '@mui/material'

const useStyles = makeStyles<{ mobile: boolean }>()((theme: Theme, { mobile }) => ({
  sidebarHeader: {
    padding: mobile ? theme.spacing(1.6, 1.6, 1.6, 1.6) : theme.spacing(1, 1, 1, 1.6),
    borderBottom: `1px solid ${theme.palette.divider}`,
    height: '64px',
    boxSizing: 'border-box',
    '&.collapsed': {
      padding: '16px 12px',
    },
  },
}))

export const SidebarHeader = ({ children, isCollapsed = false }: any) => {
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const { classes, cx } = useStyles({ mobile })
  return (
    <div className={cx(classes.sidebarHeader, { collapsed: isCollapsed && !mobile })}>
      {children}
    </div>
  )
}
