import { makeStyles } from 'tss-react/mui'
import { Info as InfoIcon } from '@mui/icons-material'
import { Warning as WarnIcon } from '@mui/icons-material'
import { Theme } from '@mui/material'
import { blue, yellow } from '@mui/material/colors'
import { alpha } from '@mui/material/styles'

type InfoProps = {
  className?: string
  children: React.ReactNode
  style?: React.CSSProperties
  icon?: boolean
  variant: 'info' | 'warn'
}

const useStyles = makeStyles<void, 'icon'>()((theme: Theme, _params, classes) => ({
  root: {
    ...(theme.typography.body2 as { fontSize: string; color: string }),
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
  },

  info: {
    border: `1px solid ${
      theme.palette.mode === 'light' ? alpha(blue[500], 0.4) : alpha(blue[200], 0.4)
    }`,
    [`& .${classes.icon}`]: {
      color: theme.palette.mode === 'light' ? blue[500] : blue[200],
    },
  },

  warn: {
    border: `1px solid ${theme.palette.mode === 'light' ? yellow[700] : alpha(yellow[500], 0.4)}`,
    borderRadius: theme.shape.borderRadius,
    [`& .${classes.icon}`]: {
      color: theme.palette.mode === 'light' ? yellow[700] : yellow[500],
    },
  },

  icon: {},
}))

export const Info = ({ className, children, icon, variant, ...rest }: InfoProps) => {
  const { classes, cx } = useStyles()

  return (
    <div className={cx(className, classes.root, classes[variant])} {...rest}>
      {icon && <Icon variant={variant} />}
      <div style={{ flex: 1 }}>{children}</div>
    </div>
  )
}

Info.defaultProps = {
  icon: true,
  variant: 'info',
}

const Icon = ({ variant }: { variant: 'info' | 'warn' }) => {
  const { classes } = useStyles()

  return variant === 'info' ? (
    <InfoIcon className={classes.icon} />
  ) : (
    <WarnIcon className={classes.icon} />
  )
}
