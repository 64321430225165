const round = (val: number) => Math.round(val * 100) / 100

/**
 * Transforms time duration (ms) into string with units (ms/s/min/hr/d).
 */
export default function formatTimeDuration(milliseconds: number) {
  const seconds = milliseconds / 1000
  if (seconds < 1) {
    return `${milliseconds}ms`
  }

  const minutes = seconds / 60
  if (minutes < 1) {
    return `${round(seconds)}s`
  }

  const hours = minutes / 60
  if (hours < 1) {
    return `${round(minutes)}min`
  }

  const days = hours / 24
  if (days < 1) {
    return `${round(hours)}hr`
  }

  return `${round(days)}d`
}
