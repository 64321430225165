import { useEffect, useState } from 'react'

/**
 * Allows for using hash-based navigation within a page, so that the browser scrolls
 * to a specific component when that component is first loaded.
 * @param {String} componentId The ID to use for the component, that is also used
 * as the hash value for navigation. e.g. /property/env?tab=overview#componentId
 * @returns {Object} An object with props to be used by the anchor component, e.g.
 *    <Card {...returnedProps}> (gives this component `id` and `ref` props)
 */
export default function useHashNavigation(componentId) {
  const [componentRef, setComponentRef] = useState()

  useEffect(() => {
    if (
      componentRef &&
      componentRef.scrollIntoView &&
      location.hash &&
      location.hash === `#${componentId}`
    ) {
      componentRef.scrollIntoView({ behavior: 'smooth' })
    }
  }, [componentRef])

  return {
    id: componentId,
    ref: setComponentRef,
  }
}
