export enum AvatarSizesEnum {
  small = 32,
  medium = 48,
  large = 100,
}

export type AvatarType = {
  size?: 'small' | 'medium' | 'large'
  imageUrl?: string
  backgroundColor?: string
  onClick?: () => void
}
