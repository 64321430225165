import { makeStyles } from 'tss-react/mui'
import { Avatar as MuiAvatar, Theme } from '@mui/material'
import { AvatarSizesEnum, AvatarType } from './types'

const useStyles = makeStyles()((theme: Theme) => ({
  avatar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  small: {
    height: AvatarSizesEnum.small,
    width: AvatarSizesEnum.small,
  },
  medium: {
    height: AvatarSizesEnum.medium,
    width: AvatarSizesEnum.medium,
  },
  large: {
    height: AvatarSizesEnum.large,
    width: AvatarSizesEnum.large,
  },
}))

export const Avatar = ({ size, imageUrl, backgroundColor, onClick }: AvatarType) => {
  const { classes, cx } = useStyles()

  return (
    <MuiAvatar
      className={cx(classes.avatar, size ? classes[size] : classes.medium)}
      src={imageUrl}
      variant="circular"
      style={{ backgroundColor: backgroundColor }}
      onClick={onClick}
    />
  )
}
