import HelpIcon from '@mui/icons-material/HelpOutlineRounded'
import { TableHead as MuiTableHead, TableSortLabel } from '@mui/material'
import { IconButton, Tooltip } from '@mui/material'
import { TableCell, TableRow } from '../../atoms'
import { ColumnOrderOptions } from './constants'
import { TTableHeadProps } from './types'

export const TableHead = ({ columns, order, orderBy, onRequestSort }: TTableHeadProps) => (
  <MuiTableHead>
    <TableRow>
      {columns?.map(({ id, label, description, tooltip, width, sort = true, className }) => {
        let labelEl =
          onRequestSort && sort ? (
            <TableSortLabel
              active={orderBy === id}
              direction={
                orderBy === id
                  ? order
                  : sort == ColumnOrderOptions.desc
                  ? ColumnOrderOptions.desc
                  : ColumnOrderOptions.asc
              }
              onClick={(event) => onRequestSort(event, id)}
            >
              {label}
            </TableSortLabel>
          ) : (
            <>{label}</>
          )

        if (tooltip) {
          labelEl = (
            <Tooltip title={tooltip}>
              <span>{labelEl}</span>
            </Tooltip>
          )
        }

        return (
          <TableCell
            className={className}
            width={width}
            key={id}
            sortDirection={orderBy === id ? order : false}
          >
            {labelEl}
            {description && (
              <Tooltip title={description}>
                <IconButton color="inherit" size="large">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            )}
          </TableCell>
        )
      })}
    </TableRow>
  </MuiTableHead>
)
