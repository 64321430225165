import { useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'

type Props = {
  className?: string
  truncate?: boolean
  children?: React.ReactNode
}

const useStyles = makeStyles()(() => ({
  truncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '& *': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}))

const Ellipsis = ({ className, truncate, ...props }: Props) => {
  const { classes, cx } = useStyles()

  const classNames = useMemo(() => {
    const classNames = []

    if (className) {
      classNames.push(className)
    }

    if (truncate) {
      classNames.push(classes.truncate)
    }

    return classNames
  }, [className, truncate, classes])

  return <span className={cx(classNames)} {...props} />
}

Ellipsis.defaultProps = {
  truncate: true,
}

export default Ellipsis
