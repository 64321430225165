import { ThemeOptions } from '@mui/material'
import baseComponentsOverrides from './componentOverrides'
import basePalette from './palette'
import baseTypography from './typography'

// theme module augmentations are defined in apps/console/src/theme.d.ts

const baseTheme: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1600,
    },
  },
  palette: basePalette,
  typography: baseTypography,
  shape: {
    borderRadius: 3,
  },
  components: baseComponentsOverrides,
}

export default baseTheme
