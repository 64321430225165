const round = (val) => Math.round(val * 100) / 100

export default function formatBytes(bytes) {
  const kb = bytes / 1024
  if (kb < 1) {
    return `${bytes} bytes`
  }
  const mb = kb / 1024
  if (mb < 1) {
    return `${round(kb)} kB`
  }
  const gb = mb / 1024
  if (gb < 1) {
    return `${round(mb)} MB`
  }
  const tb = gb / 1024
  if (tb < 1) {
    return `${round(gb)} GB`
  }
  return `${round(tb)} TB`
}

export function formatBytesToGygabytes(bytes) {
  return `${(bytes / 1024 / 1024 / 1024).toFixed(2)} GB`
}
