import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Theme, useTheme } from '@mui/material'
import { InputLabel, NumberField, Select, Typography } from 'ui'
import { parseTime, timeLabels } from './DurationLabel'
import pluralize from './pluralize'

type Props = {
  label?: string
  name?: string
  value: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  error?: boolean
  helperText?: string
}

const DurationField = ({ value, name, label, onChange, helperText, error }: Props) => {
  const [{ duration, unit }, setState] = useState({ duration: 1, unit: 'h' })
  const theme = useTheme<Theme>()

  useEffect(() => {
    if (value) {
      try {
        setState(parseTime(value))
      } catch (e) {
        console.warn(`Could not parse time value: ${value}`)
      }
    }
  }, [value])

  const handleDurationChange = useCallback(
    (e) => {
      setState((prevState) => {
        const newState = { ...prevState, duration: e.target.value }

        if (onChange) {
          onChange({ target: { value: `${newState.duration}${newState.unit}`, name } } as any)
        }

        return newState
      })
    },
    [name],
  )

  const handleUnitChange = useCallback(
    (option) => {
      setState((prevState) => {
        const newState = { ...prevState, unit: option.value }

        if (onChange) {
          onChange({ target: { value: `${newState.duration}${newState.unit}`, name } } as any)
        }

        return newState
      })
    },
    [name],
  )

  const unitOptions = useMemo(
    () =>
      Object.entries(timeLabels).map(([key, value]) => ({
        name: pluralize(value, duration),
        value: key,
      })),
    [timeLabels, duration],
  )

  return (
    <Box>
      {label && <InputLabel>{label}</InputLabel>}
      <Box display="flex" alignItems="center" style={{ gap: theme.spacing(0.5) }}>
        <NumberField
          style={{ width: 80 }}
          value={duration}
          onChange={handleDurationChange}
          name={name}
        />
        <Select
          style={{ width: 110 }}
          value={unit}
          onChange={handleUnitChange}
          name={name}
          options={unitOptions}
        />
      </Box>
      {helperText && (
        <Typography color={error ? 'error' : 'textPrimary'} variant="caption">
          {helperText}
        </Typography>
      )}
    </Box>
  )
}

const memoized = memo(DurationField)

export { memoized as DurationField }
