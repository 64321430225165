import { makeStyles } from 'tss-react/mui'
import { Theme } from '@mui/material'
import { Typography } from '../../../Typography'

const useStyles = makeStyles()((theme: Theme) => ({
  title: {
    margin: theme.spacing(0, 0, 0.5, 0),
  },
  subtitle: {
    opacity: 0.6,
    marginBottom: theme.spacing(1),
  },
}))

type Props = {
  title: string
  subtitle?: string
}

export const SectionTitle = ({ title, subtitle }: Props) => {
  const { classes } = useStyles()
  return (
    <div>
      <h3 className={classes.title}>{title}</h3>
      <Typography variant="subtitle2" className={classes.subtitle}>
        {subtitle || ''}
      </Typography>
    </div>
  )
}
