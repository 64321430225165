import { ThemeOptions } from '@mui/material'
import merge from 'ts-deepmerge'
import baseTheme from '../base/baseTheme'
import lightOverrides from './componentOverrides'
import lightPalette from './palette'
import lightTypography from './typography'

// theme module augmentations are defined in apps/console/src/theme.d.ts

const lightThemeOverrides: ThemeOptions = {
  components: lightOverrides,
  palette: lightPalette,
  typography: lightTypography,
}
const lightTheme = merge(baseTheme, lightThemeOverrides)

export default lightTheme
