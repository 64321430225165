import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Icon from './../icons/google.svg'

const useStyles = makeStyles()(() => ({
  root: {
    height: 16,
    width: 16,
    '& path': {
      fill: 'currentColor',
    },
  },
}))

export default function GoogleIcon() {
  const { classes } = useStyles()
  return <Icon className={classes.root} />
}
