import { ReactNode } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Theme } from '@mui/material'
import { Typography } from '../../../Typography'

const useStyles = makeStyles()((theme: Theme) => ({
  title: {
    display: 'block',
    marginTop: 0,
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(22),
    fontWeight: 600,
  },
  subtitle: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize,
    marginTop: theme.spacing(-0.5),
  },
}))

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  subtitle?: ReactNode
}

export const PageTitle = ({ title, subtitle, ...others }: Props) => {
  const { classes } = useStyles()

  return (
    <div {...others}>
      <Typography variant="h2" className={classes.title}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="body2" className={classes.subtitle}>
          {subtitle}
        </Typography>
      )}
    </div>
  )
}
