import { useRouter } from 'next/router'
import { stringifyUrl } from 'query-string'

export const partnerUrl = (partner, { query = {}, section } = {}) =>
  stringifyUrl(
    {
      url: `/partner/${encodeURIComponent(partner.slug)}${section ? `/${section}` : ''}`,
      query,
    },
    { skipNull: true },
  )

export const organizationUrl = (organization) => `/${encodeURIComponent(organization.slug)}`

export const propertyUrl = (organization, property) =>
  `${organizationUrl(organization)}/${encodeURIComponent(property.slug)}`

export const environmentUrl = (organization, property, environment) =>
  `${propertyUrl(organization, property)}/env/${encodeURIComponent(environment.name)}`

// Returns property base path, based on current route params
export const usePropertyUrl = () => {
  const {
    query: { propertySlug, organizationSlug },
  } = useRouter()

  // Usually property is read from current route, but when not on a property route we
  // need to specific a property
  return (property) => {
    return propertyUrl({ slug: organizationSlug }, property ? property : { slug: propertySlug })
  }
}

export const useBuildUrl = () => {
  const baseEnvPath = useEnvironmentUrl()

  return (build, { environment, property } = {}) => {
    const envUrl = baseEnvPath(environment || build.environment, { property })

    return `${envUrl}/builds/${encodeURIComponent(build.number.toString())}`
  }
}

export const useEnvironmentUrl = () => {
  const basePropertyPath = usePropertyUrl()

  return (environment, { property = undefined, query = undefined, section = undefined } = {}) => {
    const propertyUrl = basePropertyPath(property)
    const url = `${propertyUrl}/env/${encodeURIComponent(environment.name)}${
      section ? `/${section}` : ''
    }`

    return stringifyUrl({ url, query }, { skipNull: true })
  }
}

// ASM urls
export const asmCollectionUrl = (organization, collectionId) =>
  `${organizationUrl(organization)}/security/asm/collections/${encodeURIComponent(collectionId)}`

export const useAsmCollectionUrl = () => {
  const {
    query: { organizationSlug },
  } = useRouter()
  return (collectionId) => asmCollectionUrl({ slug: organizationSlug }, collectionId)
}

export const asmAssetUrl = (organization, entityId) =>
  `${organizationUrl(organization)}/security/asm/assets/${encodeURIComponent(entityId)}`

export const useAsmAssetUrl = () => {
  const {
    query: { organizationSlug },
  } = useRouter()
  return (entityId) => asmAssetUrl({ slug: organizationSlug }, entityId)
}

export const asmScanUrl = (organization, scanId) =>
  `${organizationUrl(organization)}/security/asm/scans/${encodeURIComponent(scanId)}`

export const useAsmScanUrl = () => {
  const {
    query: { organizationSlug },
  } = useRouter()
  return (scanId) => asmScanUrl({ slug: organizationSlug }, scanId)
}

export const asmRuleUrl = (organization, ruleId = null) => {
  const query = new URLSearchParams()
  if (ruleId) query.set('ruleId', ruleId)
  return `${organizationUrl(organization)}/security/asm/rules?${query.toString()}`
}

export const useAsmRuleUrl = () => {
  const {
    query: { organizationSlug },
  } = useRouter()
  return (ruleId) => asmRuleUrl({ slug: organizationSlug }, ruleId)
}
