import React from 'react'

export default function createHtmlFormatter(customHtmlFragments = {}) {
  const HTML_FRAGMENTS = {
    '**': (text, index) => <strong key={index}>{text}</strong>,
    ...customHtmlFragments,
  }

  return (text) => {
    const result = []
    let currentFragment
    let currentText = ''

    for (let i = 0; i < text.length; i++) {
      const fragment = text[i] + text[i + 1]

      // Start of the fragment
      if (!currentFragment && HTML_FRAGMENTS[fragment]) {
        currentFragment = fragment

        // If there was text before encountering fragment push it out
        if (currentText) {
          result.push(<React.Fragment key={i}>{currentText}</React.Fragment>)
          currentText = ''
        }
        i++
        continue
      }

      // End of the fragment
      if (fragment === currentFragment) {
        result.push(HTML_FRAGMENTS[currentFragment](currentText, i))
        currentText = ''
        currentFragment = undefined
        i++
        continue
      }

      currentText += text[i]

      // Non fragment text end
      if (i === text.length - 1) {
        result.push(<React.Fragment key={i}>{currentText}</React.Fragment>)
      }
    }

    return result
  }
}
