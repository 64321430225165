import { makeStyles } from 'tss-react/mui'
import { Box } from '@mui/material'
import { Typography } from '../../../Typography'
import { Avatar } from '../../atoms'
import { UserInfoType } from './types'

const useStyles = makeStyles()({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
})

export const UserInfo = ({ userName, userRole, userAvatar }: UserInfoType) => {
  const { classes } = useStyles()

  return (
    <div className={classes.container}>
      <Avatar imageUrl={userAvatar} />
      <Box>
        <Typography variant="body2" color="textPrimary" fontWeight={500}>
          {userName}
        </Typography>
        {userRole && (
          <Typography variant="body2" color="textSecondary" fontWeight={400}>
            {userRole}
          </Typography>
        )}
      </Box>
    </div>
  )
}
