import { gql, useQuery } from '@apollo/client'

export const ENV_REDIRECT_VIEW = gql`
  fragment EnvRedirectView on EnvironmentRedirect {
    id
    from
    to
    status
    forwardQueryString
    isDeployed
  }
`

const getEnvironmentRedirects = gql`
  query environmentRedirectsByEnvironment(
    $environmentId: ID!
    $offset: Int
    $first: Int
    $orderBy: OrderByEnvironmentRedirect = { createdAt: desc }
  ) {
    environmentRedirectsByEnvironment(
      environmentId: $environmentId
      offset: $offset
      first: $first
      orderBy: [$orderBy]
    ) {
      nodes {
        ...EnvRedirectView
      }
    }
  }
  ${ENV_REDIRECT_VIEW}
`

export const useGetEnvironmentRedirects = (variables) => {
  const { loading, data, error, refetch } = useQuery(getEnvironmentRedirects, {
    variables,
    // because subscription is inactive while un-mounted, we refresh on remount
    fetchPolicy: 'cache-and-network',
  })

  const environmentRedirects = data && data.environmentRedirectsByEnvironment

  return { loading, environmentRedirects, error, refetch }
}
