import SvgIcon from '@mui/material/SvgIcon'

export const ChatIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <g>
        <path
          d="M2.66667 5.33335V24L9.06667 19.2C9.52776 18.8529 10.0895 18.6656 10.6667 18.6667H21.3333C22.8061 18.6667 24 17.4728 24 16V5.33335C24 3.86059 22.8061 2.66669 21.3333 2.66669H5.33334C3.86058 2.66669 2.66667 3.86059 2.66667 5.33335ZM5.33334 18.6667V5.33335H21.3333V16H9.77867C9.20143 15.9985 8.63953 16.1858 8.17867 16.5334L5.33334 18.6667Z"
          fill="currentColor"
        />
        <path
          d="M29.3333 29.3334V12C29.3333 10.5273 28.1394 9.33335 26.6667 9.33335V24L23.8213 21.8667C23.3605 21.5191 22.7986 21.3318 22.2213 21.3334H9.33334C9.33334 22.8061 10.5272 24 12 24H21.3333C21.9105 23.999 22.4722 24.1862 22.9333 24.5334L29.3333 29.3334Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  )
}
