import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  footer: {},
}))

export const Footer = ({ children }: any) => {
  const { classes } = useStyles()

  return <footer className={classes.footer}>{children}</footer>
}
