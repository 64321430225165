import { TypographyVariantsOptions } from '@mui/material'
import { Colors } from '../colors'

const lightTypography: TypographyVariantsOptions = {
  h1: {
    color: Colors.grey[100],
  },
  subtitle1: {
    color: Colors.grey.t50,
  },
  body1: {
    color: Colors.grey[100],
  },
  body2: {
    color: Colors.grey[100],
  },
  caption: {
    color: Colors.grey.t50,
  },
  // @ts-ignore - for some reason code type override is not seen here
  code: {
    backgroundColor: '#0A0E12',
  },
}
export default lightTypography
