import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Theme } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    color: theme.palette.action.disabled,
    '&.focused': {
      color: theme.palette.action.active,
    },
  },
}))

interface Props {
  children: JSX.Element
  focused?: boolean
  position: 'start' | 'end'
}

export function Adornment({ children, focused = false, position }: Props) {
  const { classes } = useStyles()

  return (
    <InputAdornment
      classes={classes}
      className={focused ? 'focused' : ''}
      disablePointerEvents
      disableTypography
      position={position}
    >
      {children}
    </InputAdornment>
  )
}
