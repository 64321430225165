import { useRouter } from 'next/router'
import { useGetBuildQuery } from '../build/buildQueries'

export const useCurrentBuild = () => {
  const {
    query: { organizationSlug, propertySlug, buildNumber },
  } = useRouter()
  const { loading, data, error } = useGetBuildQuery({
    organizationSlug,
    propertySlug,
    buildNumber: parseInt(buildNumber as string),
  })

  return { currentBuild: data, loading, error }
}
