// Constants must match with app/models/feature_flag.rb
const FEATURES = {
  ADD_RULE_USING_AI: 'ADD_RULE_USING_AI',
  ADD_CLIENT_SECURITY_SERVICE: 'ADD_CLIENT_SECURITY_SERVICE',
  EXPERIMENTS: 'EXPERIMENTS',
  TRAFFIC_GEO: 'TRAFFIC_GEO',
  TRAFFIC_URLS: 'TRAFFIC_URLS',
  MTLS: 'MTLS',
  EDGE_FUNCTIONS: 'EDGE_FUNCTIONS',
  EDGE_FUNCTION_UI_EDITOR: 'EDGE_FUNCTION_UI_EDITOR',
  EDGE_PERMALINKS: 'EDGE_PERMALINKS',
  PRERENDERING: 'PRERENDERING',
  ENVIRONMENT_REDIRECTS: 'ENVIRONMENT_REDIRECTS',
  RTA: 'RTA',
  ASM: 'ASM',
  ORIGIN_LATENCY_BY_HOSTNAME: 'ORIGIN_LATENCY_BY_HOSTNAME',
  CPS: 'CPS',
}

export default FEATURES
