import clone from 'lodash/cloneDeep'
import uniqBy from 'lodash/uniqBy'

/**
 * Creates a function that updates an array in apollo's cache.  Any items in the `new` array
 * of the subscription result are added to the array returned in `prevSelector`.  Items in `deleted`
 * are removed.  Items in `updated` are automatically updated by apollo.
 * @param {*} prevSelector An arrow function which returns the array in the cache to be updated
 * @param {*} dataSelector An arrow function which returns the object from the subscription with `new`, `updated`, and `deleted` arrays.
 * @return {Function}
 */
export default function createArrayUpdater(
  prevSelector /* prev.organization */,
  dataSelector /* subscriptionData.data.propertyUpdated */,
  // options
  { sortFn = null } = {},
) {
  return (prev, subscriptionData) => {
    // Because of subscriptions and navigation sometimes you get empty state
    if (!prev) {
      return prev
    }

    // Clone the cached result from the query that we're updating.  We will apply the updates from the
    // subscription to the cloned result.
    const newRecord = clone(prev)

    const parent = prevSelector(newRecord)
    const response = dataSelector(subscriptionData)

    if (response.deleted) {
      parent.nodes = parent.nodes.filter((item) => !response.deleted.includes(item.id))
    }

    if (response.new && response.new.length) {
      parent.nodes = [...response.new, ...parent.nodes]
      if (response.new[0].id && parent.nodes.length && parent.nodes[0].id) {
        parent.nodes = uniqBy(parent.nodes, (n) => n.id)
      }

      if (sortFn) {
        parent.nodes = sortFn(parent.nodes)
      }
    }

    if (sortFn && response.updated && parent?.nodes) {
      // Don't need to update the object as Apollo will update it automatically in cache
      // but still want to order according to the updated values.
      const updatedNodes = parent.nodes.map((n) => {
        const update = response.updated.find(({ id }) => id === n.id)

        return {
          __originalNode: n,
          ...n,
          ...update,
        }
      })

      parent.nodes = sortFn(updatedNodes).map((i) => i.__originalNode)
    }

    return newRecord
  }
}
