import { useEffect } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'

// TODO: Verify usage of the following legacy fields:
// > error
export const CERT_VIEW = gql`
  fragment CertView on Cert {
    id
    primaryCert
    intermediateCert
    privateKey
    status
    generated
    expiration
    missingDomains
    commonName
    alternativeNames
    activationError
    serial
    createdAt
  }
`

export const CERT_REQUEST_VIEW = gql`
  fragment CertRequestView on CertRequest {
    id
    status
    error
    meta
    createdAt
  }
`

const getCert = gql`
  query getCert($propertyId: ID!) {
    cert(propertyId: $propertyId) {
      ...CertView
    }
  }
  ${CERT_VIEW}
`

const certSubscription = gql`
  subscription certSubscription($environmentId: ID!) {
    environmentCertUpdated(environmentId: $environmentId) {
      new {
        ...CertView
      }
      updated {
        ...CertView
      }
      deleted
    }
  }
  ${CERT_VIEW}
`

export const useGetCertQuery = (propertyId) => {
  const queryResults = useQuery(getCert, {
    variables: { propertyId },
  })

  const { subscribeToMore } = queryResults

  useEffect(() => {
    if (propertyId) {
      return subscribeToMore({
        document: certSubscription,
        variables: { propertyId },
        updateQuery: (store, { subscriptionData }) => {
          if (subscriptionData.data.environmentCertUpdated.new) {
            return {
              cert: subscriptionData.data.environmentCertUpdated.new[0],
            }
          }
          return store
        },
      })
    }
  }, [propertyId])

  return queryResults
}

const uploadCertMutation = gql`
  mutation uploadCert($certAttributes: UploadCertAttributes!) {
    uploadCert(certAttributes: $certAttributes) {
      cert {
        ...CertView
      }
      userErrors {
        message
        path
      }
    }
  }
  ${CERT_VIEW}
`

export const useUploadCert = () => {
  const [mutate, mutationResult] = useMutation(uploadCertMutation)

  return [
    async (certAttributes) => {
      const { data } = await mutate({
        variables: { certAttributes },
      })
      return data.uploadCert
    },
    mutationResult,
  ]
}

const generateCertMutation = gql`
  mutation generateCert($environmentId: ID!, $useDraftDomains: Boolean) {
    generateCert(environmentId: $environmentId, useDraftDomains: $useDraftDomains) {
      certRequest {
        ...CertRequestView
      }
      userErrors {
        message
        path
      }
    }
  }
  ${CERT_REQUEST_VIEW}
`

export const useGenerateCert = () => {
  const [mutate, mutationResult] = useMutation(generateCertMutation)

  return [
    async (environmentId) => {
      const { data } = await mutate({
        variables: { environmentId, useDraftDomains: true },
      })
      return data.generateCert
    },
    mutationResult,
  ]
}

const deleteCert = gql`
  mutation deleteCert($id: ID!) {
    deleteCert(id: $id)
  }
`

export const useDeleteCert = () => {
  const [mutate] = useMutation(deleteCert)

  return (certId) => mutate({ variables: { id: certId } })
}

const activateCert = gql`
  mutation activateCert($id: ID!) {
    activateCert(id: $id) {
      cert {
        ...CertView
      }
      userErrors {
        message
        path
      }
    }
  }
  ${CERT_VIEW}
`

export const useActivateCert = () => {
  const [mutate, mutationResult] = useMutation(activateCert)

  return [
    async (certId) => {
      const { data } = await mutate({
        variables: { id: certId },
      })
      return data.activateCert
    },
    mutationResult,
  ]
}
