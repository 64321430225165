import { useCallback, useRef, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Badge, DropdownMenu } from 'ui'
import Dropmarker from 'ui/icons/chevron-down.svg'

export const AND_OR_MIN_WIDTH = 50

const useStyles = makeStyles()(() => ({
  root: {
    minWidth: AND_OR_MIN_WIDTH,
  },
  dropmarker: {
    height: 12,
    width: 12,
    position: 'relative',
    top: 1,
    color: 'inherit',
    marginLeft: -6,
    opacity: 0.5,
    '&:hover': {
      color: 'inherit',
    },
  },
}))

type Props = {
  value: string
  className?: string
  onChangeConditionOperator: () => void
}

export default function AndOrSelector({ className, value, onChangeConditionOperator }: Props) {
  const { classes, cx } = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const badgeRef = useRef()

  const handleClick = useCallback(() => {
    if (badgeRef.current) {
      setAnchorEl(badgeRef.current)
    }
  }, [])

  const handleSelect = useCallback(
    (option) => {
      setAnchorEl(null)

      if (value !== option) {
        onChangeConditionOperator()
      }
    },
    [value],
  )

  return (
    <>
      <Badge
        ref={badgeRef}
        label={value}
        onClick={handleClick}
        className={cx(classes.root, className)}
        deleteIcon={<Dropmarker className={classes.dropmarker} />}
        onDelete={handleClick}
      />
      <DropdownMenu
        open={!!anchorEl}
        options={['and', 'or']}
        handleClose={handleSelect}
        anchorEl={anchorEl}
      />
    </>
  )
}
