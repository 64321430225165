import React, { ElementType } from 'react'
import { makeStyles } from 'tss-react/mui'
import NextLink from 'next/link'
import { Theme } from '@mui/material'
import MuiLink from '@mui/material/Link'
import { cx } from '@emotion/css'

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },
  colorPrimary: {
    color: theme.palette.secondary.dark,
  },
  link: {
    textDecoration: 'none',
  },
}))

interface GenericProps {
  /** If `true`, the link will be centered. */
  centered?: boolean
  /** The content of the link. */
  children: JSX.Element | string
  /** The color of the component. It supports those theme colors that make sense for this component. */
  color?: 'primary' | 'textPrimary' | 'textSecondary'
  /**
   * The component used for the root node. Either a string to use a HTML element or a component. Needs to be able to
   * hold a ref.
   */
  component?: ElementType
  /** If `true`, the link will open in a new tab. */
  newTab?: boolean
  /** Controls when the link should have an underline. */
  underline?: 'none' | 'hover' | 'always'
  className?: string
}

interface LinkWithHref extends GenericProps {
  /** The URL of the link. Can be used when `onClick` is not set. */
  href: string | Object
  onClick?: never
}
interface LinkWithOnClick extends GenericProps {
  href?: never
  /** Action that is called on the link click. Can be used when `href` is not set. */
  onClick: () => void
}
type Props = LinkWithHref | LinkWithOnClick

export function Link({
  children,
  color = 'primary',
  centered,
  component = 'a',
  href,
  newTab = false,
  onClick,
  underline = 'hover',
  className,
}: Props) {
  const { classes } = useStyles()

  const linkComponent = (
    <MuiLink
      className={cx(classes.link, className)}
      align={centered ? 'center' : 'inherit'}
      color={color}
      component={component}
      display={centered ? 'block' : 'initial'}
      onClick={onClick}
      rel={newTab ? 'noopener noreferrer' : undefined}
      target={newTab ? '_blank' : '_self'}
      TypographyClasses={classes}
      underline={underline}
    >
      {children}
    </MuiLink>
  )

  if (!href) {
    return linkComponent
  }

  return (
    <NextLink href={href} passHref legacyBehavior>
      {linkComponent}
    </NextLink>
  )
}
