import { gql, useMutation } from '@apollo/client'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { useOnLogout } from '../hooks/useOnLogout'
import { useNotifications } from '../util'

export const PROPERTY_LIST_VIEW = gql`
  fragment PropertyListView on Property {
    id
    slug
  }
`

export const ORGANIZATION_LIST_VIEW = gql`
  fragment OrganizationListView on Organization {
    id
    name
    slug
    personal
    tier
    partner {
      name
    }
    properties {
      nodes {
        ...PropertyListView
      }
    }
  }
  ${PROPERTY_LIST_VIEW}
`

export const USER_VIEW = gql`
  fragment UserView on User {
    id
    email
    fullname
    staff
    staffRole
    githubUser
    githubDetails
    githubAccessToken
    googleUserId
    googleUserEmail
    avatarUrl
    unconfirmedEmail
    tosAccepted
    temporaryPassword
    dateTimeDisplay
    dateTimeFormat
    timeZoneDisplay
    darkMode
    activeStaffSession {
      id
      role
      activeUntil
      accessList
    }
    personalOrganization {
      id
      name
      slug
    }
    partnerMembers {
      nodes {
        partner {
          id
          name
          slug
          organizations {
            nodes {
              ...OrganizationListView
            }
          }
        }
        role
      }
    }
    members {
      nodes {
        organization {
          ...OrganizationListView
        }
        role
      }
    }
    __typename
  }
  ${ORGANIZATION_LIST_VIEW}
`

export const unlinkGithubMutation = gql`
  mutation unlinkGithub {
    unlinkGithub {
      ...UserView
    }
  }
  ${USER_VIEW}
`

export const unlinkGoogleMutation = gql`
  mutation unlinkGoogle {
    unlinkGoogle {
      ...UserView
    }
  }
  ${USER_VIEW}
`

export const sendApiKeyToCli = gql`
  mutation sendApiKeyToCli($name: String!, $sid: String!) {
    sendApiKeyToCli(name: $name, sid: $sid)
  }
`

const updateUser = gql`
  mutation updateUser($user: UpdateUserAttributes!) {
    updateUser(user: $user) {
      user {
        ...UserView
      }
      userErrors {
        message
        path
      }
    }
  }
  ${USER_VIEW}
`

export const useUpdateUser = () => {
  const [mutate, mutationResult] = useMutation(updateUser)

  return [
    async (payload) => {
      const { data } = await mutate({
        variables: { user: payload },
      })
      return data.updateUser
    },
    mutationResult,
  ]
}

const deleteUser = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      logoutUrl
      success
      userErrors {
        message
      }
    }
  }
`

export const useDeleteCurrentUser = () => {
  const { currentUser } = useCurrentUser()
  const notifications = useNotifications()
  const onLogout = useOnLogout()

  const [mutate] = useMutation(deleteUser)

  return async () => {
    const {
      data: {
        deleteUser: { success, userErrors, logoutUrl },
      },
    } = await mutate({
      variables: { id: currentUser.id },
    })

    if (success) {
      onLogout(logoutUrl)
    }

    if (userErrors) {
      userErrors.forEach(({ message }) => notifications.error(message))
    }

    return success
  }
}
