import { useQuery } from '@apollo/client'
import { currentUserQuery } from '../auth/authQueries'

export const useCurrentUser = () => {
  const { data, loading } = useQuery(currentUserQuery)

  return {
    currentUser: data?.currentUser || data?.currentMccActor,
    partnerView: data?.partnerView,
    loading,
  }
}
