import InternalError from '../InternalError'
import Loading from '../Loading'
import NotFound from '../NotFound'
import { useCurrentPartner } from '../hooks/useCurrentPartner'

export const PartnerPage = ({ children }) => {
  const { loading, currentPartner, error } = useCurrentPartner()

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <InternalError error={error} />
  }

  if (!currentPartner) {
    return <NotFound />
  }

  return children
}
