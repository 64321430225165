import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  weekdaysRow: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  weekdayCell: {
    height: '40px',
  },
}))

export const CalendarWeekdays: React.FC = () => {
  const { classes } = useStyles()

  return (
    <div className={classes.weekdaysRow}>
      {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map((weekday) => (
        <div key={weekday} className={classes.weekdayCell}>
          {weekday}
        </div>
      ))}
    </div>
  )
}

export default CalendarWeekdays
