import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Box, alpha, useMediaQuery } from '@mui/material'
import { Typography } from 'ui'
import BottomRightTriangle from '../icons/BottomRightTriangle.svg'
import TopLeftTriangle from '../icons/TopLeftTriangle.svg'
import Logo from '../icons/logo-final-full-gradient.svg'

const useStyles = makeStyles()((theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    backgroundImage: `linear-gradient(180deg, ${alpha(theme.palette.common.white, 0)} 40%, ${alpha(
      theme.palette.secondary.main,
      0.1,
    )} 100%)`,
  },
  noGradient: {
    backgroundImage: 'none',
  },
  header: {
    marginBottom: theme.spacing(1),
  },
  logo: {
    height: 100,
    marginLeft: -16,
    alignSelf: 'flex-start',
  },
}))

export default function LoginLayout({
  header,
  main,
  maxWidth = 450,
  noGradient = false,
  showFooter,
  customClasses = {},
}) {
  const { classes, cx } = useStyles(undefined, {
    props: {
      classes: customClasses,
    },
  })
  const largeScreen = useMediaQuery('(min-width: 1024px)')

  return (
    <Box
      alignItems="center"
      className={cx(classes.container, { [classes.noGradient]: noGradient })}
      display="flex"
      flex={1}
      flexDirection="column"
      padding={4}
    >
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        justifyContent="center"
        maxWidth={maxWidth}
        width="100%"
      >
        <Logo className={classes.logo} />
        <div className={classes.header}>{header}</div>
        {main}
      </Box>
      {showFooter && (
        <Box marginTop={2} textAlign="center" style={{ opacity: 0.6 }}>
          <Typography variant="caption">
            Copyright © {new Date().getFullYear()} Edgio Inc. All rights reserved.
          </Typography>
        </Box>
      )}
      {largeScreen && <Triangles />}
    </Box>
  )
}

const Triangles = () => {
  return (
    <>
      <Box position="absolute" left={0} top={0}>
        <TopLeftTriangle />
      </Box>
      <Box position="fixed" right={0} bottom={-5} marginBottom={0}>
        <BottomRightTriangle />
      </Box>
    </>
  )
}
