import { makeStyles } from 'tss-react/mui'
import { ListItemIcon, ListItemText, ListItem as MuiListItem, darken, lighten } from '@mui/material'
import { Theme } from '@mui/material'
import { customColors } from '../../../theme'
import { NavListItemInterface } from './types'

const useStyles = makeStyles<void, 'listItemIcon'>()((theme: Theme, _params, classes) => ({
  root: {
    padding: theme.spacing(0.5, 2),
    boxSizing: 'border-box',
    height: 36,
    '& .MuiTypography-root': {
      fontSize: theme.typography.body2.fontSize,
      lineHeight: theme.typography.body2.lineHeight,
    },
    '& .MuiListItemText-root': {
      margin: 0,
    },
    '& .MuiButtonBase-root': {
      padding: '0 !important',
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.mode === 'dark' ? '#17232E' : '#EBF9F6',
      color: theme.palette.mode === 'dark' ? '#C5C8CB' : customColors.EdgioGreen,
      borderRight: `3px solid ${customColors.EdgioGreen}`,
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? '#101820' : '#EBF9F6',
      color: theme.palette.mode === 'dark' ? '#C5C8CB' : customColors.EdgioGreen,
    },
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? '#101820' : '#EBF9F6',
      color: '#17232E',
      textDecoration: 'none',
    },
    '&.Mui-disabled': {
      color: '#687077',
      [`.${classes.listItemIcon}, .MuiTypography-body1`]: {
        color: '#687077',
      },
    },
  },
  paper: {
    '&:hover': {
      backgroundColor:
        theme.palette.mode === 'dark' ? lighten('#101820', 0.03) : darken('#F6F6F7', 0.03),
    },
    '&.Mui-selected': {
      backgroundColor:
        theme.palette.mode === 'dark' ? lighten('#17232E', 0.03) : darken('#EBF9F6', 0.03),
    },
    '&.Mui-selected:hover': {
      backgroundColor:
        theme.palette.mode === 'dark' ? lighten('#101820', 0.03) : darken('#EBF9F6', 0.03),
    },
  },
  selected: {
    backgroundColor: '#F6F6F7',
    [`& .${classes.listItemIcon}, & .MuiTypography-body1`]: {
      fontWeight: 'bold',
      color: theme.palette.mode !== 'dark' ? '#019F7F' : 'auto',
    },
  },
  nested: {
    paddingLeft: '48px',
    '& .MuiListItemIcon-root': {
      minWidth: 26,
    },
  },
  listItemIcon: {
    minWidth: '28px',
    '& svg': {
      height: 16,
      width: 16,
    },
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    '&>*': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
}))

export const NavListItem = (props: NavListItemInterface) => {
  const {
    classes: { root, nested, selected, listItemIcon, text, paper: paperClass },
    cx,
  } = useStyles()
  const { isNested, icon, expandedIcon, children, paper } = props

  return (
    // @ts-ignore
    <MuiListItem
      classes={{ root, selected }}
      className={cx({
        [nested]: Boolean(isNested),
        [listItemIcon]: Boolean(icon),
        [paperClass]: Boolean(paper),
      })}
      {...props}
    >
      {icon && <ListItemIcon classes={{ root: listItemIcon }}>{icon}</ListItemIcon>}
      <ListItemText classes={{ root: text }}>{children}</ListItemText>
      {expandedIcon}
    </MuiListItem>
  )
}
