import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Box, CircularProgress } from '@mui/material'

const useStyles = makeStyles()(() => ({
  progress: {
    zIndex: 10,
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0,0,0,0.3)',
  },
}))

const LoadingDialog = () => {
  const { classes } = useStyles()
  return (
    <Box className={classes.progress}>
      <CircularProgress />
    </Box>
  )
}

export default LoadingDialog
