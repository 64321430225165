import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  sidebarContent: {
    flex: 1,
    overflowX: 'hidden',
    overflowY: 'auto',
  },
}))

export const SidebarContent = ({ children }: any) => {
  const { classes } = useStyles()

  return <div className={classes.sidebarContent}>{children}</div>
}
