import { memo, useState } from 'react'
import ConfirmationDialog from './ConfirmationDialog'

type ConfirmationOptions = {
  title: string
  text: React.ReactNode
  confirmLabel?: string
  denyLabel?: string
}

type InternalConfirmationOptions = ConfirmationOptions & {
  onConfirm: () => void
  onDeny: () => void
}

type ConfirmationState = {
  open: boolean
  options: InternalConfirmationOptions | null
}

let setDialogState: (state: ConfirmationState) => void

/**
 * Like the browser's `window.confirm` but pretty. I've named it "getConfirmation" instead of "confirm" because
 * it makes it easier to VSCode to find the function when you're looking for it, rather than assuming you're
 * using the browser's `confirm` function.
 * @param options
 * @returns
 */
export async function getConfirmation(options: ConfirmationOptions) {
  return new Promise<boolean>((resolve, reject) => {
    try {
      setDialogState({
        open: true,
        options: {
          ...options,
          onConfirm: () => resolve(true),
          onDeny: () => resolve(false),
        },
      })
    } catch (e) {
      reject(e)
    }
  })
}

const ConfirmationProvider = () => {
  const [{ open, options }, setState] = useState<ConfirmationState>({
    open: false,
    options: {
      title: '',
      text: '',
      onConfirm: () => {},
      onDeny: () => {},
    },
  })

  setDialogState = setState

  const handleClose = () => {
    setState({ open: false, options })
  }

  return (
    options && (
      <ConfirmationDialog
        {...options}
        open={open}
        onClose={handleClose}
        onConfirm={() => {
          handleClose()
          options.onConfirm && options.onConfirm()
        }}
        onDeny={() => {
          handleClose()
          options.onDeny && options.onDeny()
        }}
      />
    )
  )
}

export default memo(ConfirmationProvider)
