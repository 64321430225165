import { useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import get from 'lodash/get'
import { ACTOR_AVATAR_FRAGMENT } from '../ActorAvatar'
import { createArrayUpdater } from '../util'

export const LOG_ENTRY_VIEW = gql`
  fragment LogEntryView on LogEntry {
    id
    createdAt
    actionType
    branchString
    buildString
    build {
      id
      number
    }
    partnerString
    organizationString
    organization {
      slug
    }
    propertyString
    property {
      id
      slug
    }
    memberString
    partnerMemberString
    environmentString
    environment {
      id
      name
    }
    environmentVersion {
      id
      version
      draft
    }
    environmentVersionString
    environmentVariableString
    deployTokenString
    partnerApiTokenString
    property {
      slug
    }

    details

    actor {
      ...ActorAvatar
    }

    asmCollectionId
    asmCollectionString
    asmAssetId
    asmAssetString
    asmSeedId
    asmSeedString
    asmScanId
    asmScanString
    asmScanFilterId
    asmScanFilterString
    asmRuleId
    asmRuleString
    asmTechnologyVersionId
    asmTechnologyVersionString
  }
  ${ACTOR_AVATAR_FRAGMENT}
`

const getActivity = gql`
  query getPropertyActivity(
    $organizationId: ID
    $partnerId: ID
    $propertyId: ID
    $environmentId: ID
    $search: String
    $actionType: [String!]
    $dateFrom: ISO8601DateTime
    $dateTo: ISO8601DateTime
    $offset: Int
    $orderBy: OrderByLogEntry = { createdAt: desc }
  ) {
    logEntries(
      partnerId: $partnerId
      organizationId: $organizationId
      propertyId: $propertyId
      environmentId: $environmentId
      search: $search
      actionType: $actionType
      dateFrom: $dateFrom
      dateTo: $dateTo
      first: 10
      offset: $offset
      orderBy: [$orderBy]
    ) {
      nodes {
        ...LogEntryView
      }
      pageInfo {
        hasNextPage
      }
    }
  }
  ${LOG_ENTRY_VIEW}
`

const organizationLogEntriesSubscription = gql`
  subscription getOrganizationLogEntriesSubscription($organizationId: ID!) {
    organizationLogEntriesUpdated(organizationId: $organizationId) {
      new {
        ...LogEntryView
      }
      updated {
        ...LogEntryView
      }
      deleted
    }
  }
  ${LOG_ENTRY_VIEW}
`

export const useActivityByVariables = (variables) => {
  const { organizationId } = variables
  const results = useQuery(getActivity, {
    variables,
    notifyOnNetworkStatusChange: true,
    // because subscription is inactive while un-mounted, we refresh on remount
    fetchPolicy: 'cache-and-network',
  })
  const { subscribeToMore, data, fetchMore } = results

  const showMoreActivity = () => {
    fetchMore({
      variables: {
        ...variables,
        // always based the offset on the number of logEntries in the cache.  This will remain consistent even when
        // logEntries are deleted as long as they are removed from the cache.
        offset: data.logEntries.nodes.length,
      },
      updateQuery: ({ logEntries }, { fetchMoreResult }) => {
        if (!fetchMoreResult) return logEntries

        return {
          logEntries: {
            ...logEntries,
            nodes: [...logEntries.nodes, ...fetchMoreResult.logEntries.nodes],
            pageInfo: fetchMoreResult.logEntries.pageInfo,
          },
        }
      },
    })
  }

  // Listen for new log entries
  useEffect(
    () =>
      organizationId &&
      subscribeToMore({
        document: organizationLogEntriesSubscription,
        variables: { organizationId },
        // Add new entries to existing data
        updateQuery: createArrayUpdater(
          (prev) => prev.logEntries,
          ({ subscriptionData: { data } }) => {
            let newLogs = data.organizationLogEntriesUpdated.new || []
            if (variables.propertyId) {
              newLogs = newLogs.filter((log) => get(log, 'property.id') === variables.propertyId)
            }
            if (variables.environmentId) {
              newLogs = newLogs.filter(
                (log) => get(log, 'environment.id') === variables.environmentId,
              )
            }
            // TODO: When new logs is empty. This causes a error, but doesn't seem to affect the UI
            return {
              ...data.organizationLogEntriesUpdated,
              new: newLogs,
            }
          },
        ),
      }),
    [organizationId],
  )

  return {
    activity: results.data && results.data.logEntries,
    loading: results.loading,
    showMoreActivity,
  }
}
