import { useCurrentBuild } from '../hooks/useCurrentBuild'
import InternalError from '../InternalError'
import Loading from '../Loading'
import NotFound from '../NotFound'
import { EnvironmentPage } from './EnvironmentPage'
import { OrganizationPage } from './OrganizationPage'
import { PropertyPage } from './PropertyPage'

export const BuildPage = ({ children }) => {
  const { loading, currentBuild, error } = useCurrentBuild()

  if (currentBuild) {
    return (
      <OrganizationPage>
        <PropertyPage>
          <EnvironmentPage>{children}</EnvironmentPage>
        </PropertyPage>
      </OrganizationPage>
    )
  }

  if (loading) {
    return <Loading />
  }
  if (error) {
    return <InternalError error={error} />
  }
  if (!currentBuild) {
    return <NotFound />
  }
}
