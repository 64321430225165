import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { TDropdownMenu } from './types'

export const DropdownMenu = ({
  anchorEl,
  keepMounted = false,
  open,
  id,
  handleClose,
  options,
}: TDropdownMenu) => (
  <Menu id={id} anchorEl={anchorEl} keepMounted={keepMounted} open={open} onClose={handleClose}>
    {options.map((option) => (
      <MenuItem key={option} onClick={() => handleClose(option)}>
        {option}
      </MenuItem>
    ))}
  </Menu>
)
