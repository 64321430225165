import { MouseEvent } from 'react'
import { makeStyles } from 'tss-react/mui'
import { AutocompleteGetTagProps } from '@mui/material'
import { Theme } from '@mui/material'
import MuiChip from '@mui/material/Chip'
import { ClearButton } from './ClearButton'

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    borderColor: 'currentcolor',
    borderRadius: '0.375rem',
    color: theme.palette.text.secondary,
    fontSize: 14,
    height: '1.6875rem',
  },
  label: {
    paddingLeft: 0,
  },
  sizeSmall: {
    height: '1.3125rem',
  },
}))

export type TagProps = AutocompleteGetTagProps & {
  onDelete: (event: MouseEvent) => void
}

interface Props {
  label: string
  large?: boolean
  tagProps: TagProps
}

export function Chip({ label, large = false, tagProps }: Props) {
  const { classes } = useStyles()

  return (
    <MuiChip
      classes={classes}
      deleteIcon={<></>}
      icon={<ClearButton onClick={tagProps.onDelete} size="small" />}
      label={label}
      size={large ? 'medium' : 'small'}
      variant="outlined"
      {...tagProps}
    />
  )
}
