import { cyan } from '@mui/material/colors'
import { customColors, lightCacheStatusColors } from '../customColors'

export const lightGlobalOverrides = {
  a: {
    color: cyan['700'],
  },
  '#root': {
    backgroundColor: customColors.White,
  },
  '.statusGoodText': {
    color: `${lightCacheStatusColors.green} !important`,
  },
  '.statusFairText': { color: `${lightCacheStatusColors.yellowText} !important` },
  '.statusPoorText': { color: `${lightCacheStatusColors.red} !important` },
  'body.print': {
    background: 'white',
  },
}
