import React from 'react'
import { Box } from '@mui/material'

function SuccessMessage({ message }) {
  return <p>{message}</p>
}

export default function AuthForm({ children, onSubmit, successMessage, errorMessage }) {
  return (
    <div>
      {errorMessage && (
        <Box bgcolor="error.main" borderRadius="1px" p={1} my={1} width={1}>
          {errorMessage}
        </Box>
      )}
      {successMessage ? (
        <SuccessMessage message={successMessage} />
      ) : (
        <form onSubmit={onSubmit} noValidate>
          {children}
        </form>
      )}
    </div>
  )
}
