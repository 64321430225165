import { ReactElement, useCallback } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Theme } from '@mui/material'
import { darken, lighten } from '@mui/material/styles'
import { Trash2 } from 'icons'
import { Badge, IconButton } from '../../atoms'
import AndOrSelector, { AND_OR_MIN_WIDTH } from './AndOrSelector'
import { TRulesActionPanel, TRulesActionPanelBadge } from './types'

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: theme.spacing(1, 1.5),
    borderRadius: theme.shape.borderRadius,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.accordion.border,
    backgroundColor:
      theme.palette.mode === 'dark' ? darken(theme.palette.background.paper, 0.1) : '#FAFBFB',
    cursor: 'pointer',
    transition: 'all 0.2s',
    '&:hover': {
      borderColor: lighten(theme.palette.accordion.border, 0.075),
      backgroundColor:
        theme.palette.mode === 'dark'
          ? lighten(theme.palette.background.paper, 0.02)
          : darken(theme.palette.background.paper, 0.04),
    },
  },
  withBadge: {
    paddingLeft: theme.spacing(1.25),
  },
  inner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  text: {
    alignItems: 'center',
    flex: 1,
    display: 'flex',
  },
  badge: {
    '& *': {
      textOverflow: 'unset',
    },
    minWidth: AND_OR_MIN_WIDTH,
    marginRight: theme.spacing(1),
    display: 'inline-block',
    textAlign: 'center',
  },
  buttons: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    margin: theme.spacing(-0.25, -0.25, 0, 0),
  },
  readOnly: {
    cursor: 'default',
  },
  actionable: {
    cursor: 'pointer',
  },
}))

const RuleBadge = ({ badge, className }: { badge: TRulesActionPanelBadge; className?: string }) => (
  <Badge label={badge} className={className} />
)

export const RulesActionPanel = ({
  ruleName,
  ruleTypeLabel,
  badge,
  readOnly,
  dataQa,
  onEdit,
  onDelete,
  onChangeConditionOperator,
}: TRulesActionPanel): ReactElement<TRulesActionPanel> => {
  const { classes, cx } = useStyles()

  const handleDelete = useCallback(
    (e) => {
      e.stopPropagation()
      if (onDelete) {
        onDelete()
      }
    },
    [onDelete],
  )
  const isActionable = !readOnly && badge && onChangeConditionOperator

  return (
    <div
      onClick={onEdit}
      className={cx(classes.root, {
        [classes.withBadge]: Boolean(badge),
        [classes.readOnly]: Boolean(readOnly),
      })}
      data-qa={dataQa}
    >
      <div className={classes.inner}>
        <div className={classes.text}>
          {!isActionable && badge && (
            <RuleBadge
              badge={badge}
              className={cx(classes.badge, {
                [classes.actionable]: Boolean(isActionable),
              })}
            />
          )}
          {isActionable && (
            <span onClick={(e) => e.stopPropagation()}>
              <AndOrSelector
                value={badge}
                onChangeConditionOperator={onChangeConditionOperator}
                className={classes.badge}
              />
            </span>
          )}
          <span>
            {ruleName}
            {ruleTypeLabel}
          </span>
        </div>
        <div className={classes.buttons}>
          {onDelete && !readOnly && (
            <IconButton size={'small'} onClick={handleDelete}>
              <Trash2 />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  )
}
