import { ApolloClient, ApolloLink, InMemoryCache, from } from '@apollo/client'

/* istanbul ignore file */
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'
import { get, omit, partition, remove } from 'lodash'
import UpdateEnvironmentVersionDraftMutationLink from '../environment/UpdateEnvironmentVersionDraftMutationLink'
import { clearCsrfToken, getCsrfToken } from './getCsrfToken'

const ONE_UI_ERROR_CODES = [
  'ONE_SECURITY_SESSION_HEX_ID_MISMATCH',
  'ONE_SECURITY_SESSION_EXPIRED',
  'MISSING_ONE_SECURITY_PERMISSION',
]

const getCable = async () => {
  const ActionCable = (await import('actioncable')).default
  return ActionCable.createConsumer(`${process.env.WS_PROTOCOL}://${process.env.API_URL}/cable`)
}

let cable

export default async function createApolloClient() {
  if (!cable) {
    cable = await getCable()
  }

  // Poor dev's queue for collecting internal messages before they can be
  // exposed to the user, most notably when currentUser has been fetched, but
  // notifications cannot yet be rendered.
  let earlyInternalMessages = []
  const popEarlyInternalMessages = (level, handler) => {
    remove(earlyInternalMessages, (message) => {
      const warning = message.level == level
      warning ? handler(message.message) : null
      return warning
    })
  }

  let onUnauthorizedHook = (message) => {
    earlyInternalMessages.push({ level: 'unauthorized', message })
  }
  let onInternalErrorHook = (message) => {
    earlyInternalMessages.push({ level: 'error', message })
  }
  let onInternalWarningHook = (message) => {
    earlyInternalMessages.push({ level: 'warning', message })
  }
  let csrfToken = null

  const hasSubscriptionOperation = ({ query: { definitions } }) => {
    return definitions.some(
      ({ kind, operation }) => kind === 'OperationDefinition' && operation === 'subscription',
    )
  }

  const linkOptions = {
    uri: `${process.env.PROTOCOL}://${process.env.API_URL}/graphql`,
    credentials: 'include',
    headers: {
      accept: 'application/json',
    },
  }

  const fetchcsrfToken = setContext(async (_, { headers }) => {
    csrfToken = await getCsrfToken()

    return {
      headers: {
        ...headers,
        'X-CSRF-Token': csrfToken,
      },
    }
  })

  const warningHandlerLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((data) => {
      const [warnings, errors] = partition(
        data.errors || [],
        (error) => error.extensions?.level === 'WARN',
      )

      if (errors.length) {
        data = {
          ...data,
          errors: errors,
        }
      } else {
        data = omit(data, 'errors')
      }

      if (warnings.length) {
        const uniqueWarnings = [...new Set(warnings.map((a) => a.message))]
        onInternalWarningHook(uniqueWarnings.join('\n'), { preventDuplicate: false })
      }

      return data
    })
  })

  const errorHandlerLink = onError(({ forward, operation, networkError, graphQLErrors }) => {
    const SESSION_ENDED_MSG = 'Session has ended!'
    const INTERNAL_ERROR_MESSAGE = 'Something went wrong, please try again later!'

    // 422 happens when session times out on the backend and the CSRF token
    // does not match with the new session CSRF token
    // We clear the token and retry, which should fetch a new one
    if (networkError?.statusCode === 422) {
      clearCsrfToken()
      return forward(operation)
    }
    // Trigger logout callback when devise returns 401
    if (networkError?.statusCode === 401) {
      onUnauthorizedHook(get(networkError, 'result.error', SESSION_ENDED_MSG))
    }
    // Show maintenance error and internal errors
    // Backend should not send internal error details in production mode
    if (networkError?.statusCode >= 500) {
      onInternalErrorHook(get(networkError, 'result.error', INTERNAL_ERROR_MESSAGE))
    }
    if (
      networkError?.statusCode === 503 &&
      // TODO: first condition can go away once https://github.com/moovweb/le-deployer/pull/1456 goes live
      (get(networkError, 'error') === 'Edgio Console in under maintenance' ||
        get(networkError, 'code') === 'CONSOLE_MAINTENANCE')
    ) {
      if (window.location.pathname !== '/maintenance') {
        window.location = `/maintenance?redirectTo=${encodeURIComponent(window.location.pathname)}`
      }
    }
    // Any other response containing a first-level 'errors' attribute is an internal
    // error too
    if (graphQLErrors) {
      const operationType = get(operation, 'query.definitions[0].operation')
      const errorCode = get(graphQLErrors, '[0].extensions.errorCode')

      if (
        operationType === 'subscription' &&
        ['NOT_FOUND_OR_ACCESS_DENIED', 'ACCESS_DENIED'].includes(errorCode)
      ) {
        // We ignore subscription errors with access denied or not found. They should be raised by
        // the parent query.
        // This prevents flickering of the UI when an entity is deleted for example
      } else if (ONE_UI_ERROR_CODES.includes(errorCode)) {
        const newHexId = get(graphQLErrors, '[0].extensions.payload.new_hex_id')
        const newAccountName = get(graphQLErrors, '[0].extensions.payload.new_account_name')
        const missingPermission = get(graphQLErrors, '[0].extensions.payload.missing_permission')

        if (newHexId) {
          window.location = `/invalid-mcc-session?newHexId=${newHexId}&newAccountName=${newAccountName}`
          return
        }

        if (missingPermission) {
          window.location = `/missing-permission?missingPermission=${missingPermission}`
          return
        }

        window.location = '/invalid-mcc-session'
      } else {
        onInternalErrorHook(graphQLErrors[0].message)
      }
    }
  })

  const transportLink = ApolloLink.split(
    hasSubscriptionOperation,
    new ActionCableLink({ cable }),
    createUploadLink(linkOptions),
  )

  const updateEnvironmentVersionDraftLink = new UpdateEnvironmentVersionDraftMutationLink()

  const client = new ApolloClient({
    cache: new InMemoryCache({
      // addTypename: false
      //
      typePolicies: {
        PropertyPciCompliance: {
          keyFields: ['id', 'environmentId'],
        },
      },
    }),
    link: from([
      errorHandlerLink,
      warningHandlerLink,
      fetchcsrfToken,
      updateEnvironmentVersionDraftLink,
      transportLink,
    ]),
  })

  client.onUnauthorized = (fn) => {
    onUnauthorizedHook = fn
    popEarlyInternalMessages('unauthorized', fn)
  }

  client.onInternalError = (fn) => {
    onInternalErrorHook = fn
    popEarlyInternalMessages('error', fn)
  }

  client.onInternalWarning = (fn) => {
    onInternalWarningHook = fn
    popEarlyInternalMessages('warning', fn)
  }

  return client
}
