import { ReactElement } from 'react'

export enum RulesActionPanelBadge {
  if = 'if',
  and = 'and',
  elseIf = 'else if',
}

export type TRulesActionPanelBadge =
  | RulesActionPanelBadge.if
  | RulesActionPanelBadge.and
  | RulesActionPanelBadge.elseIf

export type TRulesActionPanel = {
  ruleName: string | ReactElement
  ruleTypeLabel?: string | ReactElement
  badge?: TRulesActionPanelBadge
  readOnly?: boolean
  dataQa?: string
  onChangeConditionOperator?: () => void
  onDelete?: () => void
  onEdit?: () => void
}
