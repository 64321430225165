import React, { ChangeEvent, FocusEvent } from 'react'
import { FormControl, FormHelperText } from '@mui/material'
import { ControlLabel } from './ControlLabel'

export interface CommonControlProps {
  style?: React.CSSProperties
  /** If `true`, the `input` element will be disabled. */
  disabled?: boolean
  /** If `true`, the input will indicate an error. */
  error?: boolean
  /** If `true`, the input will take up the full width of its container. */
  fullWidth?: boolean
  /** If `true`, space will be added after the `input` element. */
  gutterBottom?: boolean
  /** The helper text content. */
  helperText?: string | JSX.Element
  /** The label content. */
  label?: string | JSX.Element
  /** Callback fired when `input` loses focus. */
  onBlur?: (event: FocusEvent) => void
  /** Callback fired when `input` gains focus. */
  onFocus?: (event: FocusEvent) => void
  /** If `true`, the label is displayed as required and the `input` element will be required. */
  required?: boolean
}

export const CommonControlDefaultProps = {
  disabled: false,
  error: false,
  fullWidth: false,
  gutterBottom: false,
  required: false,
}

interface Props extends CommonControlProps {
  control: JSX.Element
  danger?: boolean
  onChange?: (event: ChangeEvent) => void
  value?: boolean
  className?: string
}

export function BaseControl({
  control,
  danger,
  error,
  fullWidth,
  gutterBottom,
  helperText,
  label,
  required,
  className,
}: Props) {
  return (
    <FormControl className={className} error={error} fullWidth={fullWidth} required={required}>
      {label ? <ControlLabel control={control} danger={danger} label={label} /> : control}
      {(helperText || gutterBottom) && (
        <FormHelperText error={error}>{helperText || (gutterBottom && ' ')}</FormHelperText>
      )}
    </FormControl>
  )
}

BaseControl.defaultProps = CommonControlDefaultProps
