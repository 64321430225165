import moment, { Duration, Moment } from 'moment'
import { DateRange } from 'moment-range'
import { TCalendarMode } from './Calendar'

export type TCalendarFrame = {
  granularity: string
  date: Moment
}

/**
 * Detects the end date of the frame depending on it's granularity
 */
export function getFrameEnd(frame: TCalendarFrame): Moment {
  switch (frame.granularity) {
    case 'date':
      return moment(frame.date).endOf('month')
    case 'month':
      return moment(frame.date).endOf('year')
    case 'year':
      return moment(frame.date).add(7, 'years').endOf('year')
    // current date by default
    default:
      return moment()
  }
}

/**
 * Generates the available (selectable) range
 */
export function getAvailableRange(
  range: DateRange,
  mode: TCalendarMode,
  minDate: Moment,
  maxDate?: Moment,
): DateRange {
  if (!maxDate) {
    maxDate = moment()
  }

  if (!range) {
    return new DateRange(minDate, maxDate)
  }

  switch (mode) {
    case 'rangeStart':
      return new DateRange(minDate, range.end)
    case 'rangeEnd':
      return new DateRange(range.start, maxDate)
    case 'month':
    case 'date':
    default:
      return new DateRange(minDate, maxDate)
  }
}

/**
 * Get the range edge, that can be changed. It is always range.start if mode != rangeEnd
 */
export function getActiveRangeEdge(range: DateRange, mode: TCalendarMode): moment.Moment {
  if (!range) {
    return moment()
  }

  return mode === 'rangeEnd' ? moment(range.end) : moment(range.start)
}

/**
 * Creates datePicker frame object
 * Frame is used for navigation on datePicker widget, it serves as a current state
 */
export function buildFrame(range: DateRange, mode: TCalendarMode): TCalendarFrame {
  const activeEdge = range ? getActiveRangeEdge(range, mode) : moment()

  return {
    granularity: mode === 'month' ? 'month' : 'date',
    date: moment(activeEdge).startOf(mode === 'month' ? 'year' : 'month'),
  }
}

/**
 * Limits the range based on maxRange
 */
export function limitRange(range: DateRange, maxRange: Duration, mode: TCalendarMode): DateRange {
  if (range.valueOf() < maxRange.milliseconds()) {
    return range
  }

  if (mode === 'rangeStart') {
    return new DateRange(
      moment(range.start),
      moment(range.start).add(maxRange).subtract(1, 'day').endOf('day'),
    )
  }

  if (mode === 'rangeEnd') {
    return range
  }

  return range
}
