import * as React from 'react'
import { SVGProps } from 'react'

interface SVGRProps {
  title?: string
  titleId?: string
}
const SvgDragDropDots = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="18px"
    height="18px"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M9 12.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM14.25 12.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM3.75 12.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM9 6.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM14.25 6.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM3.75 6.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
      stroke="#17232E"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgDragDropDots
