import { ThemeOptions } from '@mui/material'
import merge from 'ts-deepmerge'
import baseTheme from '../base/baseTheme'
import darkComponentOverrides from './componentOverrides'
import darkPaletteOptions from './palette'
import darkTypography from './typography'

// theme module augmentations are defined in apps/console/src/theme.d.ts

const darkThemeOverrides: ThemeOptions = {
  components: darkComponentOverrides,
  palette: darkPaletteOptions,
  typography: darkTypography,
}

const darkTheme = merge(baseTheme, darkThemeOverrides)

export default darkTheme
