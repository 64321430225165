import { IS_PROD_ENV, IS_SANDBOX_ENV, IS_STAGE_ENV } from '../constants'

export function idsLoginLink({ action = 'login', redirectTo = '' } = {}) {
  return `${process.env.PROTOCOL}://${
    process.env.API_URL
  }/users/auth/ids_connect?action=${action}&redirectTo=${encodeURIComponent(
    `${window.location.origin}${redirectTo}`,
  )}`
}

export function loginLink({ action = 'login', redirectTo = '' } = {}) {
  // Normally for local development we want to be independent of IDS for logins.
  // However, if you should find yourself in the situation to use IDS as a login
  // provider during local development, please make use of this override:
  const USE_IDS_LOCALLY = false

  const loginUrl =
    IS_PROD_ENV || IS_STAGE_ENV || IS_SANDBOX_ENV || USE_IDS_LOCALLY
      ? idsLoginLink({ action, redirectTo })
      : `/login${redirectTo ? `?redirectTo=${encodeURIComponent(redirectTo)}` : ''}`

  return loginUrl
}
