import React, { ReactNode } from 'react'

export type TColumnOrder = 'asc' | 'desc'
export type TColumnOrderRuleSetBy = string

export type THeadColumn = {
  id: string
  label: ReactNode
  width?: string
  description?: string
  sort?: boolean | 'asc' | 'desc'
  className?: string
  tooltip?: ReactNode
}

export type THeadColumns = THeadColumn[]

export type TTableHeadProps = {
  columns: THeadColumns
  order?: TColumnOrder
  orderBy?: TColumnOrderRuleSetBy
  onRequestSort?: (event: React.MouseEvent<unknown>, property: TColumnOrderRuleSetBy) => void
}
