import { gql, useApolloClient, useMutation } from '@apollo/client'
import { useOnLogout } from '../hooks/useOnLogout'
import { USER_VIEW } from '../user/userQueries'

export const currentUserQuery = gql`
  {
    currentUser {
      ...UserView
    }
    currentMccActor {
      email
      impersonatedEmail
      customerName
      customerAccountNumber
      permissions
      __typename
    }
    partnerView {
      logoUrl
      logoUrlDark
      supportInfo
    }
  }
  ${USER_VIEW}
`
const logoutMutation = gql`
  mutation logout {
    logout {
      success
      logoutUrl
    }
  }
`

export const useLogoutMutation = () => {
  const [mutate, mutationResult] = useMutation(logoutMutation)
  const onLogout = useOnLogout()

  return [
    async () => {
      const { data } = await mutate({})
      if (data.logout.success) {
        onLogout(data.logout.logoutUrl)
      }

      return data.logout
    },
    mutationResult,
  ]
}
const loginMutation = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      userErrors {
        message
        path
      }
    }
  }
`

export const useLoginMutation = () => {
  const [mutate] = useMutation(loginMutation)
  const apolloClient = useApolloClient()

  return async (email, password) => {
    const { data, errors } = await mutate({
      variables: { email, password },
    })

    if (!errors && (!data.login.userErrors || data.login.userErrors.length === 0)) {
      // Perform the refetch only if there are no errors and no userErrors
      await apolloClient.refetchQueries({
        include: [{ query: currentUserQuery }],
      })
    }

    return data.login
  }
}

const signupMutation = gql`
  mutation signup(
    $email: String!
    $fullname: String!
    $password: String!
    $recaptchaToken: String!
    $source: String
    $country: String
  ) {
    signup(
      email: $email
      fullname: $fullname
      password: $password
      recaptchaToken: $recaptchaToken
      source: $source
      country: $country
    ) {
      success
      userErrors {
        message
        path
      }
    }
  }
`

export const useSignupMutation = () => {
  const [mutate, mutationResult] = useMutation(signupMutation)

  return [
    async ({ email, fullname, password, recaptchaToken, source, country }) => {
      const { data } = await mutate({
        variables: { email, fullname, password, recaptchaToken, source, country },
      })

      return data.signup
    },
    mutationResult,
  ]
}

const emailConfirmationMutation = gql`
  mutation emailConfirmation($confirmationToken: String!) {
    emailConfirmation(confirmationToken: $confirmationToken)
  }
`

export const useEmailConfirmationMutation = () => {
  const [mutate, mutationResult] = useMutation(emailConfirmationMutation)

  const confirm = async (confirmationToken) => {
    try {
      const { data } = await mutate({
        variables: { confirmationToken },
        // If it works, the user may now be logged in
        refetchQueries: [{ query: currentUserQuery }],
        awaitRefetchQueries: true,
      })
      return data.emailConfirmation
    } catch (e) {
      // As we awaitRefetchQueries, this mutation may return a 401 in
      // case the confirmation token is invalid or expired.
      if (e.networkError?.statusCode === 401) {
        return false
      } else {
        throw e
      }
    }
  }

  return [confirm, mutationResult]
}

const resetPasswordRequestMutation = gql`
  mutation resetPasswordRequest($email: String!) {
    resetPasswordRequest(email: $email)
  }
`

const resendConfirmationMutation = gql`
  mutation resendConfirmation($email: String!) {
    resendConfirmation(email: $email)
  }
`

export const useResetPasswordRequestMutation = () => {
  const [mutate, mutationResult] = useMutation(resetPasswordRequestMutation)

  return [
    async (email) => {
      const { data } = await mutate({ variables: { email } })
      return data.resetPasswordRequest
    },
    mutationResult,
  ]
}

export const useResendConfirmationMutation = () => {
  const [mutate, mutationResult] = useMutation(resendConfirmationMutation)

  return [
    async (email) => {
      const { data } = await mutate({ variables: { email } })
      return data.resendConfirmation
    },
    mutationResult,
  ]
}

const resendUnlockInstructionsMutation = gql`
  mutation resendUnlockInstructions($email: String!) {
    resendUnlockInstructions(email: $email)
  }
`

export const useResendUnlockInstructionsMutation = () => {
  const [mutate, mutationResult] = useMutation(resendUnlockInstructionsMutation)

  return [
    async (email) => {
      const { data } = await mutate({ variables: { email } })
      return data.resendUnlockInstructions
    },
    mutationResult,
  ]
}

const resetPasswordMutation = gql`
  mutation resetPassword($password: String!, $resetPasswordToken: String!) {
    resetPassword(password: $password, resetPasswordToken: $resetPasswordToken) {
      success
      userErrors {
        message
        path
      }
    }
  }
`

const changePasswordMutation = gql`
  mutation changePassword($userId: String!, $password: String!) {
    changePassword(userId: $userId, password: $password) {
      user {
        ...UserView
      }
      userErrors {
        message
        path
      }
    }
  }
  ${USER_VIEW}
`

export const useResetPasswordMutation = () => {
  const [mutate, mutationResult] = useMutation(resetPasswordMutation)

  return [
    async (resetPasswordToken, password) => {
      const { data } = await mutate({ variables: { resetPasswordToken, password } })

      return data.resetPassword
    },
    mutationResult,
  ]
}

export const useChangePassword = () => {
  const [mutate, mutationResult] = useMutation(changePasswordMutation)

  return [
    async (userId, password) => {
      const { data } = await mutate({ variables: { userId, password } })
      return data.changePassword
    },
    mutationResult,
  ]
}

const unlockAccountMutation = gql`
  mutation unlockAccount($token: String!) {
    unlockAccount(token: $token)
  }
`

export const useUnlockAccountMutation = () => {
  const [mutate, mutationResult] = useMutation(unlockAccountMutation)

  return [
    async (token) => {
      const { data } = await mutate({ variables: { token } })
      return data.unlockAccount
    },
    mutationResult,
  ]
}
