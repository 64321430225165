import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { CircularProgress, Grid, Typography } from '@mui/material'
import { gql } from '@apollo/client'

export const HOME_SNAPSHOT_VIEW = gql`
  fragment homeSnapshotView on BuildSnapshot {
    id
    url
    status
    httpStatusCode
  }
`

const useStyles = makeStyles()((theme) => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
    background: theme.palette.mode === 'dark' ? '#111820' : theme.palette.background.default,
    overflow: 'hidden',
    position: 'relative',
  },
  withImage: {
    border: 'none',
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  sizer: {
    width: '100%',
    height: 0,
    paddingTop: '62.5%', // 16/9 aspect ratio
  },
  failedLabel: {
    opacity: 0.6,
  },
  center: {
    textAlign: 'center',
  },
}))

const SnapshotContainer = ({ snapshotLoaded = false, children }) => {
  const { classes, cx } = useStyles()

  return (
    <div className={cx({ [classes.root]: true, [classes.withImage]: snapshotLoaded })}>
      <div className={classes.sizer} />
      <div className={classes.image}>{children}</div>
    </div>
  )
}

const Status = ({ loading, text }) => {
  const { classes, cx } = useStyles()

  return (
    <Grid container justifyContent="center" alignItems="center" direction="column" spacing={1}>
      {loading && (
        <Grid item>
          <CircularProgress />
        </Grid>
      )}
      <Grid item>
        <Typography
          gutterBottom={false}
          className={cx({ [classes.center]: true, [classes.failedLabel]: !loading })}
        >
          {text}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default function BuildHomeSnapshotPanel({ build, imageHref, minHeight }) {
  const snapshot = build?.homeSnapshot

  // This is for legacy builds that doesn't have the snapshot, so we can manage the state.
  const isLoading = snapshot?.status === 'waiting' || !build

  const buildCompleted = build?.status === 'completed'
  const buildFailed = ['failed', 'canceled'].includes(build?.status)
  const isSnapshotMissing = !snapshot || snapshot?.status === 'failed' || buildFailed

  const isWaitingForDeployment = !buildCompleted && !buildFailed
  const isCreatingSnapshot = isLoading && buildCompleted

  const snapshotLoaded =
    !isLoading && !isSnapshotMissing && [200, null].includes(snapshot?.httpStatusCode)

  const snapshotSrc = snapshot?.url

  if (snapshotLoaded) {
    return (
      <SnapshotContainer minHeight={minHeight} snapshotLoaded={true}>
        <div style={{ display: 'flex', alignSelf: 'center' }}>
          <img
            onClick={() => {
              if (imageHref) {
                window.open(imageHref, '_blank')
              }
            }}
            style={{ maxWidth: '100%' }}
            src={snapshotSrc}
            alt="snapshot"
          />
        </div>
      </SnapshotContainer>
    )
  }

  if (build && isWaitingForDeployment) {
    return (
      <SnapshotContainer minHeight={minHeight}>
        <Status loading={true} text="Waiting for deployment to finish..." />
      </SnapshotContainer>
    )
  }

  if (isCreatingSnapshot) {
    return (
      <SnapshotContainer minHeight={minHeight}>
        <Status loading={true} text="Creating snapshot..." />
      </SnapshotContainer>
    )
  }

  return (
    <SnapshotContainer minHeight={minHeight}>
      <Status loading={false} text="Preview not available" />
    </SnapshotContainer>
  )
}

BuildHomeSnapshotPanel.defaultProps = {
  initialLoading: true,
  minHeight: 150,
}
