import { PaletteOptions } from '@mui/material'
import { pink } from '@mui/material/colors'
import { Colors } from '../colors'
import { customColors, lightCacheStatusColors } from '../customColors'

const lightPaletteOptions: PaletteOptions = {
  mode: 'light',
  // @ts-ignore we are overwriting only light value from base palette
  primary: {
    light: pink[400],
  },
  // @ts-ignore we are overwriting only light value from base palette
  success: {
    light: pink[400],
  },
  text: {
    primary: Colors.grey[100],
    secondary: customColors.GraniteGray,
  },
  background: {
    paper: customColors.White,
    default: Colors.grey.t98,
  },
  action: {
    active: Colors.grey[100],
    disabledBackground: customColors.Cultured,
  },
  divider: Colors.grey.t92,
  borderColor: {
    main: Colors.grey.t92,
  },
  danger: {
    main: '#ff1744',
    light: '#e57373',
    dark: '#d32f2f',
    contrastText: '#fff',
  },
  cacheStatus: lightCacheStatusColors,
  accordion: {
    border: Colors.grey.t92,
    summary: '#F6F6F7',
  },
  inputBackground: 'white',
  dialogBackground: 'white',
  tabsPanel: {
    border: '#DCDEE0',
    background: Colors.grey.t98,
  },
  tabsNav: {
    background: 'white',
  },
}

export default lightPaletteOptions
