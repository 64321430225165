import SvgIcon from '@mui/material/SvgIcon'

export const QuestionIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M17.3333 29.3333H13.3333V25.3333H17.3333V29.3333ZM17.3333 22.6666H13.3333V22.6573C13.3333 20.4573 13.3333 18.5573 14.2293 17.2266C14.9264 16.3432 15.7938 15.6087 16.78 15.0666C17.2253 14.7813 17.6453 14.5106 17.9987 14.2293C19.5472 13.0307 20.2434 11.0319 19.7747 9.13064C19.0329 7.35751 17.1457 6.35141 15.2601 6.72393C13.3746 7.09645 12.0117 8.74466 12 10.6666H8C8 6.24836 11.5817 2.6666 16 2.6666C19.0737 2.6574 21.8846 4.39857 23.2453 7.15464C24.4676 9.96281 23.9844 13.2219 22 15.5546C21.3967 16.2233 20.7194 16.8213 19.9813 17.3373C19.3354 17.7828 18.7462 18.3053 18.2267 18.8933C17.4924 20.006 17.1759 21.3428 17.3333 22.6666Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}
