let csrfToken = null
let getCsrfTokenPromise = null

const fetchCsrfToken = async () => {
  const response = await fetch(`${process.env.PROTOCOL}://${process.env.API_URL}/api/csrf`, {
    credentials: 'include',
  })
  if (response.status !== 200) {
    return null
  }
  const { token } = await response.json()
  csrfToken = token
  return token
}

export const getCsrfToken = async () => {
  if (csrfToken) {
    return Promise.resolve(csrfToken)
  }

  if (!getCsrfTokenPromise) {
    getCsrfTokenPromise = fetchCsrfToken().finally(() => (getCsrfTokenPromise = null))
  }

  return getCsrfTokenPromise
}

export const clearCsrfToken = () => (csrfToken = null)
