import React from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    marginTop: '25%',
  },
  errorCode: {
    textAlign: 'center',
    fontSize: theme.typography.h2.fontSize,
    fontWeight: 100,
  },
  errorDetails: {
    fontSize: '0.75em',
    margin: '20px',
    fontFamily: 'Courier',
  },
}))

export default function InternalError({ error }) {
  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.errorCode}>
        <b>500</b>&nbsp;&nbsp;|&nbsp;&nbsp;Something went wrong:
      </div>
      <div className={classes.errorDetails}>{JSON.stringify(error)}</div>
    </div>
  )
}
