import { ElementType } from 'react'
import { Typography as MuiTypography } from '@mui/material'

interface Props {
  style?: React.CSSProperties
  className?: string
  /** The content of the component. */
  children: React.ReactNode
  /** The color of the component. It supports those theme colors that make sense for this component. */
  color?: 'primary' | 'error' | 'initial' | 'textPrimary' | 'textSecondary'
  /** The font style of the text */
  fontStyle?: 'bold' | 'italic' | 'normal'
  /** The font weight of the text */
  fontWeight?: 400 | 500 | 600
  /** If `true`, the text will have a bottom margin. */
  gutterBottom?: boolean
  /** The component used for the root node. Either a string to use a HTML element or a component. */
  component?: ElementType
  /** Applies the theme typography styles. */
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline'
    | 'inherit'
  noWrap?: boolean
  title?: string
  /** QA test hook */
  ['data-qa']?: string
}

export function Typography({
  style,
  className,
  children,
  color = 'textPrimary',
  fontWeight,
  component,
  gutterBottom = false,
  fontStyle,
  variant = 'body1',
  noWrap = false,
  title,
  ...props
}: Props) {
  return (
    <MuiTypography
      style={{
        fontStyle,
        fontWeight,
        ...style,
      }}
      className={className}
      color={color}
      gutterBottom={gutterBottom}
      variant={variant}
      noWrap={noWrap}
      data-qa={props['data-qa']}
      title={title}
      component={component as ElementType}
    >
      {children}
    </MuiTypography>
  )
}
