import * as React from 'react'
import { SVGProps } from 'react'

interface SVGRProps {
  title?: string
  titleId?: string
}
const SvgMoreVertical = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="18px"
    height="18px"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M9 9.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM9 4.5A.75.75 0 1 0 9 3a.75.75 0 0 0 0 1.5ZM9 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgMoreVertical
