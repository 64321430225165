import { makeStyles } from 'tss-react/mui'
import { Theme } from '@mui/material'

const useStyles = makeStyles()((theme: Theme) => ({
  header: {
    backgroundColor: theme.palette.mode === 'dark' ? '#070A0E' : '#fff',
    borderBottom: `1px solid ${theme.palette.mode === 'dark' ? '#17232E' : '#ECEDEE'}`, // t-92 grey
    // padding: '20px 22px',
    gridArea: 'header',
    height: '64px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
  },
}))

export const Header = ({ children }: any) => {
  const { classes } = useStyles()

  return <header className={classes.header}>{children}</header>
}
