export const DragHorizontal = (props: any) => (
  <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9 12.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM14.25 12.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM3.75 12.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM9 6.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM14.25 6.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM3.75 6.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
