import { makeStyles } from 'tss-react/mui'
import { Theme } from '@mui/material'

const useStyles = makeStyles()((theme: Theme) => ({
  sidebar: {
    width: '246px',
    borderRight: `1px solid ${theme.palette.mode === 'dark' ? '#17232E' : '#ECEDEE'}`, // t-92 grey
    gridArea: 'subnav',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
}))

export const SubNav = ({ children }: any) => {
  const { classes } = useStyles()

  return <div className={classes.sidebar}>{children}</div>
}
