import { useCallback } from 'react'
import { useApolloClient } from '@apollo/client'
import { currentUserQuery } from '../auth/authQueries'
import { useAppStore } from '../stores/appStore/appStore'

export const useOnLogout = () => {
  const { setIsLoggingOut } = useAppStore((s) => ({ setIsLoggingOut: s.setIsLoggingOut }))
  const client = useApolloClient()
  const onLogout = useCallback(
    (logoutUrl) => {
      setIsLoggingOut(true)

      // It is important that we will remove the current user before flushing the apollogql-s cache on logout
      // Otherwise we will face issues with reading properties since user is logged in and no data is present.
      client.writeQuery({
        query: currentUserQuery,
        data: { currentUser: null },
      })

      client.cache.reset()
      client.clearStore()

      // Destroy IDS session
      if (logoutUrl) {
        window.location.assign(logoutUrl)
      }
    },
    [client, setIsLoggingOut],
  )

  return onLogout
}
