import { useRouter } from 'next/router'
import { useGetOrganizationQuery } from '../organization/organizationQueries'
import { useCurrentUser } from './useCurrentUser'

export const useCurrentOrganization = () => {
  const { currentUser } = useCurrentUser()

  let {
    query: { organizationSlug },
  } = useRouter()

  organizationSlug =
    currentUser?.__typename === 'MccActor'
      ? null
      : organizationSlug || currentUser?.personalOrganization?.slug

  const {
    organization: currentOrganization,
    currentActorOrganizationRole,
    organizationFeatures: currentOrganizationFeatures,
    loading,
    refetch,
  } = useGetOrganizationQuery(organizationSlug, {
    skip: !organizationSlug,
  })

  return {
    loading,
    currentOrganization,
    currentActorOrganizationRole,
    currentOrganizationFeatures,
    refetch,
  }
}
