import { makeStyles } from 'tss-react/mui'
import { Theme } from '@mui/material'

const useStyles = makeStyles()((theme: Theme) => ({
  main: {
    backgroundColor: theme.palette.background.default,
    gridArea: 'main',
    padding: theme.spacing(2.5, 2.5, 2.5, 2.5),
    // the Y scrollbar on filters sidebar lies on top of this components scrollbar
    overflowY: 'auto',
    display: 'block !important',
    width: '100%',
  },
}))

export const Main = ({ children }: any) => {
  const { classes } = useStyles()

  return <main className={classes.main}>{children}</main>
}
