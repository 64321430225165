import React from 'react'
import { useTheme } from '@mui/material/styles'

type SpacerProps = {
  vertical?: boolean
  spacing?: number
  flex?: string
  style?: { [key: string]: string | number }
}

export default function Spacer({ vertical, spacing, flex, style, ...others }: SpacerProps) {
  const theme = useTheme()

  const finalWidth = spacing && !vertical && theme.spacing(spacing)
  const finalHeight = spacing && vertical && theme.spacing(spacing)

  const finalStyle = {
    width: finalWidth,
    height: finalHeight,
    flex,
    ...style,
  }

  return <div style={finalStyle} {...others} />
}
