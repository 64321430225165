import { useRouter } from 'next/router'
import { useGetProperty } from '../property/propertyQueries'
import { useCurrentUser } from './useCurrentUser'

export const useCurrentProperty = () => {
  const {
    query: { propertySlug, organizationSlug },
  } = useRouter()

  const { currentUser } = useCurrentUser()

  const { data, loading } = useGetProperty({
    propertySlug,
    organizationSlug: organizationSlug ?? currentUser?.personalOrganization?.slug,
  })

  return {
    currentProperty: data?.property,
    currentActorPropertyRole: data?.currentActorPropertyRole,
    loading,
  }
}
