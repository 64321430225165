import { ReactNode } from 'react'
import pluralize from './pluralize'

/**
 * Humanizes the list of provided items into the form of "item1, item2, and item3"
 * @param list A list of items to humanize.
 * @param label A label to append to the end of the returned value, which will automatically be pluralized based on the length of the list.
 * @param pluralForm If the plural form of the above label is irregular, provide it here.
 * @returns
 */
export default function listify(list: ReactNode[], label?: string, pluralForm?: string) {
  if (list == null) {
    return list
  }

  if (!pluralForm) {
    pluralForm = label ? pluralize(label, list.length) : undefined
  }

  const suffix = list.length === 1 ? label : pluralForm

  let result: ReactNode[] = []

  if (list.length === 1) {
    result = list
  } else {
    result = list.flatMap((item, index) => {
      if (index < list.length - 2) {
        // eslint-disable-next-line react/jsx-key
        return [item, <span>,&nbsp;</span>]
      } else if (index === list.length - 2) {
        // eslint-disable-next-line react/jsx-key
        return [item, <span>{list.length > 2 ? ', ' : ''} and&nbsp;</span>]
      } else {
        return [item]
      }
    })
  }

  if (suffix) {
    result.push(<span> {suffix}</span>)
  }

  return (
    <span>
      {result.map((el, i) => (
        <span key={i}>{el}</span>
      ))}
    </span>
  )
}
