import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useNotifications } from './util'

export default function ReactQueryProvider({ children }: { children: React.ReactNode }) {
  const notifications = useNotifications()

  const queryClient = useMemo(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: (error: any) => {
            const message =
              error?.response?.data?.description ||
              error?.response?.data?.title ||
              error?.message ||
              'Could not process the request'

            notifications.error(message, {
              preventDuplicate: true,
            })
          },
        }),
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: false,
          },
        },
      }),
    [],
  )

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}
